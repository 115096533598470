import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getDegrees } from 'cv-app/shared/services/data-service';
import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import { AuthInfo } from 'cv-app/utils/api-auth-helper';
import { StatusEnums } from 'cv-app/utils/status-enums';

export const DEGREE_FEATURE_KEY = 'degree';

export interface DegreeState extends EntityState<SelectOption> {
  loadingStatus: StatusEnums;
  error: string;
  degrees: SelectOption[];
}

export const degreeAdapter = createEntityAdapter<SelectOption>();

export const fetchDegree = createAsyncThunk(
  'degree/fetchStatus',
  async (authInfo: AuthInfo, thunkAPI) => {
    const response = getDegrees(authInfo);
    return Promise.resolve(response);
  }
);

export const initialDegreeState: DegreeState = degreeAdapter.getInitialState({
  loadingStatus: StatusEnums.Initial,
  error: null,
  degrees: []
});

export const degreeSlice = createSlice({
  name: DEGREE_FEATURE_KEY,
  initialState: initialDegreeState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDegree.pending, (state: DegreeState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(
        fetchDegree.fulfilled,
        (state: DegreeState, action: PayloadAction<SelectOption[]>) => {
          state.degrees = action.payload;
          state.loadingStatus = StatusEnums.Loaded;
        }
      )
      .addCase(fetchDegree.rejected, (state: DegreeState, action) => {
        state.loadingStatus = StatusEnums.Error;
        state.error = action.error.message;
      });
  },
});

/*
 * Export reducer for store configuration.
 */
export const degreeReducer = degreeSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(degreeActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const degreeActions = degreeSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllDegree);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = degreeAdapter.getSelectors();

export const getDegreeState = (rootState: unknown): DegreeState =>
  rootState[DEGREE_FEATURE_KEY];

export const selectAllDegree = createSelector(getDegreeState, selectAll);

export const selectDegreeEntities = createSelector(
  getDegreeState,
  selectEntities
);
