import React from 'react';

import './form-validation.module.scss';

/* eslint-disable-next-line */
export interface FormValidationProps {
  showError: boolean;
  errorText: string;
}

export function FormValidation(props: FormValidationProps) {
  if (props.showError) {
    return (
      <span className="c-field__message">
        {props.errorText}
      </span>
    );
  }
  return (null);
}

export default FormValidation;
