// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".personal-info_c-personal-info__media__370Vo {\n  max-height: 178px;\n  max-width: 178px;\n}\n.personal-info_c-personal-info__media-wrapper__1c2lb {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}", ""]);
// Exports
exports.locals = {
	"c-personal-info__media": "personal-info_c-personal-info__media__370Vo",
	"c-personal-info__media-wrapper": "personal-info_c-personal-info__media-wrapper__1c2lb"
};
module.exports = exports;
