import { setMaxDateForStartDate } from 'cv-app/utils/helpers/date-helper';
import { formatDateForInput } from 'cv-app/utils/helpers/form-helper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormDate from '../../molecules/form-date/form-date';

import './date-interval.module.scss';

/* eslint-disable-next-line */
export interface DateIntervalProps {
  doValidate: boolean;
  componentKey: string;
  startDate: string;
  endDate: string;
  requireEndDate: boolean;
  showEndDate: boolean;
  maxEndDate?: string;
  onStartDateChanged: (newValue: string) => void;
  onEndDateChanged: (newValue: string) => void;
  minDate?: string;
}

export function DateInterval(props: DateIntervalProps) {

  const { t } = useTranslation();
  const startDate = new Date(props.startDate);
  const endDate = new Date(props.endDate);
  const minDate = new Date(props.minDate);

  return (
    <React.Fragment>
      <FormDate
        isRequired={true}
        doValidate={props.doValidate}
        inputId={`${props.componentKey}-startDate`}
        labelText={t(`${props.componentKey}__lbl-startDate`)}
        errorText={t(`${props.componentKey}__lbl-startDateError`) + ' ' + props.minDate}
        inputValue={formatDateForInput(props.startDate)}
        maxDate={setMaxDateForStartDate(props.endDate)}
        onChangeCallback={props.onStartDateChanged}
        minDate={props.minDate}
      ></FormDate>
      {(props.requireEndDate || props.showEndDate) &&
        <FormDate
          isRequired={props.requireEndDate}
          doValidate={props.doValidate}
          inputId={`${props.componentKey}-endDate`}
          labelText={t(`${props.componentKey}__lbl-endDate`)}
          errorText={props.endDate > props.startDate ? t(`${props.componentKey}__lbl-endDateError2`) : t(`${props.componentKey}__lbl-endDateError`)}
          inputValue={(formatDateForInput(props.endDate))}
          minDate={formatDateForInput(props.startDate)}
          maxDate={props.maxEndDate}
          onChangeCallback={props.onEndDateChanged}
        ></FormDate>
      }
    </React.Fragment>
  );
}

export default DateInterval;
