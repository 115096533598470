// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loader_c-loading-wrapper__1bQB4 {\n  width: 100%;\n  display: flex;\n  height: 100%;\n  align-items: center;\n  justify-content: center;\n}", ""]);
// Exports
exports.locals = {
	"c-loading-wrapper": "loader_c-loading-wrapper__1bQB4"
};
module.exports = exports;
