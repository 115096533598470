import React from 'react';
import Box from '../../layout/box/box';

import './loader.module.scss';

/* eslint-disable-next-line */
export interface LoaderProps {}

export function Loader(props: LoaderProps) {
  return (
    <div className="c-loading-wrapper">
      <div className="c-loading">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="144px"
          height="24px"
          viewBox="0 0 144 24"
          preserveAspectRatio="xMidYMid"
        >
          <g transform="translate(10 10)">
            <circle cx="0" cy="0" r="6" fill="#941914">
              <animateTransform
                attributeName="transform"
                type="scale"
                begin="-0.6578947368421052s"
                calcMode="spline"
                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                values="0;1;0"
                keyTimes="0;0.5;1"
                dur="1.7543859649122806s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </g>
          <g transform="translate(30 10)">
            <circle cx="0" cy="0" r="6" fill="#c42828">
              <animateTransform
                attributeName="transform"
                type="scale"
                begin="-0.43859649122807015s"
                calcMode="spline"
                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                values="0;1;0"
                keyTimes="0;0.5;1"
                dur="1.7543859649122806s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </g>
          <g transform="translate(50 10)">
            <circle cx="0" cy="0" r="6" fill="#ef463c">
              <animateTransform
                attributeName="transform"
                type="scale"
                begin="-0.21929824561403508s"
                calcMode="spline"
                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                values="0;1;0"
                keyTimes="0;0.5;1"
                dur="1.7543859649122806s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </g>
          <g transform="translate(70 10)">
            <circle cx="0" cy="0" r="6" fill="#ee7684">
              <animateTransform
                attributeName="transform"
                type="scale"
                begin="0s"
                calcMode="spline"
                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                values="0;1;0"
                keyTimes="0;0.5;1"
                dur="1.7543859649122806s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default Loader;
