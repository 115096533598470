import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEnglish from '../src/translation/english/translation.json';
import translationFrench from '../src/translation/french/translation.json';
import translationGerman from '../src/translation/german/translation.json';
import translationPortuguese from '../src/translation/portuguese/translation.json';
import translationChinese from '../src/translation/chinese/translation.json';

const resources = {
  en: {
    translation: translationEnglish
  },
  fr: {
    translation: translationFrench
  },
  de: {
    translation: translationGerman
  },
  pt: {
    translation: translationPortuguese
  },
  zh: {
    translation: translationChinese
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
