// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".step-layout_o-step__nav-flag__3LKlb {\n  display: flex;\n  align-items: center;\n  font-size: 11px;\n  font-weight: 500;\n  width: 80px;\n  min-height: var(--dot__size);\n}\n@media only screen and (min-width: 46.25em) {\n  .step-layout_o-step__grid__1DPqV {\n    display: grid;\n    grid-template-columns: auto 1fr;\n    grid-column-gap: 100px;\n  }\n}\n@media only screen and (min-width: 112.5em) {\n  .step-layout_o-step__grid__1DPqV {\n    margin: 0 0 0 -20px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"o-step__nav-flag": "step-layout_o-step__nav-flag__3LKlb",
	"o-step__grid": "step-layout_o-step__grid__1DPqV"
};
module.exports = exports;
