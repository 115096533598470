// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".edu-card_c-edu-card__wrapper__1PNMt {\n  display: grid;\n  grid-template-areas: var(--c-edu-card__grid-template-areas, \"actions\" \"info\");\n}\n.edu-card_c-edu-card__title__3RTHw {\n  font-size: 24px;\n  font-weight: 600;\n}\n.edu-card_c-edu-card__subtitle__3QsKS {\n  font-size: 20px;\n  color: brandColor(\"dot-red\");\n  margin-bottom: 6px;\n}\n.edu-card_c-edu-card__info__ZOvoF {\n  grid-area: info;\n}\n.edu-card_c-edu-card__extra-info__xWLlC {\n  font-size: 14px;\n  color: brandColor(\"mid-grey\");\n  margin-bottom: 12px;\n}\n.edu-card_c-edu-card__actions__xYZ_I {\n  grid-area: actions;\n  flex-shrink: 0;\n  justify-self: end;\n}\n@media only screen and (min-width: 768px) {\n  .edu-card_c-edu-card__E5hZb {\n    --c-edu-card__grid-template-areas: \"info actions\";\n  }\n}", ""]);
// Exports
exports.locals = {
	"c-edu-card__wrapper": "edu-card_c-edu-card__wrapper__1PNMt",
	"c-edu-card__title": "edu-card_c-edu-card__title__3RTHw",
	"c-edu-card__subtitle": "edu-card_c-edu-card__subtitle__3QsKS",
	"c-edu-card__info": "edu-card_c-edu-card__info__ZOvoF",
	"c-edu-card__extra-info": "edu-card_c-edu-card__extra-info__xWLlC",
	"c-edu-card__actions": "edu-card_c-edu-card__actions__xYZ_I",
	"c-edu-card": "edu-card_c-edu-card__E5hZb"
};
module.exports = exports;
