import React, { useEffect, useState } from 'react';
import { LanguageProficiencyInput } from 'cv-app/shared/services/language-proficiency/language-proficiency-types';
import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import LanguagesLister from './components/languages-lister/languages-lister';
import Loader from 'cv-app/shared/ui/molecules/loader/loader';
import './step-languages-skills.module.scss';
import { AuthInfo, useAuth } from 'cv-app/utils/api-auth-helper';
import { PostListData, SectionData } from 'cv-app/shared/services/sections';
import { LanguageProficiencyEntity } from 'cv-app/shared/redux/language-proficiency/language-proficiency.slice';
import { StatusEnums } from 'cv-app/utils/status-enums';
import Modal from 'cv-app/shared/ui/molecules/modal/modal';
import { LanguageProficiency } from 'cv-app/shared/redux/language-proficiency/language-proficiency.slice';
import { SectionType } from 'cv-app/utils/section-types';
import { CvSectionStateEntity } from 'cv-app/shared/redux/cvs/cvs.slice';
import {
  createEmptyLanguageProficiency,
  createNewLanguageProficiency
} from 'cv-app/utils/language-proficiency/language-proficiency-helper';
import { getNextListItemId } from 'cv-app/utils/helpers/list-item-helper';
import StepLister from 'cv-app/shared/ui/modules/step-lister/step-lister';

/* eslint-disable-next-line */
export interface StepLanguagesSkillsProps {
  loadingStatus: StatusEnums;
  languagesLoadingStatus: StatusEnums;
  loadingStatusLevels: StatusEnums;
  errorMessage: string;
  languageProficiencies: LanguageProficiencyEntity[];
  sectionData: SectionData<LanguageProficiencyInput>;
  languageProficiencyLevels: LanguageProficiency[];
  loadingStatusCvStates: StatusEnums;
  cvSectionsStates: CvSectionStateEntity[];
  fetchCvSectionStates: (authInfo: AuthInfo) => void;
  fetchLanguageProficiency: (AuthInfo) => void;
  fetchLanguageOptions: (AuthInfo) => void;
  postLanguageProficiencyInput: (postData: PostListData<LanguageProficiencyEntity>) => void;
  languageOptions: SelectOption[];
  fetchLanguageProficiencyLevels: (AuthInfo) => void;
}

interface LanguageProficienciesState {
  newLanguageForm?: LanguageProficiencyEntity;
}

export function StepLanguagesSkills(props: StepLanguagesSkillsProps) {

  /**STATES */
  const [entityToDelete, setEntityToDelete] = useState(-1);
  const [languageProficienciesStateState, setLanguageProficienciesStateState] = useState<LanguageProficienciesState>({
    newLanguageForm: createNewLanguageProficiency(props.languageProficiencies)
  });
  const [showPostErrorModal, setShowPostErrorModal] = useState(false);

  //#region HOOKS
  const authInfo = useAuth();

  // Levels data
  useEffect(() => {
    if (props.loadingStatusLevels === StatusEnums.Initial) {
      props.fetchLanguageProficiencyLevels(authInfo);
    }
  });
  // Language selector data
  useEffect(() => {
    if (props.languagesLoadingStatus === StatusEnums.Initial) {
      props.fetchLanguageOptions(authInfo);
    }
  });

  useEffect(() => {
    setShowPostErrorModal(props.loadingStatus === StatusEnums.SubmitError);
  }, [props.loadingStatus]);
  //#endregion

  //#region CALLBACKS
  const deleteLanguageProficiency = (languageProficiencyId: number) => {
    setEntityToDelete(languageProficiencyId);
  };

  //#endregion

  function getNewListOfEntities(newLanguageProficiency: LanguageProficiencyEntity) {
    const languageProficiencies: LanguageProficiencyEntity[] = [];
    props.languageProficiencies.forEach(element => {
      languageProficiencies.push(element);
    });
    const indexOf = languageProficiencies.findIndex(x => x.id === languageProficienciesStateState.newLanguageForm.id);
    if (indexOf === -1) {
      languageProficiencies.push(languageProficienciesStateState.newLanguageForm);
    } else {
      languageProficiencies[indexOf] = languageProficienciesStateState.newLanguageForm;
    }
    return languageProficiencies;
  }

  const addLanguageProficiency = (newLanguageProficiency: LanguageProficiencyEntity) => {
    newLanguageProficiency.id = getNextListItemId(props.languageProficiencies);
    props.postLanguageProficiencyInput({
      authInfo: authInfo,
      entities: getNewListOfEntities(newLanguageProficiency)
    });

    resetForm();
  };

  const editLanguageProficiency = (newLanguageProficiency: LanguageProficiencyEntity) => {
    const languageProficiencies = props.languageProficiencies.map(languageProficiency => {
      if (languageProficiency.id === newLanguageProficiency.id) {
        return newLanguageProficiency;
      } else {
        return languageProficiency;
      }
    });

    props.postLanguageProficiencyInput({
      authInfo: authInfo,
      entities: languageProficiencies,
    });
    resetForm();
  };

  const resetForm = () => {
    const newState = { ...languageProficienciesStateState };
    newState.newLanguageForm = createEmptyLanguageProficiency();
    setLanguageProficienciesStateState(newState);
  };

  const handleChange = (newData: LanguageProficiencyEntity) => {
    const newState = { ...languageProficienciesStateState };
    newState.newLanguageForm = newData;
    setLanguageProficienciesStateState(newState);
  };


  if (props.loadingStatusLevels === StatusEnums.Initial) {
    return (<Loader></Loader>);
  }

  //#region RENDERING
  return (
    <StepLister
      entities={props.languageProficiencies}
      cvSectionsStates={props.cvSectionsStates}
      loadingStatus={props.loadingStatus}
      loadingStatusCvStates={props.loadingStatusCvStates}
      errorMessage={props.errorMessage}
      stepKey={SectionType.Language}
      entityToDelete={entityToDelete}
      setParentEntityToDelete={setEntityToDelete}
      fetchEntities={props.fetchLanguageProficiency}
      fetchCvSectionStates={props.fetchCvSectionStates}
      postEntityList={props.postLanguageProficiencyInput}
    >
      <LanguagesLister
        languageProficiencyLevels={props.languageProficiencyLevels}
        newLanguageForm={languageProficienciesStateState.newLanguageForm}
        inputChangeCallback={handleChange}
        editLanguageCallback={editLanguageProficiency}
        addLanguageCallback={addLanguageProficiency}
        languageProficiencyCards={props.languageProficiencies}
        deleteCallback={deleteLanguageProficiency}
        languageOptions={props.languageOptions}
        isEmpty={props.languageProficiencies.length === 0}
        cancelLanguageCallback={resetForm}
      ></LanguagesLister>
      <Modal
        modalId='postError'
        isVisible={showPostErrorModal}
        modalText='Something went wrong, please try again later.'
        textAccept='Ok'
        handleAccept={() => {
          setShowPostErrorModal(false);
        }}
      ></Modal>
    </StepLister>
  );
  //#endregion
}

export default StepLanguagesSkills;
