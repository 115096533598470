import { connect } from "react-redux";
import {fetchLanguageProficiency, postLanguageProficiencyInput, fetchLanguageProficiencyLevels} from 'cv-app/shared/redux/language-proficiency/language-proficiency.slice';
import {fetchCvSectionStates } from "cv-app/shared/redux/cvs/cvs.slice";
import {fetchLanguageOptions} from 'cv-app/shared/redux/language-options/language-options.slice';
import { StepLanguagesSkills } from 'cv-app/pages/step-languages-skills/step-languages-skills';

const mapStateToProps = (state: any) => ({
    loadingStatus: state.languageProficiency.loadingStatus,
    languagesLoadingStatus: state.languageOptions.loadingStatus,
    loadingStatusCvStates: state.cvs.loadingSectionsStatus,
    cvSectionsStates: state.cvs.sections,
    loadingStatusLevels: state.languageProficiency.loadingStatusLevels,
    errorMessage: state.languageProficiency.error,
    languageProficiencies: Object.values(state.languageProficiency.sectionData.input.languageProficiencies),
    sectionData: state.languageProficiency.sectionData,
    languageOptions: state.languageOptions.languageOptions,
    languageProficiencyLevels: state.languageProficiency.languageProficiencyLevels
});

const mapDispatchToProps = {
    fetchLanguageProficiency,
    fetchLanguageOptions,
    postLanguageProficiencyInput,
    fetchLanguageProficiencyLevels,
    fetchCvSectionStates
};

export default( connect(mapStateToProps, mapDispatchToProps)(StepLanguagesSkills))