// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dashboard_c-dashboard__header__1lEJE {\n  display: flex;\n}", ""]);
// Exports
exports.locals = {
	"c-dashboard__header": "dashboard_c-dashboard__header__1lEJE"
};
module.exports = exports;
