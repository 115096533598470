import React from 'react';
import Button from 'cv-app/shared/ui/molecules/button/button';

import './modal.module.scss';

/* eslint-disable-next-line */
export interface ModalProps {
  modalId: string;
  isVisible: boolean;
  modalText?: string;
  modalContent?: JSX.Element;
  buttonsPosition?: 'start' | 'end' | 'center' | 'between' | 'around';
  textCancel?: string;
  textAccept?: string;  
  customClass?: string;
  handleCancel?: () => void;
  handleAccept?: () => void;  
}

export function Modal(props: ModalProps) {
  return (
    <div className={`o-modal-wrapper ${props.isVisible ? "is-active" : ""}`}
      tabIndex={-1} role="dialog" aria-labelledby={props.modalId} aria-hidden={!props.isVisible}>      
      <div className={`o-modal c-box u-margin-auto u-padding-none ${props.customClass ? props.customClass : ""}`} role="document">
        <div className="o-modal__body" id={props.modalId}>
          {!!props.modalText && <p>{props.modalText}</p>}
          {!!props.modalContent && props.modalContent}
          <div className={`o-flex o-flex--justify-content-${props.buttonsPosition || "end"}`}>
            {props.textCancel &&
              <Button
                buttonText={props.textCancel}
                btnType='secondary'
                callbackOnClick={props.handleCancel}
              ></Button>
            }
            {props.textAccept &&
              <Button
                buttonText={props.textAccept}
                btnType='primary'
                callbackOnClick={props.handleAccept}
              ></Button>
            }
          </div>

        </div>
      </div>
    </div>
  );
}

export default Modal;
