import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getLanguageProficiencies, getLanguageProficiencyLevels, postLanguageProficiency } from 'cv-app/shared/services/language-proficiency/language-proficiency-service';
import { LanguageProficiencyInput } from 'cv-app/shared/services/language-proficiency/language-proficiency-types';
import { PostListData, SectionData } from 'cv-app/shared/services/sections';
import { AuthInfo } from 'cv-app/utils/api-auth-helper';
import { SectionType } from 'cv-app/utils/section-types';
import { StatusEnums } from 'cv-app/utils/status-enums';

export const LANGUAGE_PROFICIENCY_FEATURE_KEY = 'languageProficiency';

/*
 * Update these interfaces according to your requirements.
 */
export interface LanguageProficiencyEntity {
  id: number;
  languageId: string;
  level: number;
  isNative: boolean;
}

export interface LanguageProficiencyState
  extends EntityState<LanguageProficiencyEntity> {
  loadingStatus: StatusEnums
  error: string;
  sectionData: SectionData<LanguageProficiencyInput>;
  languageProficiencyLevels: LanguageProficiency[];
  loadingStatusLevels: StatusEnums;
}
export interface LanguageProficiency {
  id: number;
  code: string;
  name: string;
}

export const languageProficiencyAdapter = createEntityAdapter<LanguageProficiencyEntity>();

/**
 * Export an effect using createAsyncThunk from
 * the Redux Toolkit: https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(fetchLanguageProficiency())
 * }, [dispatch]);
 * ```
 */
export const fetchLanguageProficiency = createAsyncThunk(
  'languageProficiency/fetchStatus',
  async (authInfo: AuthInfo, thunkAPI) => {
    const response = await getLanguageProficiencies(authInfo);
    return Promise.resolve(response);
  }
);

export const postLanguageProficiencyInput = createAsyncThunk(
  'languageProficiency/postStatus',
  async (postData: PostListData<LanguageProficiencyEntity>) => {
    const response = await postLanguageProficiency({languageProficiencies: postData.entities, type: SectionType.Language}, postData.authInfo)
    return Promise.resolve(response);
  }
)
export const fetchLanguageProficiencyLevels = createAsyncThunk(
  'languageProficiencyLevel/fetchStatus',
  async (authInfo: AuthInfo) => {
    const response = await getLanguageProficiencyLevels(authInfo);
    return Promise.resolve(response);
  }
)

export const initialLanguageProficiencyState: LanguageProficiencyState = languageProficiencyAdapter.getInitialState(
  {
    loadingStatus: StatusEnums.Initial,
    error: null,
    sectionData: {
      id: '',
      cvId: '',
      locale: '',
      canPublish: false,
      changedBy: '',
      changedOn: '',
      type: SectionType.Language,
      input: {
        type: SectionType.Language,
        languageProficiencies: []
      }
    },
    loadingStatusLevels: StatusEnums.Initial,
    languageProficiencyLevels: []
  }
);

export const languageProficiencySlice = createSlice({
  name: LANGUAGE_PROFICIENCY_FEATURE_KEY,
  initialState: initialLanguageProficiencyState,
  reducers: {
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        postLanguageProficiencyInput.fulfilled,
        (
          state: LanguageProficiencyState,
          action: PayloadAction<SectionData<LanguageProficiencyInput>>
        ) => {
          //languageProficiencyAdapter.setAll(state, action.payload.input.languageProficiencies);
          state.sectionData = action.payload;
          state.loadingStatus = StatusEnums.Loaded;
        }
      )
      .addCase(
        postLanguageProficiencyInput.pending,
        (state: LanguageProficiencyState) => {
          state.loadingStatus = StatusEnums.Loading;
        }
      )
      .addCase(
        fetchLanguageProficiency.pending,
        (state: LanguageProficiencyState) => {
          state.loadingStatus = StatusEnums.Loading;
        }
      )
      .addCase(
        fetchLanguageProficiency.fulfilled,
        (
          state: LanguageProficiencyState,
          action: PayloadAction<SectionData<LanguageProficiencyInput>>
        ) => {
          //languageProficiencyAdapter.setAll(state, action.payload.input.languageProficiencies);
          state.sectionData = action.payload;
          state.loadingStatus = StatusEnums.Loaded;
        }
      )
      .addCase(
        fetchLanguageProficiency.rejected,
        (state: LanguageProficiencyState, action) => {
          if (action.error.message === '404') {
            state.loadingStatus = StatusEnums.NotFound;
            state.sectionData = initialLanguageProficiencyState.sectionData;
          } else {
            state.loadingStatus = StatusEnums.Error;
          }
          state.error = action.error.message;
        }
      )
      .addCase(
        postLanguageProficiencyInput.rejected,
        (state: LanguageProficiencyState, action) => {
          state.loadingStatus = StatusEnums.SubmitError;
          state.error = action.error.message;
        })
      .addCase(
        fetchLanguageProficiencyLevels.pending,
        (state: LanguageProficiencyState) => {
          state.loadingStatusLevels = StatusEnums.Loading;
        }
      )
      .addCase(
        fetchLanguageProficiencyLevels.fulfilled,
        (
          state: LanguageProficiencyState,
          action: PayloadAction<LanguageProficiency[]>
        ) => {
          state.languageProficiencyLevels = action.payload;
          state.loadingStatusLevels = StatusEnums.Loaded;
        }
      )
      .addCase(
        fetchLanguageProficiencyLevels.rejected,
        (state: LanguageProficiencyState, action) => {
          state.loadingStatusLevels = StatusEnums.Error;
          state.error = action.error.message;
        }
      )
  },
});

/*
 * Export reducer for store configuration.
 */
export const languageProficiencyReducer = languageProficiencySlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(languageProficiencyActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const languageProficiencyActions = languageProficiencySlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllLanguageProficiency);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = languageProficiencyAdapter.getSelectors();

export const getLanguageProficiencyState = (
  rootState: unknown
): LanguageProficiencyState => rootState[LANGUAGE_PROFICIENCY_FEATURE_KEY];

export const selectAllLanguageProficiency = createSelector(
  getLanguageProficiencyState,
  selectAll
);

export const selectLanguageProficiencyEntities = createSelector(
  getLanguageProficiencyState,
  selectEntities
);
