import { dateIsValid } from 'cv-app/utils/helpers/form-helper';
import { stringIsNullOrEmpty } from 'cv-app/utils/helpers/string-helper';
import React, { useEffect, useState } from 'react';
import FormValidation from 'cv-app/shared/ui/molecules/form-validation/form-validation';
import { useTranslation } from 'react-i18next';
import './form-date.module.scss';

/* eslint-disable-next-line */
export interface FormDateProps {
  isRequired: boolean;
  doValidate: boolean;
  inputId: string;
  labelText: string;
  errorText?: string;
  inputValue?: string;
  isDisabled?: boolean;
  minDate?: string;
  maxDate?: string;
  onChangeCallback?: (newValue: string) => void;
  extraValidation?: (date: Date) => boolean;
}

export function FormDate(props: FormDateProps) {
  const [isValid, setIsValid] = useState(true);
  const [invalidErrorMessage, setInvalidErrorMessage] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setIsValid(validateValue(props.inputValue));
  }, [props.doValidate]);

  const cssClass = `c-field ${isValid ? '' : 'c-field--error'}`;
  const errorMessage = invalidErrorMessage ? t('invalid-date') : props.errorText;

  const handleChange = (e: React.ChangeEvent<HTMLDataElement>) => {
    const validValue = !props.doValidate || validateValue(e.target.value);
    setIsValid(validValue);
    // in case of field being reset, we prefer null instead of '' so our backend won't choke
    const newValue = stringIsNullOrEmpty(e.target.value) ? null : e.target.value;
    props.onChangeCallback(newValue);
  };

  const validateValue = (inputValue: string) => {
    setInvalidErrorMessage(false);
    // do not validate unless necesarry
    if (!props.doValidate) {
      return true;
    }

    // required and empty
    if (props.isRequired && stringIsNullOrEmpty(inputValue)) {
      return false;
    }

    // Check that is it a date

    if (!stringIsNullOrEmpty(inputValue)) {
      const asDate = new Date(inputValue);
      if (!dateIsValid(inputValue))
        return false;

      if (!stringIsNullOrEmpty(props.maxDate)) {
        const maxDate = new Date(props.maxDate);
        if (asDate > maxDate) {
          setInvalidErrorMessage(true);
          return false;
        }
      }

      if (props.extraValidation) {
        return props.extraValidation(asDate);
      }
    }

    if (inputValue < props.minDate) {
      return false;
    }

    return true;
  };

  return (
    <div className={cssClass}>
      <label htmlFor={props.inputId} className='c-field__label'>
        {props.isRequired &&
          <span className='c-field__required-mark'>*</span>}
        {props.labelText}
      </label>
      <input
        id={props.inputId}
        type='date'
        className='c-input'
        value={props.inputValue}
        disabled={props.isDisabled}
        onChange={handleChange}
        min={props.minDate}
        max={props.maxDate}
      ></input>
      {isValid}
      <FormValidation errorText={errorMessage} showError={!isValid}></FormValidation>
    </div>
  );
}

export default FormDate;
