import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';

export function dateIsValid(text: string): boolean {
  if (!text)
    return false;
  const asDate = new Date(text);
  if (isNaN(asDate.getTime()))
    return false;
  return true;
}

export function formatDateForInput(text: string) {
  if (!text) {
    return '';
  }
  const asDate = new Date(text);
  if (isNaN(asDate.getTime()))
    return '';

  const month = asDate.getMonth() + 1;

  const yearString = ('0000' + asDate.getFullYear()).slice(-4);
  const monthString = ('00' + month).slice(-2);
  const dayString = ('00' + asDate.getDate()).slice(-2);
  const stringDate = `${yearString}-${monthString}-${dayString}`;
  return stringDate;
}

export function isValidOption(optionKey: string, options: SelectOption[]) {
  if (optionKey) {
    return Array.from(options, o => o.optionValue).includes(optionKey.toString());
  }
  return false;
}

export function getOptionName(optionKey: string, options: SelectOption[]) {
  if (optionKey) {
    const option = options.find(x => x.optionValue === optionKey.toString());
    if (option)
      return option.optionName;
  }
  return '';
}

export function isValidDateRange(startDate: string, endDate: string, sameDay = true): boolean {
  const minimalDate = new Date('1950-01-01T00:00:00');
  const sDate = new Date(String(startDate));
  const eDate = new Date(endDate);

  const isStartDateValid = sDate >= minimalDate && sDate <= new Date(formatDateForInput(new Date().toISOString()));

  if (!endDate && isStartDateValid) {
    return true;
  }
  if (sameDay) {
    return isStartDateValid && eDate >= sDate;
  }

  return isStartDateValid && eDate > sDate;
}
