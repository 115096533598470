import { EducationEntity } from 'cv-app/shared/redux/education/education.slice';
import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import { compareHistoryDates, isValidHistoryItem } from 'cv-app/utils/base/history-helper';
import { isValidDateRange, isValidOption } from 'cv-app/utils/helpers/form-helper';
import { getNextListItemId, isValidListItem } from '../helpers/list-item-helper';
import { removeSpace } from '../helpers/string-helper';

export function createNewEducation(educations: EducationEntity[]): EducationEntity {
  return {
    ...createEmptyEducation(),
    id: getNextListItemId(educations)
  };
}

export function createEmptyEducation(): EducationEntity {
  const emptyEducation: EducationEntity = {
    id: -1,
    schoolName: '',
    educationDegreeId: '-1',
    fieldOfStudy: '',
    startDate: '',
    endDate: '',
    description: ''
  };
  return emptyEducation;
}

export function haveIdenticalData(education: EducationEntity, otherEducation: EducationEntity): boolean {
  return (
    education.schoolName === otherEducation.schoolName &&
    education.educationDegreeId === otherEducation.educationDegreeId &&
    education.fieldOfStudy === otherEducation.fieldOfStudy &&
    education.startDate === otherEducation.startDate &&
    education.endDate === otherEducation.endDate &&
    education.description === otherEducation.description
  );
}

export function validateData(education: EducationEntity, isFinished: boolean, degreeOptions: SelectOption[], educations?: EducationEntity[]): { isValid: boolean, message?: string, duplicate?: boolean } {
  if (!isValidListItem(education))
    return { isValid: false };
  if (!isValidHistoryItem(education, isFinished))
    return { isValid: false };
  if (education.schoolName === '' || education.fieldOfStudy === '')
    return { isValid: false };
  if (removeSpace(education.schoolName).length < 2 || education.schoolName.length > 80) {
    return { isValid: false };
  }
  if (removeSpace(education.fieldOfStudy).length < 2 || education.fieldOfStudy.length > 80) {
    return { isValid: false };
  }
  if (!isValidOption(education.educationDegreeId, degreeOptions)) {
    return { isValid: false };
  }
  if (!isValidDateRange(education.startDate, education.endDate, false)) {
    return { isValid: false };
  }
  if (education.description.length > 700) {
    return { isValid: false };
  }

  // const duplicateEducation = educations.find(el => {
  //   return education.schoolName === el.schoolName &&
  //     new Date(education.startDate).toLocaleDateString() === new Date(el.startDate).toLocaleDateString() &&
  //     el.id !== education.id;
  // });

  // if (duplicateEducation) {
  //   return { isValid: false, message: 'edu_duplicate', duplicate: true };
  // }

  return { isValid: true };
}

export function compareEducations(a: EducationEntity, b: EducationEntity) {
  const dateCompare = compareHistoryDates(a, b);
  if (dateCompare !== 0)
    return dateCompare;
  // in the unlikly event that two educations have the same start and end date, do it by schoolName
  return a.schoolName.localeCompare(b.schoolName);
}
