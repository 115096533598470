import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getNationalities } from 'cv-app/shared/services/data-service';
import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import { AuthInfo } from 'cv-app/utils/api-auth-helper';
import { StatusEnums } from 'cv-app/utils/status-enums';

export const NATIONALITIES_FEATURE_KEY = 'nationalities';

/*
 * Update these interfaces according to your requirements.
 */
export interface NationalitiesState extends EntityState<SelectOption> {
  loadingStatus: StatusEnums;
  error: string;
  nationalities: SelectOption[];
}

export const nationalitiesAdapter = createEntityAdapter<SelectOption>();


export const fetchNationalities = createAsyncThunk(
  'nationalities/fetchStatus',
  async (authInfo: AuthInfo, thunkAPI) => {
    const response = getNationalities(authInfo);
    return Promise.resolve(response);
  }
);

export const initialNationalitiesState: NationalitiesState = nationalitiesAdapter.getInitialState(
  {
    loadingStatus: StatusEnums.Initial,
    error: null,
    nationalities: []
  }
);

export const nationalitiesSlice = createSlice({
  name: NATIONALITIES_FEATURE_KEY,
  initialState: initialNationalitiesState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNationalities.pending, (state: NationalitiesState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(
        fetchNationalities.fulfilled,
        (
          state: NationalitiesState,
          action: PayloadAction<SelectOption[]>
        ) => {
          state.nationalities = action.payload;
          state.loadingStatus = StatusEnums.Loaded;
        }
      )
      .addCase(
        fetchNationalities.rejected,
        (state: NationalitiesState, action) => {
          state.loadingStatus = StatusEnums.Error;
          state.error = action.error.message;
        }
      );
  },
});

/*
 * Export reducer for store configuration.
 */
export const nationalitiesReducer = nationalitiesSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(nationalitiesActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const nationalitiesActions = nationalitiesSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllNationalities);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = nationalitiesAdapter.getSelectors();

export const getNationalitiesState = (rootState: unknown): NationalitiesState =>
  rootState[NATIONALITIES_FEATURE_KEY];

export const selectAllNationalities = createSelector(
  getNationalitiesState,
  selectAll
);

export const selectNationalitiesEntities = createSelector(
  getNationalitiesState,
  selectEntities
);
