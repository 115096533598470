import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import EducationForm from 'cv-app/pages/step-education-edit/components/education-form/education-form';

import './step-education-edit.module.scss';
import { EducationInput } from 'cv-app/shared/services/educations/education-types';
import { createEmptyEducation, createNewEducation, haveIdenticalData, validateData } from 'cv-app/utils/educations/education-helper';
import { stringIsNullOrEmpty, removeSpace } from 'cv-app/utils/helpers/string-helper';
import { AuthInfo, useAuth } from 'cv-app/utils/api-auth-helper';
import { PostListData, SectionData } from 'cv-app/shared/services/sections';
import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import Loader from 'cv-app/shared/ui/molecules/loader/loader';
import { StatusEnums } from 'cv-app/utils/status-enums';
import { EducationEntity } from 'cv-app/shared/redux/education/education.slice';
import EditArticle from 'cv-app/shared/ui/modules/edit-article/edit-article';
import { SectionType } from 'cv-app/utils/section-types';
import { CvSectionStateEntity } from 'cv-app/shared/redux/cvs/cvs.slice';
import StepBase from 'cv-app/shared/ui/modules/step-base/step-base';
import { useTranslation } from 'react-i18next';
import { getLegalEntitiesEducationDegrees } from 'cv-app/shared/services/educations/education-service';

/* eslint-disable-next-line */
export interface StepEducationEditProps {
  errorMessage: string;
  loadingStatus: StatusEnums;
  degreesLoadingStatus: StatusEnums;
  educations: EducationEntity[];
  sectionData: SectionData<EducationInput>;
  loadingStatusCvStates: StatusEnums;
  cvSectionsStates: CvSectionStateEntity[];
  legalEntityId: number;
  fetchCvSectionStates: (authInfo: AuthInfo) => void;
  fetchEducation: (authInfo: AuthInfo) => void;
  fetchDegree: (authInfo: AuthInfo) => void;
  postEducationInput: (postData: PostListData<EducationEntity>) => void;
}


export function StepEducationEdit(props: StepEducationEditProps) {
  /**STATES */
  //#region
  const [validationOn, setValidationOn] = useState(false);
  const [canReturn, setCanReturn] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isStudying, setIsStudying] = useState(false);
  const [educationDegrees, setEducationDegrees] = useState([]);
  // const [isDuplicate, setIsDuplicate] = useState(false);
  const [duplicateErrorMessage, setDuplicateErrorMessage] = useState('');
  //#endregion

  const authInfo = useAuth();
  const { cvid, educationid } = useParams<{ cvid: string, educationid: string }>();
  const { t } = useTranslation();

  //#region Loading data
  useEffect(() => {
    if (props.degreesLoadingStatus === StatusEnums.Initial) {
      props.fetchDegree(authInfo)
    }
  }, []);

  useEffect(() => {
    async function fetchEducationDegrees() {
      if (props.loadingStatus === StatusEnums.Initial || props.degreesLoadingStatus === StatusEnums.Loaded) {
        try {
          const response = await getLegalEntitiesEducationDegrees(authInfo, props.legalEntityId);
          setEducationDegrees(response);
        } catch (error) {
          console.log(error);
        }
      }
    }
    fetchEducationDegrees();
  }, [props.degreesLoadingStatus]);
  //#endregion

  //#region On save/cancel/modal
  const onSaveButtonClicked = () => {
    setIsSubmitting(true);
    setValidationOn(true);
  }
  const onCancelButtonClicked = () => {
    setIsSubmitting(false); // Probably not needed?
    setIsCancelling(true);
  }
  const cancelSubmission = () => {
    setIsSubmitting(false);
  }
  const stayOnPage = () => {
    setIsCancelling(false);
  }
  //#endregion

  const setInitialFormState = (e: EducationEntity, isNew: boolean) => {
    if (isNew) {
      return
    }
    const isStudying = stringIsNullOrEmpty(e.endDate);
    setIsStudying(isStudying)
  }

  const handleCheckboxChange = (isStudying: boolean) => {
    setIsStudying(isStudying);
  }

  const isValidEntity = (e: EducationEntity) => {
    const result = validateData(e, !isStudying, educationDegrees);
    // setIsDuplicate(result.duplicate);
    // setDuplicateErrorMessage(result.message);
    return result.isValid;
  }

  const preSubmissionHandleData = (e: EducationEntity) => {
    const newData: EducationEntity = {
      ...e,
      endDate: isStudying ? null : e.endDate,
      schoolName: removeSpace(e.schoolName),
      fieldOfStudy: removeSpace(e.fieldOfStudy),
      description: removeSpace(e.description)
    };    
    return newData;
  }

  function isNewEducation() {
    return !educationid;
  }

  const renderForm = (formInput: EducationEntity, dataChangeCallback: (data: EducationEntity) => void) => {
    return (
      <form className="c-form">
        <EducationForm
          doValidate={validationOn}
          isStudying={isStudying}
          dataChangeCallback={dataChangeCallback}
          checkboxChangeCallback={handleCheckboxChange}
          degreeDataSource={educationDegrees}
          educationData={formInput}
        ></EducationForm>
        {/* {isDuplicate && <span className='c-field__message'>{t(duplicateErrorMessage)}</span>} */}
      </form>)
  }


  /**RENDERING */
  //#region
  // On cancel or data saved successfully
  if (canReturn) {
    return (<Redirect to={`/cv/${cvid}/education`}></Redirect>)
  }

  return (
    <StepBase
      isInitialState={props.degreesLoadingStatus === StatusEnums.Initial}
      isError={props.loadingStatus === StatusEnums.Error}
      errorMessage={props.errorMessage}
      stepKey={SectionType.Education}
      nextBtnText={isNewEducation() ? "general__btn-add" : "general__btn-update"}
      prevBtnText={"general__btn-cancel"}
      onNextClicked={onSaveButtonClicked}
      onPrevClicked={onCancelButtonClicked}
      loadingStatusCvStates={props.loadingStatusCvStates}
      cvSectionsStates={props.cvSectionsStates}
      fetchCvSectionStates={props.fetchCvSectionStates}
    >
      <EditArticle
        entities={props.educations}
        loadingStatus={props.loadingStatus}
        errorMessage={props.errorMessage}
        entityToEditId={educationid}
        fetchEntities={props.fetchEducation}
        createEmptyEntity={createEmptyEducation}
        createNewEntity={createNewEducation}
        submitForm={isSubmitting}
        isValid={isValidEntity}
        cancelSubmission={cancelSubmission}
        continueEditing={stayOnPage}
        closeForm={() => {setCanReturn(true)}}
        preSubmissionHandleData={preSubmissionHandleData}
        setInitialSetForInput={setInitialFormState}
        renderForm={renderForm}
        postEntityList={props.postEducationInput}
        discardForm={isCancelling}
        haveIdenticalData={haveIdenticalData}
      ></EditArticle>
    </StepBase>
  )
  //#endregion

}

export default StepEducationEdit;
