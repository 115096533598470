import React, { useEffect, useState } from 'react';
import FormValidation from 'cv-app/shared/ui/molecules/form-validation/form-validation';
import { useTranslation } from 'react-i18next';
import { removeSpace } from 'cv-app/utils/helpers/string-helper';
import './form-input.module.scss';

/* eslint-disable-next-line */
export interface FormInputProps {
  type: 'input' | 'textarea' | 'range';
  isRequired: boolean;
  doValidate: boolean;
  inputValue?: string;
  fieldClassVariant?: string;
  inputId: string;
  labelText: string;
  errorText?: string;
  onChangeCallback?: (e) => void;
  minLength?: number;
  maxLenght?: number;
  pattern?: RegExp;
  isDisabled?: boolean;
  placeholder?: string;
  min?: number;
  max?: number;
  defaultChecked?: boolean;
  cssClass?: string;
  subLabelText?: string;
  maxLengthErrorText?: string;
  isMaxLengthError?: boolean;
}

export function FormInput(props: FormInputProps) {
  const [isValid, setIsValid] = useState(true);  
  const { t } = useTranslation();

  useEffect(() => {
    setIsValid(validateValue(props.inputValue))
  }, [props.doValidate])

  const cssClass = `c-field ${isValid ? "" : "c-field--error"}`;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLTextAreaElement>) => {
    const validValue = !props.doValidate || validateValue(e.target.value);
    setIsValid(validValue);
    props.onChangeCallback(e.target.value);
  }

  const validateValue = (inputValue: string | number) => {
    // do not validate unless necesarry
    if (!props.doValidate) {
      return true;
    }

    // required and empty
    if (props.isRequired && inputValue === "") {
      return false;
    }

    if ((props.type === 'textarea' || props.type === 'input') && (removeSpace(String(inputValue)).length < props.minLength || String(inputValue).length > props.maxLenght)) {
      return false;
    }

    // value doesn't match
    if (props.pattern && inputValue !== "") {
        const isValid = props.pattern.test(inputValue.toString());
        return isValid;
    }

    return true;
  }

  return (
    <div className={cssClass}>
      <label htmlFor={props.inputId} className="c-field__label">
        {props.isRequired &&
          <span className="c-field__required-mark">*</span>}
        {props.labelText}
        {props.subLabelText && <span className="subtitle">&nbsp;{props.subLabelText}</span>}
        <FormValidation errorText={props.maxLengthErrorText} showError={props.isMaxLengthError}></FormValidation>
      </label>
      {props.type === 'input' &&
        <input
        id={props.inputId}
        type="text"
        className={`c-input ${props.cssClass}`}
        placeholder={props.placeholder}
        onChange={handleChange}
        value={props.inputValue}
        disabled={props.isDisabled}
        minLength={props.minLength > 0 ? props.minLength : null}
      />}
      {props.type === 'textarea' &&
        <textarea
        id={props.inputId}
        className={`c-input c-input--text-area ${props.cssClass} ${props.isMaxLengthError ? 'slate-invalid' : ''}`}
        rows={15}
        value={props.inputValue}
        disabled={props.isDisabled}
        onChange={handleChange}
        minLength={props.minLength > 0 ? props.minLength : null}
        placeholder={props.placeholder}
        />
      }
      {props.type === 'range' &&
        <input
        id={props.inputId}
        type="range"
        className="c-language-card__slider"
        onChange={handleChange}
        value={props.inputValue}
        disabled={props.isDisabled}
        min={props.min}
        max={props.max}
        maxLength={props.maxLenght > 0 ? props.maxLenght : null}
      />
      }
      <FormValidation errorText={props.errorText} showError={!isValid || (removeSpace(props.inputValue).length < 2 && props.inputValue.length > 80)}></FormValidation>
    </div>
  );
}

export default FormInput;
