import React from 'react';

import './check-box.module.scss';

/* eslint-disable-next-line */
export interface CheckBoxProps {
  inputId: string;
  isChecked: boolean;
  labelText: string;
  extraCss?: string;
  changeCallback: (e) => void;
}

export function CheckBox(props: CheckBoxProps) {
  return (
    <div className={`c-input c-input--checkbox ${props.extraCss}`}>
      <label className="c-form-element" htmlFor={props.inputId}>
        {props.labelText}
        <input
          checked={props.isChecked}
          className="c-input__field"
          id={props.inputId}
          type="checkbox"
          onChange={props.changeCallback}
        ></input>
        <span className="c-checkmark"></span>
      </label>
    </div>
  );
}

export default CheckBox;
