import React from 'react';
import { Route, Switch } from 'react-router-dom';

import './main-content.module.scss';
import ConsultancyViewContainer from 'cv-app/pages/step-consultancy-view/containers/consultancy-view-container';
import DashboardContainer from 'cv-app/pages/dashboard/containers/dashboard-container';
import LanguageProficiencyContainer from 'cv-app/pages/step-languages-skills/language-proficiency-containers/language-proficiency-container';
import PersonalContainer from 'cv-app/pages/step-personal/containers/personal-container';
import EducationViewContainer from 'cv-app/pages/step-education-view/containers/education-view-container';
import EducationEditContainer from 'cv-app/pages/step-education-edit/containers/education-edit-container';
import ConsultancyEditContainer from 'cv-app/pages/step-consultancy-edit/containers/consultancy-edit-container';
import OtherWorkEditContainer from 'cv-app/pages/step-other-work-edit/containers/other-work-edit-container';
import OtherWorkViewContainer from 'cv-app/pages/step-other-work-view/containers/other-work-view-container';
import CertificatesContainer from 'cv-app/pages/step-certificates/containers/certificates-container';

/* eslint-disable-next-line */
export interface MainContentProps { }

export function MainContent(props: MainContentProps) {

  return (
    <Switch>
      <Route path="/cv/:cvid/work-history/other/add">
        <OtherWorkEditContainer></OtherWorkEditContainer>
      </Route>
      <Route path="/cv/:cvid/work-history/other/:workid">
        <OtherWorkEditContainer></OtherWorkEditContainer>
      </Route>
      <Route path="/cv/:cvid/work-history/other">
        <OtherWorkViewContainer></OtherWorkViewContainer>
      </Route>
      <Route path="/cv/:cvid/work-history/consultancy/add">
        <ConsultancyEditContainer></ConsultancyEditContainer>
      </Route>
      <Route path="/cv/:cvid/work-history/consultancy/:workhistoryid">
        <ConsultancyEditContainer></ConsultancyEditContainer>
      </Route>
      <Route path="/cv/:cvid/work-history/consultancy">
        <ConsultancyViewContainer></ConsultancyViewContainer>
      </Route>
      <Route path="/cv/:cvid/skills-and-languages">
        <LanguageProficiencyContainer></LanguageProficiencyContainer>
      </Route>
      <Route path="/cv/:cvid/certifications">
        <CertificatesContainer></CertificatesContainer>
      </Route>
      <Route path="/cv/:cvid/education/add">
        <EducationEditContainer></EducationEditContainer>
      </Route>
      <Route path="/cv/:cvid/education/:educationid">
        <EducationEditContainer></EducationEditContainer>
      </Route>
      <Route path="/cv/:cvid/education">
        <EducationViewContainer></EducationViewContainer>
      </Route>
      <Route path="/cv/:cvid/personal">
        <PersonalContainer></PersonalContainer>
      </Route>
      <Route path="/cv/personal">
        <PersonalContainer></PersonalContainer>
      </Route>
      {/* Not yet implemented in mvp */}
      {/* <Route path="/cv/:cvid">
        <CvDetail />
      </Route> */}
      <Route path="/">
        <DashboardContainer></DashboardContainer>
      </Route>
    </Switch>
  );
}

export default MainContent;
