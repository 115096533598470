import React, { useState } from 'react';
import CertificateItem from 'cv-app/pages/step-certificates/components/certificate-item/certificate-item';

import './certificates-lister.module.scss';
import { CertificateEntity } from 'cv-app/shared/redux/certificate/certificate.slice';
import { addAlignSelfStartClass } from 'cv-app/utils/certifications/certification-helper';
import CertificateEdit from '../certificate-edit/certificate-edit';

/* eslint-disable-next-line */
export interface CertificatesListerProps {
  deleteCallback: (certId: number) => void;
  editCallback: (certId: number) => void;
  certificates: CertificateEntity[];
  isInteractive: boolean;
  doValidate: boolean;
  certificateData: CertificateEntity;
  isNew: boolean;
  cancelCallback: () => void;
  saveButtonCallback: () => void;
  dataChangeCallback: (data: CertificateEntity) => void;
}


export function CertificatesLister(props: CertificatesListerProps) {
  const sortedCertificates = [...props.certificates].sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
  const items = sortedCertificates.map((card) => {
    if (card.id === props.certificateData.id) {
      return <CertificateEdit
        certificateData={props.certificateData}
        dataChangeCallback={props.dataChangeCallback}
        cancelCallback={props.cancelCallback}
        doValidate={props.doValidate}
        isNew={false}
        key={`cert-edit-card-${card.id}`}
        saveButtonCallback={props.saveButtonCallback}
      ></CertificateEdit>
    }
    return <CertificateItem
      certificate={card}
      isInteractive={props.isInteractive}
      deleteCallback={props.deleteCallback}
      editCallback={props.editCallback}
      key={`cert-card-${card.id}`}
      extraCss={addAlignSelfStartClass(sortedCertificates, props.certificateData.id, card.id)}
    ></CertificateItem>
  });

  return (
    <div className="c-card-lister">
      <div className="c-card-lister__item-wrapper">
        {items}
      </div>
    </div>
  );
}

export default CertificatesLister;
