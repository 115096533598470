
import { AuthInfo, fetchJson, fetchJsonPost } from 'cv-app/utils/api-auth-helper';
import { Language, LanguageProficiencyInput } from "cv-app/shared/services/language-proficiency/language-proficiency-types";
import { SelectOption } from "cv-app/shared/ui/molecules/form-select/form-select";
import { SectionData } from '../sections';
import { LanguageProficiency } from 'cv-app/shared/redux/language-proficiency/language-proficiency.slice'

const cachingKey = 'languages';
function getLanguagesFromApi(authInfo: AuthInfo)
    : Promise<SelectOption[]> {
    return new Promise((resolve) => {
        fetchJson<Language[]>('/api/languages', authInfo)
            .then((languages) => {
                const languageSelectOption: SelectOption[] = languages.map(d => {
                    return {
                        optionName: d.name,
                        optionValue: d.id.toString()
                    }
                })
                sessionStorage.setItem(cachingKey, JSON.stringify(languageSelectOption));
                resolve(languageSelectOption);
            })
    })
}
export function getLangauges(
    authInfo: AuthInfo): Promise<SelectOption[]> {
    const languages = JSON.parse(sessionStorage.getItem(cachingKey));
    if (languages) {
        return new Promise((resolve) => {
            resolve(languages);
        })
    }
    // If nothing is cached in the session, get from API
    return getLanguagesFromApi(authInfo);
}


export function getLanguageProficiencies(
    authInfo: AuthInfo): Promise<SectionData<LanguageProficiencyInput>> {
    return fetchJson<SectionData<LanguageProficiencyInput>>('/api/cvs/sections/language-proficiency?draft=true', authInfo);
}
export function postLanguageProficiency(input: LanguageProficiencyInput, authInfo: AuthInfo) {
    return fetchJsonPost<SectionData<LanguageProficiencyInput>>('/api/cvs/sections', input, authInfo)
}
export function getLanguageProficiencyLevels(authInfo: AuthInfo) {
    return fetchJson<LanguageProficiency[]>('/api/languageproficiencies',authInfo)
}
