import React from 'react';
import SimpleSvg from 'cv-app/shared/ui/molecules/simple-svg/simple-svg';

import './button.module.scss';

/* eslint-disable-next-line */
export interface ButtonProps {
  buttonText?: string;
  buttonCss?: string;
  buttonIcon?: string;
  buttonIconHeight?: number;
  buttonIconWidth?: number;
  buttonIconExtraCss?: string;
  btnType?: 'primary'|'secondary';
  isEnabled?: boolean;
  type?: 'submit' | 'button';
  callbackOnClick?: () => void;
}

export function Button(props: ButtonProps) {
  const {buttonCss, btnType, buttonIcon, buttonIconWidth, buttonIconHeight, buttonIconExtraCss, callbackOnClick, buttonText, isEnabled = true} = props;

  const btnClass = `c-btn ${buttonCss ? `${buttonCss}` : ""}
                      ${btnType ? `c-btn--${btnType}` : ""}
                      ${buttonIcon ?  "c-btn--icon" : ""}
                    `;
  return (
    <button className={btnClass} onClick={callbackOnClick} disabled={!isEnabled} type={props.type ? props.type : 'button'}>
      {buttonIcon &&
        <span className="c-btn__icon">
          <SimpleSvg
            svgId={buttonIcon}
            svgWidth={buttonIconWidth}
            svgHeight={buttonIconHeight}
            cssClass={buttonIconExtraCss}
          ></SimpleSvg>
        </span>
      }
      {buttonText}
    </button>
  );
}

export default Button;
