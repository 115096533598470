import { LanguageProficiencyEntity } from "cv-app/shared/redux/language-proficiency/language-proficiency.slice";
import { SelectOption } from "cv-app/shared/ui/molecules/form-select/form-select";
import { getNextListItemId } from "../helpers/list-item-helper";


export function haveIdenticalData(languageProficiency: LanguageProficiencyEntity, otherLanguageProficiencies: LanguageProficiencyEntity[]): boolean {
  if (otherLanguageProficiencies.find(x => x.languageId == languageProficiency.languageId)) 
    return true
  return false;
}

export function createEmptyLanguageProficiency() {
  const languageProficiency: LanguageProficiencyEntity = {
    id: -1,
    isNative: false,
    languageId: '',
    level: 3,
  }
  return languageProficiency;
}

export function createNewLanguageProficiency(languageProficiencies: LanguageProficiencyEntity[]) {
  return {
    ...createEmptyLanguageProficiency(),
    id: getNextListItemId(languageProficiencies)
  };
}

export function validateData(languageProficiency: LanguageProficiencyEntity) {
  if (languageProficiency.languageId === '' || languageProficiency.level > 6 || languageProficiency.level < 0)
    return false;
  return true;
}
export function filteredOptions(languageProficiencies: LanguageProficiencyEntity[], options: SelectOption[]): SelectOption[] {
  var filtered = [];
  options.forEach(option => {
    if (languageProficiencies.find(x => x.languageId == option.optionValue))
        return
    filtered.push(option)
  });
  return filtered;
}