import { CONSULTANCY_FEATURE_KEY, consultancyReducer } from "./consultancy/consultancy.slice";
import { cvsReducer, CVS_FEATURE_KEY } from "./cvs/cvs.slice";
import { educationReducer, EDUCATION_FEATURE_KEY } from "./education/education.slice";
import { nationalitiesReducer, NATIONALITIES_FEATURE_KEY } from "./data/nationalities/nationalities.slice";
import { teamsReducer, TEAMS_FEATURE_KEY } from "./data/teams/teams.slice";
import { ME_FEATURE_KEY, meReducer } from "./me/me.slice";
import { PERSONAL_FEATURE_KEY, personalReducer} from "./personal/personal.slice";
import { degreeReducer, DEGREE_FEATURE_KEY } from "./data/degree/degree.slice";


import {
    LANGUAGE_PROFICIENCY_FEATURE_KEY,
    languageProficiencyReducer,
  } from 'cv-app/shared/redux/language-proficiency/language-proficiency.slice';

  import {
    LANGUAGE_OPTIONS_FEATURE_KEY,
    languageOptionsReducer,
  } from 'cv-app/shared/redux/language-options/language-options.slice';
import { jobsReducer, JOBS_FEATURE_KEY } from "./jobs/jobs.slice";
import { certificateReducer, CERTIFICATE_FEATURE_KEY } from "cv-app/shared/redux/certificate/certificate.slice";
import { industriesReducer, INDUSTRIES_FEATURE_KEY } from "./data/industries/industries.slice";
import { entitiesReducer, ENTITIES_FEATURE_KEY } from "./data/entities/entities.slice";


export const reducers = {
    [NATIONALITIES_FEATURE_KEY]: nationalitiesReducer,
    [ENTITIES_FEATURE_KEY]: entitiesReducer,
    [TEAMS_FEATURE_KEY]: teamsReducer,
    [CVS_FEATURE_KEY]: cvsReducer,
    [ME_FEATURE_KEY]: meReducer,
    [CONSULTANCY_FEATURE_KEY]: consultancyReducer,
    [LANGUAGE_OPTIONS_FEATURE_KEY]: languageOptionsReducer,
    [LANGUAGE_PROFICIENCY_FEATURE_KEY]: languageProficiencyReducer,
    [PERSONAL_FEATURE_KEY]: personalReducer,
    [EDUCATION_FEATURE_KEY]: educationReducer,
    [DEGREE_FEATURE_KEY]: degreeReducer,
    [JOBS_FEATURE_KEY]: jobsReducer,
    [CERTIFICATE_FEATURE_KEY]: certificateReducer,
    [INDUSTRIES_FEATURE_KEY]: industriesReducer
}
