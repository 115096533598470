import React from 'react';

import './section.module.scss';

/* eslint-disable-next-line */
export interface SectionProps {}

export function Section(props: React.PropsWithChildren<SectionProps>) {
  return (
    <section className="c-section u-bg-color-accent1">
      <div className="o-wrapper">
        {props.children}
      </div>
    </section>
  );
}

export default Section;
