import { ConsultancyEntity } from 'cv-app/shared/redux/consultancy/consultancy.slice';
import { CvSectionStateEntity } from 'cv-app/shared/redux/cvs/cvs.slice';
import { getLegalEntitieIndustries } from 'cv-app/shared/services/data-service';
import { PostListData } from 'cv-app/shared/services/sections';
import EditArticle from 'cv-app/shared/ui/modules/edit-article/edit-article';
import StepBase from 'cv-app/shared/ui/modules/step-base/step-base';
import { AuthInfo, useAuth } from 'cv-app/utils/api-auth-helper';
import { createEmptyConsultancy, createNewConsultancy, haveIdenticalData, validateConsultancy } from 'cv-app/utils/consultancy/consultancy-helper';
import { stringIsNullOrEmpty, removeSpace } from 'cv-app/utils/helpers/string-helper';
import { SectionType } from 'cv-app/utils/section-types';
import { StatusEnums } from 'cv-app/utils/status-enums';
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import ConsultancyForm from './components/consultancy-form';
import './step-consultancy-edit.module.scss';

/* eslint-disable-next-line */
export interface StepConsultancyEditProps {
  errorMessage: string;
  loadingStatus: StatusEnums;
  loadingStatusPersonal: StatusEnums;
  entities: ConsultancyEntity[];
  loadingStatusCvStates: StatusEnums;
  cvSectionsStates: CvSectionStateEntity[];
  legalEntityId: number;
  fetchPersonal: (authInfo: AuthInfo) => void;
  fetchCvSectionStates: (authInfo: AuthInfo) => void;
  fetchConsultancy: (authInfo: AuthInfo) => void;
  postConsultancyInput: (postData: PostListData<ConsultancyEntity>) => void;
}

export function StepConsultancyEdit(props: StepConsultancyEditProps) {
  /**STATES */
  //#region
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [doValidate, setDoValidate] = useState(false);
  const [canReturn, setCanReturn] = useState(false);
  const [isOngoing, setIsOngoing] = useState(false);
  const [isDelaware, setIsDelaware] = useState(true);
  const [industries, setIndustries] = useState([]);    
  //#endregion

  /**HOOKS */
  //#region
  const { cvid, workhistoryid } = useParams<{ cvid: string, workhistoryid: string }>();

  const authInfo = useAuth();

  useEffect(() => {
    if (props.loadingStatus === StatusEnums.Initial) {
      props.fetchPersonal(authInfo);
    }
  }, []);

  useEffect(() => {
    async function fetchIndustries() {
      if (props.loadingStatusPersonal === StatusEnums.Loaded) {
        try {
          const response = await getLegalEntitieIndustries(authInfo, props.legalEntityId);
          setIndustries(response);          
        } catch (error) {
          console.log(error);
        }
      }
    }
    fetchIndustries();
  }, [props.loadingStatusPersonal]);
  //#endregion

  //#region On save/cancel/modal
  const onSaveButtonClicked = () => {
    setIsSubmitting(true);
    setDoValidate(true);
  }
  const onCancelButtonClicked = () => {
    setIsSubmitting(false); // Probably not needed?
    setIsCancelling(true);
  }
  const cancelSubmission = () => {
    setIsSubmitting(false);
  }
  const stayOnPage = () => {
    setIsCancelling(false);
  }
  //#endregion

  const isValidEntity = (c: ConsultancyEntity) => {
    return validateConsultancy(c, !isOngoing, industries);
  }
  const preSubmissionHandleData = (c: ConsultancyEntity) => {
    const newProject: ConsultancyEntity = {
      ...c,
      customerName: removeSpace(c.customerName),
      projectInformation: removeSpace(c.projectInformation),
      role: removeSpace(c.role),
      description: removeSpace(c.description),    
      startDate: c.startDate,      
      endDate: isOngoing ? null : c.endDate,
      isDelaware: isDelaware,
      timesheetCode: isDelaware ? removeSpace(c.timesheetCode) : null
    }
    return newProject;
  }

  const onCheckboxClickedCallback = (isChecked: boolean) => {
    setIsOngoing(isChecked);
  }
  const onIsDelwareChangedCallback = (isDelaware: boolean) => {
    setIsDelaware(isDelaware);
  }
  const setInitialFormState = (c: ConsultancyEntity, isNew: boolean) => {
    if (isNew)
      return;
    setIsDelaware(c.isDelaware);
    setIsOngoing(stringIsNullOrEmpty(c.endDate));
  }

  function isNew() {
    if (workhistoryid)
      return false;
    return true;
  }

  /**RENDERING helpers */
  const renderForm = (formInput: ConsultancyEntity, dataChangeCallback: (data: ConsultancyEntity) => void) => {
    return (
      <ConsultancyForm
        doValidate={doValidate}
        isOnGoingProject={isOngoing}
        isDelaware={isDelaware}
        formInput={formInput}
        dataChangeCallback={dataChangeCallback}
        checkboxChangeCallback={onCheckboxClickedCallback}
        radioButtonChangedCallback={onIsDelwareChangedCallback}
        industries={industries}
      ></ConsultancyForm>
    )
  }

  /**RENDERING */
  //#region

  // On cancel or data saved successfully
  if (canReturn) {
    return (<Redirect to={`/cv/${cvid}/work-history/consultancy`}></Redirect>)
  }

  // Page view
  return (
    <StepBase
      isInitialState={false}
      isError={props.loadingStatus === StatusEnums.Error}
      errorMessage={props.errorMessage}
      stepKey={SectionType.Consultancy}
      nextBtnText={isNew() ? "general__btn-add" : "general__btn-update"}
      prevBtnText={"general__btn-cancel"}
      onNextClicked={onSaveButtonClicked}
      onPrevClicked={onCancelButtonClicked}
      loadingStatusCvStates={props.loadingStatusCvStates}
      cvSectionsStates={props.cvSectionsStates}
      fetchCvSectionStates={props.fetchCvSectionStates}
    >
      <EditArticle
        entities={props.entities}
        loadingStatus={props.loadingStatus}
        errorMessage={props.errorMessage}
        entityToEditId={workhistoryid}
        fetchEntities={props.fetchConsultancy}
        createEmptyEntity={createEmptyConsultancy}
        createNewEntity={createNewConsultancy}
        submitForm={isSubmitting}
        isValid={isValidEntity}
        cancelSubmission={cancelSubmission}
        continueEditing={stayOnPage}
        closeForm={() => { setCanReturn(true) }}
        preSubmissionHandleData={preSubmissionHandleData}
        setInitialSetForInput={setInitialFormState}
        renderForm={renderForm}
        postEntityList={props.postConsultancyInput}
        discardForm={isCancelling}
        haveIdenticalData={haveIdenticalData}
      ></EditArticle>
    </StepBase>
  );
  //#endregion
}

export default StepConsultancyEdit;
