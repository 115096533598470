import React, { useCallback, useEffect, useState } from 'react'
import { deserializeFromHtml, serialize, htmlDecode } from "cv-app/shared/services/html-serialize/html-serialize";
import { useMemo } from "react";
import { createEditor, BaseEditor, Descendant, Node, Editor } from 'slate'
import { withHistory } from "slate-history";
import { Editable, ReactEditor, Slate, withReact } from "slate-react";
import Box from "cv-app/shared/ui/layout/box/box";
import { Toolbar } from '../toolbar/toolbar';
import { BlockButton } from '../block-button/block-button';
import { Element } from '../slate-element/slate-element';
import FormValidation from '../../molecules/form-validation/form-validation';
import './rich-text-editor.scss';

interface RichTextEditorProps {
  title: string;
  value: string;
  placeholder: string;
  helpText: string;
  errorText: string;
  doValidate: boolean;
  maxLength: number;
  maxLengthErrorText: string;
  onChange: (value: string) => void;
  subtitle?: string;
}

type CustomElement = { type: 'paragraph'; children: CustomText[] }
type CustomText = { text: string }

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor
    Element: CustomElement
    Text: CustomText
  }
}

export function RichTextEditor(props: RichTextEditorProps) {
  const [isValid, setIsValid] = useState(true);
  const [isMaxLengthError, setIsMaxLengthError] = useState(false);

  useEffect(() => setIsValid(validateValue(htmlDecode(props.value))), [props.doValidate, props.value]);

  const validateValue = (inputValue: string | number) => {
    if (!props.doValidate) {
      return true;
    }
    if (String(inputValue).length < 200 && !isMaxLengthError) {
      return false;
    }
    return true;
  }

  const renderElement = useCallback(props => <Element {...props} />, []);
  const editor = useMemo(() => withReact(withHistory(createEditor())), []);

  let value = props.value;

  if (props.value.includes('<![CDATA')) {
    value = props.value.replace('<![CDATA[', '').slice(0, -3);
  }

  const slateValue = useMemo(() => {
    if (/(<([^>]+)>)/ig.test(value)) {
      editor.children = deserializeFromHtml(value);
    } else {
      editor.children = [
        {
          type: 'paragraph',
          children: [{ text: value }],
        },
      ];
    }
    Editor.normalize(editor, { force: true });
    return editor.children;
  }, [editor, value]);

  const handleDOMBeforeInput = (e) => {
    function serializeString(value?: Descendant[]) {
      return (value || []).map((n) => Node.string(n)).join()
    }

    const inputType = e.inputType;
    if (inputType === 'insertText' || inputType === 'insertFromPaste') {
        if (
          e.inputType !== 'insertText' &&
          e.inputType !== 'insertFromPaste'
        ) {
          return;
        }

        const input = e.data || e.dataTransfer?.getData('text/plain');
        if (!input) return;

        const sel = [
          editor.selection?.anchor?.offset || 0,
          editor.selection?.focus?.offset || 0
        ].sort();

        const text = serializeString(editor.children);

        const newText = text.substring(0, sel[0]) + input + text.substring(sel[1])

        if (newText.length > 700) e.preventDefault()
    }
  };

  const onChange = useCallback(
    (newValue) => {
      editor.children = newValue;
      const serializedValue: string = newValue.map(v => serialize(v)).join('');
      const value = serializedValue;
      const decodedValue = htmlDecode(serializedValue);
      if (decodedValue.length > props.maxLength) {
        setIsMaxLengthError(true);
      } else {
        setIsMaxLengthError(false);
      }
      props.onChange(value);
    },
    [editor, props]
  );

  return (
    <div>
      <Box isBordered={true}>
        <div className="c-form__input-grid c-form__input-grid--cols-ration-1-2">
          <div className="u-padding" dangerouslySetInnerHTML={{ __html: props.helpText }}></div>
          <Slate editor={editor} initialValue={slateValue} onChange={onChange}>
            <div style={{wordBreak: 'break-word'}}>
              <label htmlFor="description" className='c-field__label'>
                <span className="c-field__required-mark">*</span>
                <span>{props.title}</span>
                {props.subtitle && <span className='subtitle'>&nbsp;({props.subtitle}: '{props.maxLength}')</span>}
                <FormValidation errorText={props.maxLengthErrorText} showError={isMaxLengthError}></FormValidation>
                <FormValidation errorText={props.errorText} showError={!isValid}></FormValidation>
              </label>
              <div className={'u-full-height slate-textarea c-input--text-area c-input ' + (isMaxLengthError || !isValid ? 'slate-invalid' : 'slate-valid')}>
                <Toolbar>
                  <BlockButton format="bulleted-list" icon="format_list_bulleted" />
                </Toolbar>
                <Editable
                  id='description'
                  renderElement={renderElement}
                  minLength={200}
                  placeholder={props.placeholder}
                  style={{outline:'none'}}
                  // onDOMBeforeInput={handleDOMBeforeInput}
                />
              </div>
            </div>
          </Slate>
        </div>
      </Box>
    </div>
  )
}
