// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".error-view_c-codeblock__xkFIN {\n  padding: 40px;\n  border-radius: 20px;\n  background-color: brandColor(\"mid-grey\");\n  color: brandColor(\"white\");\n}", ""]);
// Exports
exports.locals = {
	"c-codeblock": "error-view_c-codeblock__xkFIN"
};
module.exports = exports;
