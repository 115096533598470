import Media from 'cv-app/shared/ui/molecules/media/media';
import React from 'react';

import './passport.module.scss';

/* eslint-disable-next-line */
export interface PassportProps {
  userName: string;
  nationality: string;
  legalEntity: string;
  jobTitle: string;
  imageSrc: string;
}

export function Passport(props: PassportProps) {
  return (
    <div className="c-passport">
      <div className="c-passport__col c-passport__col--img-wrapper">
        <Media
          imgSrc={props.imageSrc}
          forceFit={true}
          imgAlt="profile"
          rounded={true}
          hasBorder={true}
          borderPlacing="left"
          placeholderSvgProps={
            {
              svgId: "icon-people",
              svgHeight: 135,
              svgWidth: 106
            }
          }
        ></Media>
      </div>
      <div className="c-passport__col">
        <div className="c-passport__details">
          <div className="c-passport__title">{props.userName}</div>
          <div className="c-passport__info">{props.nationality}</div>
          <div className="c-passport__subtitle">{props.jobTitle}</div>
        </div>
      </div>
    </div>
  );
}

export default Passport;
