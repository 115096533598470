import { MeEntity } from "cv-app/shared/redux/me/me.slice";
import { AuthInfo, fetchDataBase64, fetchJson } from "cv-app/utils/api-auth-helper";

export function getLoggedUserData(authInfo: AuthInfo) {
    return fetchJson<MeEntity>('/api/me', authInfo);
}

export function getUserPhoto(url: string, authInfo: AuthInfo) {
    return fetchDataBase64(url, authInfo);
}
