// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".box_c-box__3HFCG {\n  margin-bottom: 40px;\n  padding: 40px;\n  border-radius: 20px;\n}\n.box_c-box--section__1rgCs {\n  padding-left: var(--c-box-section--padding-left, 20px);\n  padding-right: var(--c-box-section--padding-right, 20px);\n}\n.box_c-box--bordered__tIglh {\n  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);\n}\n@media only screen and (min-width: 768px) {\n  .box_c-box__3HFCG {\n    --c-box-section--padding-left: 100px;\n    --c-box-section--padding-right: 100px;\n  }\n}\n.box_c-box__title__OyJLb {\n  font-size: 24px;\n  font-weight: 600;\n}\n.box_c-box__intro__I6_dL {\n  margin-bottom: 48px;\n}", ""]);
// Exports
exports.locals = {
	"c-box": "box_c-box__3HFCG",
	"c-box--section": "box_c-box--section__1rgCs",
	"c-box--bordered": "box_c-box--bordered__tIglh",
	"c-box__title": "box_c-box__title__OyJLb",
	"c-box__intro": "box_c-box__intro__I6_dL"
};
module.exports = exports;
