import { ConsultancyEntity } from "cv-app/shared/redux/consultancy/consultancy.slice";
import { htmlDecode } from "cv-app/shared/services/html-serialize/html-serialize";
import DateInterval from "cv-app/shared/ui/components/date-interval/date-interval";
import { RichTextEditor } from "cv-app/shared/ui/components/rich-text-editor/rich-text-editor";
import CheckBox from "cv-app/shared/ui/molecules/check-box/check-box";
import FormInput from "cv-app/shared/ui/molecules/form-input/form-input";
import FormSelect, { SelectOption } from "cv-app/shared/ui/molecules/form-select/form-select";
import RadioButtons from "cv-app/shared/ui/molecules/radio-buttons/radio-buttons";
import { SectionType } from "cv-app/utils/section-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { cleanHtml } from 'cv-app/shared/services/html-serialize/html-serialize';

export interface ConsultancyFormProps {
    doValidate: boolean;
    isOnGoingProject: boolean;
    isDelaware: boolean;
    formInput: ConsultancyEntity;
    dataChangeCallback: (data: ConsultancyEntity) => void;
    checkboxChangeCallback: (checked: boolean) => void;
    radioButtonChangedCallback: (isDelaware: boolean) => void;
    industries: SelectOption[];
}

const MIN_DATE = '1950-01-01';

export function ConsultancyForm(props: ConsultancyFormProps) {
    const { t } = useTranslation();
    const dlwRadioButtonKey = "dlw";    
    const [isMaxLengthCustomer, setIsMaxLengthCustomer] = useState(false);  
    const [isMaxLengthProject, setIsMaxLengthProject] = useState(false);  
    const [isMaxLengthRole, setIsMaxLengthRole] = useState(false);
    const [isMaxLengthTimesheet, setIsMaxLengthTimesheet] = useState(false);
    const handleInputChange = (newValue: string, propName: string) => {
      if(propName === "customerName") {
        if (newValue.length > 80) {
            setIsMaxLengthCustomer(true);
        }
        else {
            setIsMaxLengthCustomer(false);
        }
      }
      if(propName === "projectInformation") {
        if (newValue.length > 80) {
            setIsMaxLengthProject(true);
        }
        else {
            setIsMaxLengthProject(false);
        }
      }
      if(propName === "role") {
        if (newValue.length > 80) {
            setIsMaxLengthRole(true);
        }
        else {
            setIsMaxLengthRole(false);
        }
      }    
      if(propName === "timesheetCode") {
        if (newValue.length > 80) {
            setIsMaxLengthTimesheet(true);
        }
        else {
            setIsMaxLengthTimesheet(false);
        }
      }        
      const data: ConsultancyEntity = {
        ...props.formInput,
        [propName]: newValue
      }
      props.dataChangeCallback(data);
    }
    const handleEndDateChange = (newValue: string) => {
      const data : ConsultancyEntity = {
        ...props.formInput,
        endDate: newValue
      };
      props.dataChangeCallback(data);
    };
    const handleCheckboxChange = e => {
      props.checkboxChangeCallback(!props.isOnGoingProject);
      const checked = e.target.checked;
      if (checked) {
        handleEndDateChange(null);
      }
    };
    const handleRadioButtonChange = (newValue: string) => {
        const isDlw = newValue === dlwRadioButtonKey;
        props.radioButtonChangedCallback(isDlw);
    }
    return (
        <div className="c-form">
            <div className="c-form__input-grid">
                <RadioButtons
                    isRequired={true}
                    isInline={true}
                    doValidate={props.doValidate}
                    groupName="projectType"
                    selectedValue={props.isDelaware ? dlwRadioButtonKey : "other"}
                    onChangeCallback={handleRadioButtonChange}
                    radioButtons={[
                        {
                            optionName: t("consultancy-work-reference__lbl-delaware"),
                            optionValue: dlwRadioButtonKey
                        },
                        {
                            optionName: t("consultancy-work-reference__lbl-other"),
                            optionValue: "other"
                        }
                    ]}
                ></RadioButtons>
                {props.isDelaware &&
                    <FormInput
                        isRequired={false}
                        type="input"
                        doValidate={props.doValidate}
                        onChangeCallback={(newValue: string) => { handleInputChange(newValue, "timesheetCode") }}
                        inputValue={props.formInput.timesheetCode}
                        inputId="timesheet"
                        labelText={t("consultancy-work-reference__lbl-timesheet")}
                        maxLenght={80}
                        maxLengthErrorText={t('limit_reached') + " (" + props.formInput.timesheetCode.length + t("characters") + ")"}
                        isMaxLengthError={isMaxLengthTimesheet}
                    ></FormInput>
                }
            </div>
            <div className="c-form__input-grid">
                <FormInput
                    isRequired={true}
                    type="input"
                    doValidate={props.doValidate}
                    onChangeCallback={(newValue: string) => { handleInputChange(newValue, "customerName") }}
                    inputValue={props.formInput.customerName}
                    inputId="customerName"
                    labelText={t("consultancy-work-reference__lbl-customerName")}
                    errorText={t("consultancy-work-reference__lbl-customerNameError")}
                    minLength={2}
                    maxLenght={80}
                    maxLengthErrorText={t('limit_reached') + " (" + props.formInput.customerName.length + t("characters") + ")"}
                    isMaxLengthError={isMaxLengthCustomer}
                ></FormInput>
                <FormInput
                    isRequired={true}
                    type="input"
                    doValidate={props.doValidate}
                    onChangeCallback={(newValue: string) => { handleInputChange(newValue, "projectInformation") }}
                    inputValue={props.formInput.projectInformation}
                    inputId="projectInformation"
                    labelText={t("consultancy-work-reference__lbl-projectInfo")}
                    errorText={t("consultancy-work-reference__lbl-projectInfoError")}
                    placeholder={t("consultancy-work-reference__placeholder-projectInfo")}
                    minLength={2}
                    maxLenght={80}
                    maxLengthErrorText={t('limit_reached') + " (" + props.formInput.projectInformation.length + t("characters") + ")"}
                    isMaxLengthError={isMaxLengthProject}
                ></FormInput>
            </div>
            <div className="c-form__input-grid">
                <FormSelect
                    isRequired={true}
                    doValidate={props.doValidate}
                    inputValue={props.formInput.industryId}
                    inputId="industry"
                    inputName="industry"
                    labelText={t("consultancy-work-reference__lbl-industry")}
                    errorText={t("consultancy-work-reference__lbl-industryError")}
                    onChangeCallback={(newValue: string) => { handleInputChange(newValue, "industryId") }}
                    selectDataSource={props.industries}
                    defaultValue={`${t('general__select-choose')} ${t("consultancy-work-reference__lbl-industry")}`}
                ></FormSelect>
                <FormInput
                    isRequired={true}
                    type="input"
                    doValidate={props.doValidate}
                    onChangeCallback={(newValue: string) => { handleInputChange(newValue, "role") }}
                    inputValue={props.formInput.role}
                    inputId="role"
                    labelText={t("consultancy-work-reference__lbl-role")}
                    errorText={t("consultancy-work-reference__lbl-roleError")}
                    placeholder={t("consultancy-work-reference__placeholder-roleInfo")}
                    minLength={2}
                    maxLenght={80}
                    maxLengthErrorText={t('limit_reached') + " (" + props.formInput.role.length + t("characters") + ")"}
                    isMaxLengthError={isMaxLengthRole}
                ></FormInput>
            </div>
            <div className="c-form__input-grid">
                <div></div>
                <div>
                    <div className="c-form__input-grid">
                        <DateInterval
                            doValidate={props.doValidate}
                            componentKey={SectionType.Consultancy}
                            startDate={props.formInput.startDate}
                            endDate={props.formInput.endDate}
                            // maxEndDate={maxDateToday()}
                            requireEndDate={!props.isOnGoingProject}
                            showEndDate={!props.isOnGoingProject}
                            onStartDateChanged={(newValue: string) => { handleInputChange(newValue, "startDate") }}
                            onEndDateChanged={(newValue: string) => { handleInputChange(newValue, "endDate") }}
                            minDate={MIN_DATE}
                        ></DateInterval>
                    </div>
                    <CheckBox
                        inputId="ongoingProject"
                        isChecked={props.isOnGoingProject}
                        labelText={t("consultancy-work-reference__lbl-checkbox")}
                        changeCallback={handleCheckboxChange}
                    ></CheckBox>
                </div>
            </div>
            <RichTextEditor
              title={t("consultancy-work-reference__lbl-description")}
              value={cleanHtml(props.formInput.description)}
              placeholder={t("consultancy-work-reference__description-placeholder")}
              helpText={t("consultancy-work-reference__help-text")}
              errorText={t("consultancy-work-reference__description-error") + " (" + (200 - htmlDecode(props.formInput.description).length) + t("remaining") + ")"}
              doValidate={props.doValidate}
              onChange={(newValue: string) => { handleInputChange(newValue, 'description') }}
              subtitle={t("character_limit")}
              maxLength={700}
              maxLengthErrorText={t('limit_reached') + " (" + htmlDecode(props.formInput.description).length + t("characters") + ")"}   
            />
        </div>
    );
}

export default ConsultancyForm;
