import { connect } from "react-redux";
import { fetchMe, fetchPhoto } from "cv-app/shared/redux/me/me.slice";
import { fetchNationalities } from 'cv-app/shared/redux/data/nationalities/nationalities.slice';
import { fetchLegalEntities } from 'cv-app/shared/redux/data/entities/entities.slice';
import { fetchPersonal, postPersonalInput } from 'cv-app/shared/redux/personal/personal.slice';
import { fetchCvs, fetchCvSectionStates } from "cv-app/shared/redux/cvs/cvs.slice";
import StepPersonal from "../step-personal/step-personal";
import { fetchConsultancy, postConsultancyInput } from 'cv-app/shared/redux/consultancy/consultancy.slice';
import { fetchJobs, postJobsInput } from 'cv-app/shared/redux/jobs/jobs.slice';
import { fetchEducation, postEducationInput } from 'cv-app/shared/redux/education/education.slice';

const mapStateToProps = (state: any) => {
  return {
    loadingStatus: state.personal.loadingStatus,
    imageLoadingStatus: state.me.imageLoadingStatus,
    loadingStatusCvStates: state.cvs.loadingSectionsStatus,
    meLoaded: state.me.loadingStatus,
    userData: state.me.userData,
    errorMessage: state.personal.error,
    sectionData: state.personal.sectionData,
    cvSectionsStates: state.cvs.sections,
    nationalitiesLoadingStatus: state.nationalities.loadingStatus,
    nationalities: state.nationalities.nationalities,
    consultancySectionData: state.consultancy.sectionData,
    jobsSectionData: state.jobs.sectionData,
    educationSectionData: state.education.sectionData,
    entitiesLoadingStatus: state.legalentities.loadingStatus,
    legalEntities: state.legalentities.legalEntities,
    loadingStatusConsultancies: state.consultancy.loadingStatus,
    loadingStatusJobs: state.jobs.loadingStatus,
    loadingStatusEducation: state.education.loadingStatus,
  };
}

const mapDispatchToProps = {
    fetchPersonal,
    fetchMe,
    fetchPhoto,
    fetchNationalities,
    fetchLegalEntities,
    postPersonalInput,
    fetchCvs,
    fetchCvSectionStates,
    postConsultancyInput,
    postJobsInput,
    fetchConsultancy,
    fetchJobs,
    fetchEducation,
    postEducationInput
};

export default connect(mapStateToProps, mapDispatchToProps)(StepPersonal)
