// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cv-article-card_c-cv-article__title__3wgLb {\n  font-size: 24px;\n  font-weight: 600;\n}\n.cv-article-card_c-cv-article__subtitle__2-Eu8 {\n  font-size: 20px;\n  color: brandColor(\"dot-red\");\n  margin-bottom: 6px;\n}\n.cv-article-card_c-cv-article__info__3uoXa {\n  font-size: 14px;\n  color: brandColor(\"mid-grey\");\n  margin-bottom: 12px;\n}\n.cv-article-card_c-cv-article__actions__2MwIY {\n  flex-shrink: 0;\n}", ""]);
// Exports
exports.locals = {
	"c-cv-article__title": "cv-article-card_c-cv-article__title__3wgLb",
	"c-cv-article__subtitle": "cv-article-card_c-cv-article__subtitle__2-Eu8",
	"c-cv-article__info": "cv-article-card_c-cv-article__info__3uoXa",
	"c-cv-article__actions": "cv-article-card_c-cv-article__actions__2MwIY"
};
module.exports = exports;
