import { AuthInfo, fetchJson, fetchJsonPost } from "cv-app/utils/api-auth-helper";
import { SectionData } from "../sections";
import { CertificatesInput } from "./certificates-types";

export function getCertificatesSection(authInfo: AuthInfo): Promise<SectionData<CertificatesInput>> {
    return fetchJson<SectionData<CertificatesInput>>('/api/cvs/sections/certificates?draft=true', authInfo);
}

export function postCertificates(input: CertificatesInput, authInfo: AuthInfo): Promise<SectionData<CertificatesInput>> {
    return fetchJsonPost<SectionData<CertificatesInput>>('/api/cvs/sections', input, authInfo);
}