import React, { useEffect, useState } from 'react';
import './dashboard.module.scss';
import BoxedComponent from 'cv-app/shared/ui/layout/boxed-component/boxed-component';
import Section from 'cv-app/shared/ui/layout/section/section';
import Loader from 'cv-app/shared/ui/molecules/loader/loader';
import Passport from 'cv-app/pages/dashboard/components/passport/passport';
import { AuthInfo, useAuth } from 'cv-app/utils/api-auth-helper';
import { StatusEnums } from 'cv-app/utils/status-enums';
import ErrorModal from 'cv-app/shared/ui/molecules/modal/error-modal';
import { MeEntity, FetchPhotoParameters } from 'cv-app/shared/redux/me/me.slice';
import ErrorView from 'cv-app/shared/ui/molecules/error-view/error-view';
import { TrackedErrorBoundary } from 'cv-app/shared/ui/tracked-error-boundary';
import CvListerContainer from './containers/cv-lister-container';
import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import { getProfilePhoto, getUserLegalEntity, getUserNationality } from 'cv-app/utils/personal/personal-helper';
import { useTranslation } from "react-i18next";

/* eslint-disable-next-line */
export interface DashboardProps {
  loadingStatus: StatusEnums;
  imageLoadingStatus: StatusEnums;
  userData: MeEntity;
  errorMessage: string;
  nationalitiesLoadingStatus: StatusEnums;
  nationalities: SelectOption[];
  entitiesLoadingStatus: StatusEnums;
  legalEntities: SelectOption[];
  csvFirstName: string;
  csvLastName: string;
  csvJobTitle: string;
  loadingStatusPersonal: StatusEnums;
  legalEntityId: number;
  fetchMe: (authInfo: AuthInfo) => void;
  fetchPhoto: (params: FetchPhotoParameters) => void;
  fetchNationalities: (authInfo: AuthInfo) => void;
  fetchLegalEntities: (authInfo: AuthInfo) => void;
  fetchPersonal: (authInfo: AuthInfo) => void;
}

export function Dashboard(props: DashboardProps) {
  /**HOOKS */
  //#region
  const authInfo = useAuth();
  const { t } = useTranslation();
  const [showPostErrorModal, setShowPostErrorModal] = useState(false);

  useEffect(() => {
    if (props.loadingStatus === StatusEnums.Initial) {
      props.fetchMe(authInfo)
    }
  }, [])
  useEffect(() => {
    if (props.nationalitiesLoadingStatus === StatusEnums.Initial) {
      props.fetchNationalities(authInfo)
    }
  }, [])
  useEffect(() => {
    if (props.entitiesLoadingStatus === StatusEnums.Initial) {
      props.fetchLegalEntities(authInfo)
    }
  }, [])
  useEffect(() => {
    // When me data is loaded
    if (props.loadingStatus === StatusEnums.Loaded) {
      // no ods data returned
      if (props.userData.noOdsUserData) {
        // display a modal with error message 
        setShowPostErrorModal(true);
      }
      // look for the encoded photo only if there is a photo url (and we don't already have the photo)
      if (props.imageLoadingStatus === StatusEnums.Initial && props.userData.hasPhoto) {
          props.fetchPhoto({
            photoUrl: props.userData.photoUri,
            authInfo: authInfo
          })
        }
      }
  }, [props.loadingStatus])
  useEffect(() => {
    if (props.loadingStatusPersonal === StatusEnums.Initial) {
      props.fetchPersonal(authInfo);
    }
  }, [props.loadingStatusPersonal]);
  //#endregion

  /**RENDERING */
  //#region

  // On error
  if (props.loadingStatus === StatusEnums.Error) {
    return (<ErrorView errorMessage={props.errorMessage}></ErrorView>)
  }
  
  // Initial
  if (props.loadingStatus === StatusEnums.Initial ||
      props.loadingStatus === StatusEnums.Loading) {
    return (<Loader></Loader>)
  }

  const legalEntityName = props.legalEntities.find(({ optionValue }) => optionValue === String(props.legalEntityId))?.optionName;

  // Page view
  return (
    <TrackedErrorBoundary>
      <Section>
        <div className="c-dashboard__header">
          <Passport
            userName={`${props.userData?.firstName ?? props.csvFirstName} ${props.userData?.lastName ?? props.csvLastName}`}
            imageSrc={getProfilePhoto(props.userData, props.imageLoadingStatus)}
            jobTitle={`${props.userData?.jobTitle ?? props.csvJobTitle}`}
            nationality={legalEntityName ?? getUserNationality(props.userData, props.nationalities)}
            legalEntity={getUserLegalEntity(props.userData, props.legalEntities)}
          ></Passport>
        </div>
      </Section>
      <BoxedComponent componentTitle={t("dashboard__title")}>
        <CvListerContainer></CvListerContainer>
      </BoxedComponent>

      <ErrorModal
        modalId='postError'
        isVisible={showPostErrorModal}
        errorMessage={t('dashboard__ods_error_msg')}
        otherErrMessage={t('dashboard__ods_other_error_msg')}
        code='Status Code: 404'
        buttonsPosition='start'
        textAccept={t('general__btn-ok')}
        handleAccept={() => {
          setShowPostErrorModal(false);
        }}
      ></ErrorModal>
    </TrackedErrorBoundary>
  )
  //#endregion
}

export default Dashboard;
