import { fetchMe, fetchPhoto } from "cv-app/shared/redux/me/me.slice";
import { fetchNationalities } from 'cv-app/shared/redux/data/nationalities/nationalities.slice';
import { fetchLegalEntities } from 'cv-app/shared/redux/data/entities/entities.slice';
import { connect } from "react-redux";
import Dashboard from "cv-app/pages/dashboard/dashboard";
import { fetchPersonal } from 'cv-app/shared/redux/personal/personal.slice';

const mapStateToProps = (state: any) => {
  return {
    loadingStatus: state.me.loadingStatus,
    imageLoadingStatus: state.me.imageLoadingStatus,
    userData: state.me.userData,
    errorMessage: state.me.error,
    nationalitiesLoadingStatus: state.nationalities.loadingStatus,
    nationalities: state.nationalities.nationalities,
    entitiesLoadingStatus: state.legalentities.loadingStatus,
    legalEntities: state.legalentities.legalEntities,
    csvFirstName: state.cvs.variants[0]?.content.input.firstName ?? '',
    csvLastName: state.cvs.variants[0]?.content.input.lastName ?? '',
    csvJobTitle: state.cvs.variants[0]?.content.input.jobTitle ?? '',
    loadingStatusPersonal: state.personal.loadingStatus,
    legalEntityId: state.personal.sectionData.input.legalEntityId,
  }
};

const mapDispatchToProps = {
    fetchMe,
    fetchPhoto,
    fetchNationalities,
    fetchLegalEntities,
    fetchPersonal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
