import { CvSectionStateEntity } from 'cv-app/shared/redux/cvs/cvs.slice';
import { JobEntity } from 'cv-app/shared/redux/jobs/jobs.slice';
import { getLegalEntitieIndustries } from 'cv-app/shared/services/data-service';
import { PostListData } from 'cv-app/shared/services/sections';
import EditArticle from 'cv-app/shared/ui/modules/edit-article/edit-article';
import StepBase from 'cv-app/shared/ui/modules/step-base/step-base';
import { AuthInfo, useAuth } from 'cv-app/utils/api-auth-helper';
import { stringIsNullOrEmpty, removeSpace } from 'cv-app/utils/helpers/string-helper';
import { createEmptyJob, createNewJob, haveIdenticalData, validateJobEntity } from 'cv-app/utils/jobs-helper/job-helper';
import { SectionType } from 'cv-app/utils/section-types';
import { StatusEnums } from 'cv-app/utils/status-enums';
import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import OtherWorkForm from './components/other-work-form';
import './step-other-work-edit.module.scss';
import { htmlDecode } from 'cv-app/shared/services/html-serialize/html-serialize';

/* eslint-disable-next-line */
export interface StepOtherWorkEditProps {
  errorMessage: string;
  loadingStatus: StatusEnums;
  entities: JobEntity[];
  loadingStatusCvStates: StatusEnums;
  cvSectionsStates: CvSectionStateEntity[];
  loadingStatusPersonal: StatusEnums;
  legalEntityId: number;
  fetchPersonal: (authInfo: AuthInfo) => void;
  fetchCvSectionStates: (authInfo: AuthInfo) => void;
  fetchJobs: (authInfo: AuthInfo) => void;
  postJobsInput: (postData: PostListData<JobEntity>) => void;
}

export function StepOtherWorkEdit(props: StepOtherWorkEditProps) {
  /**STATES */
  //#region
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [doValidate, setDoValidate] = useState(false);
  const [canReturn, setCanReturn] = useState(false);
  const [isOngoing, setIsOngoing] = useState(false);
  const [industries, setIndustries] = useState([]);
  //#endregion

  /**HOOKS */
  //#region
  const { cvid, workid } = useParams<{ cvid: string, workid: string }>();

  const authInfo = useAuth();

  useEffect(() => {
    if (props.loadingStatus === StatusEnums.Initial) {
      props.fetchPersonal(authInfo);
    }
  }, []);

  useEffect(() => {
    async function fetchIndustries() {
      if (props.loadingStatus === StatusEnums.Initial || props.loadingStatusPersonal === StatusEnums.Loaded) {
        try {
          const response = await getLegalEntitieIndustries(authInfo, props.legalEntityId);
          setIndustries(response);
        } catch (error) {
          console.log(error);
        }
      }
    }
    fetchIndustries();
  }, [props.loadingStatusPersonal]);
  //#endregion

  //#region On save/cancel/modal
  const onSaveButtonClicked = () => {
    setIsSubmitting(true);
    setDoValidate(true);
  }
  const onCancelButtonClicked = () => {
    setIsSubmitting(false); // Probably not needed?
    setIsCancelling(true);
  }
  const cancelSubmission = () => {
    setIsSubmitting(false);
  }
  const stayOnPage = () => {
    setIsCancelling(false);
  }
  //#endregion

  const isValidEntity = (w: JobEntity) => {
    return validateJobEntity(w, !isOngoing, industries);
  }
  const preSubmissionHandleData = (w: JobEntity) => {
    const newJob: JobEntity = {
      ...w,
      companyName: removeSpace(w.companyName),
      role: removeSpace(w.role),
      description: removeSpace(w.description),  
      startDate: w.startDate,
      endDate: isOngoing ? null : w.endDate
    }
    return newJob;
  }

  const onCheckboxClickedCallback = (isChecked: boolean) => {
    setIsOngoing(isChecked);
  }
  const setInitialFormState = (w: JobEntity, isNew: boolean) => {
    if(isNew)
     return;
    setIsOngoing(stringIsNullOrEmpty(w.endDate));
  }

  function isNew() {
    if (workid)
      return false;
    return true;
  }

  /**RENDERING helpers */
  const renderForm = (formInput: JobEntity, dataChangeCallback: (data: JobEntity) => void) => {
    return (
      <OtherWorkForm
        formInput={formInput}
        checkboxChangeCallback={onCheckboxClickedCallback}
        dataChangeCallback={dataChangeCallback}
        doValidate={doValidate}
        isOnGoing={isOngoing}
        industries={industries}
      ></OtherWorkForm>
    )
  }

  /**RENDERING */
  //#region

  // On cancel or data saved successfully
  if (canReturn) {
    return (<Redirect to={`/cv/${cvid}/work-history/other`}></Redirect>)
  }

  // Page view
  return (
    <StepBase
      isInitialState={false}
      isError={props.loadingStatus === StatusEnums.Error}
      errorMessage={props.errorMessage}
      stepKey={SectionType.OtherWork}
      nextBtnText={isNew() ? "general__btn-add" : "general__btn-update"}
      prevBtnText={"general__btn-cancel"}
      onNextClicked={onSaveButtonClicked}
      onPrevClicked={onCancelButtonClicked}
      loadingStatusCvStates={props.loadingStatusCvStates}
      cvSectionsStates={props.cvSectionsStates}
      fetchCvSectionStates={props.fetchCvSectionStates}
    >
      <EditArticle
        entities={props.entities}
        loadingStatus={props.loadingStatus}
        errorMessage={props.errorMessage}
        entityToEditId={workid}
        fetchEntities={props.fetchJobs}
        createNewEntity={createNewJob}
        createEmptyEntity={createEmptyJob}
        submitForm={isSubmitting}
        isValid={isValidEntity}
        cancelSubmission={cancelSubmission}
        continueEditing={stayOnPage}
        closeForm={() => {setCanReturn(true)}}
        preSubmissionHandleData={preSubmissionHandleData}
        setInitialSetForInput={setInitialFormState}
        renderForm={renderForm}
        postEntityList={props.postJobsInput}
        discardForm={isCancelling}
        haveIdenticalData={haveIdenticalData}
      ></EditArticle>
    </StepBase>
  );
  //#endregion

}

export default StepOtherWorkEdit;
