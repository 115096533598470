import { ConsultancyEntity } from 'cv-app/shared/redux/consultancy/consultancy.slice';
import Loader from 'cv-app/shared/ui/molecules/loader/loader';
import { AuthInfo, useAuth } from 'cv-app/utils/api-auth-helper';
import { compareConsultancies } from 'cv-app/utils/consultancy/consultancy-helper';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ConsultancyLister from './components/consultancy-lister/consultancy-lister';

import './step-consultancy-view.module.scss';
import { ConsultancyInput } from 'cv-app/shared/services/consultancy/consultancy-types';
import { PostListData, SectionData } from 'cv-app/shared/services/sections';
import { StatusEnums } from 'cv-app/utils/status-enums';
import { SectionType } from 'cv-app/utils/section-types';
import { CvSectionStateEntity } from 'cv-app/shared/redux/cvs/cvs.slice';
import StepLister from 'cv-app/shared/ui/modules/step-lister/step-lister';

/* eslint-disable-next-line */
export interface StepConsultancyViewProps {
  errorMessage: string;
  loadingStatus: StatusEnums;
  consultancies: ConsultancyEntity[];
  sectionData: SectionData<ConsultancyInput>;
  loadingStatusCvStates: StatusEnums;
  cvSectionsStates: CvSectionStateEntity[];
  loadingStatusPersonal: StatusEnums;
  fetchPersonal: (authInfo: AuthInfo) => void;
  fetchCvSectionStates: (authInfo: AuthInfo) => void;
  fetchConsultancy: (authInfo: AuthInfo) => void;
  postConsultancyInput: (postData: PostListData<ConsultancyEntity>) => void;
}

export function StepConsultancyView(props: StepConsultancyViewProps) {

  /**STATES */
    //#region
  const [entityToDelete, setEntityToDelete] = useState(-1);
  //#endregion

  /**HOOKS */
    //#region
  const { cvid } = useParams<{ cvid: string }>();
  //#endregion

  /**CALLBACKS */
    //#region
  const onDeleteConsultancyCallback = (id: number) => {
      setEntityToDelete(id);
  };
  //#endregion

  const authInfo = useAuth();

  useEffect(() => {
    if (props.loadingStatus === StatusEnums.Initial) {
      props.fetchPersonal(authInfo);
    }
  }, []);

  /**RENDERING */
  //#region
  return (
    <StepLister
      entities={props.consultancies}
      cvSectionsStates={props.cvSectionsStates}
      loadingStatus={props.loadingStatus}
      loadingStatusCvStates={props.loadingStatusCvStates}
      errorMessage={props.errorMessage}
      stepKey={SectionType.Consultancy}
      entityToDelete={entityToDelete}
      setParentEntityToDelete={setEntityToDelete}
      fetchEntities={props.fetchConsultancy}
      fetchCvSectionStates={props.fetchCvSectionStates}
      postEntityList={props.postConsultancyInput}
    >
      {props.loadingStatus === StatusEnums.Loading && <Loader></Loader>}
      <ConsultancyLister
        articleDeleteCallback={onDeleteConsultancyCallback}
        cvId={cvid}
        entities={props.consultancies.sort(compareConsultancies)}
      ></ConsultancyLister>
    </StepLister>
  );
  //#endregion
}

export default StepConsultancyView;
