import React from 'react';

import './simple-svg.module.scss';

/* eslint-disable-next-line */
export interface SimpleSvgProps {
  svgId: string;
  svgHeight: number;
  svgWidth: number;
  cssClass?: string;
}

export function SimpleSvg(props: SimpleSvgProps) {
  return (
      <svg className={`c-svg ${props.cssClass}`} width={props.svgWidth} height={props.svgHeight}>
        <use xlinkHref={`../../../assets/svg-symbols.svg#${props.svgId}`}></use>
      </svg>
  );
}

export default SimpleSvg;
