import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { unescapeHtml } from 'cv-app/shared/services/html-serialize/html-serialize';
import { getPeronsalInformationSection, postPeronsalInformation } from 'cv-app/shared/services/personal-information/personal-information-service';
import { PersonalInformationInput } from 'cv-app/shared/services/personal-information/personal-types';
import { PostInputData, SectionData } from 'cv-app/shared/services/sections';
import { AuthInfo } from 'cv-app/utils/api-auth-helper';
import { SectionType } from 'cv-app/utils/section-types';
import { StatusEnums } from 'cv-app/utils/status-enums';

export const PERSONAL_FEATURE_KEY = 'personal';

export interface PersonalEntity {
  id: number;
}

export interface PersonalState extends EntityState<PersonalEntity> {
  loadingStatus: StatusEnums;
  error: string;
  sectionData: SectionData<PersonalInformationInput>;
}

export const personalAdapter = createEntityAdapter<PersonalEntity>();


export const fetchPersonal = createAsyncThunk(
  'personal/fetchStatus',
  async (authInfo: AuthInfo, thunkAPI) => {
    const response = await getPeronsalInformationSection(authInfo);
    return Promise.resolve(response);
  }
);

export const postPersonalInput = createAsyncThunk(
  'personal/postStatus',
  async (postData: PostInputData<PersonalInformationInput>, thunkAPI) => {
    postData.sectionInput.description = unescapeHtml(postData.sectionInput.description);
    const response = await postPeronsalInformation(postData.sectionInput, postData.authInfo);
    return Promise.resolve(response);
  }
);

export const initialPersonalState: PersonalState = personalAdapter.getInitialState(
  {
    loadingStatus: StatusEnums.Initial,
    error: null,
    sectionData: {
      id: '',
      cvId: '',
      locale: '',
      canPublish: false,
      changedBy: '',
      changedOn: '',
      type: SectionType.Personal,
      input: {
        type: SectionType.Personal,
        firstName: '',
        lastName: '',
        jobTitle: '',
        description: '',
        imgSrc: '',
        nationality: '',
        teamId: -1,
        legalEntityId: -1,
        nationalityId: -1,
      }
    }
  }
);

export const personalSlice = createSlice({
  name: PERSONAL_FEATURE_KEY,
  initialState: initialPersonalState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPersonal.pending, (state: PersonalState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(postPersonalInput.pending, (state:  PersonalState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(
        fetchPersonal.fulfilled,
        (state: PersonalState, action: PayloadAction<SectionData<PersonalInformationInput>>) => {
          state.loadingStatus = StatusEnums.Loaded;
          state.sectionData = action.payload;
        }
      )
      .addCase(
        postPersonalInput.fulfilled,
        (state: PersonalState, action: PayloadAction<SectionData<PersonalInformationInput>>) => {
          state.loadingStatus = StatusEnums.Loaded;
          state.sectionData = action.payload;
        }
      )
      .addCase(fetchPersonal.rejected, (state: PersonalState, action) => {
        if (action.error.message === '404') {
          state.loadingStatus = StatusEnums.NotFound;
        } else {
          state.loadingStatus = StatusEnums.Error;
        }
        state.error = action.error.message;
      })
      .addCase(postPersonalInput.rejected, (state: PersonalState, action) => {
        state.loadingStatus = StatusEnums.SubmitError;
        state.error = action.error.message;
      });
  },
});

/*
 * Export reducer for store configuration.
 */
export const personalReducer = personalSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(personalActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const personalActions = personalSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllPersonal);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = personalAdapter.getSelectors();

export const getPersonalState = (rootState: unknown): PersonalState =>
  rootState[PERSONAL_FEATURE_KEY];

export const selectAllPersonal = createSelector(getPersonalState, selectAll);

export const selectPersonalEntities = createSelector(
  getPersonalState,
  selectEntities
);
