// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".education-form_c-education-form__checkbox__2IIM4 {\n  grid-row-start: 2;\n  grid-column-start: 2;\n}", ""]);
// Exports
exports.locals = {
	"c-education-form__checkbox": "education-form_c-education-form__checkbox__2IIM4"
};
module.exports = exports;
