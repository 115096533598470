import { NotRecuiredSectionsToLegalEntityIds } from 'cv-app/shared/constants/not-required-sections';
import { CvSectionStateEntity } from 'cv-app/shared/redux/cvs/cvs.slice';
import Timeline, { TimelineRow } from 'cv-app/shared/ui/components/timeline/timeline';
import { AuthInfo, useAuth } from 'cv-app/utils/api-auth-helper';
import { getSvgForNav, isCompleted, mapNavigationFromSectionData, mapSectionToUrl } from 'cv-app/utils/helpers/cv-section-helper';
import { SectionType } from 'cv-app/utils/section-types';
import { StatusEnums } from 'cv-app/utils/status-enums';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export interface StepNavProps {
    loadingStatusCvStates: StatusEnums;
    cvSectionsStates: CvSectionStateEntity[];
    currentStep: SectionType;
    loadingStatusPersonal: StatusEnums;
    legalEntityId: number;
    fetchCvSectionStates: (authInfo: AuthInfo) => void;
    fetchPersonal: (authInfo: AuthInfo) => void;
}

export function StepNav(props: StepNavProps) {
    /**HOOKS */
    const authInfo = useAuth();
    const { cvid } = useParams<{ cvid: string }>();
    const { t } = useTranslation();

    useEffect(() => {
        if (props.loadingStatusCvStates === StatusEnums.Initial) {
            props.fetchCvSectionStates(authInfo);
        }
    }, []);
    useEffect(() => {
      if (props.loadingStatusPersonal === StatusEnums.Initial) {
        props.fetchPersonal(authInfo);
      }
    }, [props.loadingStatusPersonal]);

    /**RENDERING */
    //#region
    const rows: TimelineRow[] = [];
    const sectionsMap = mapNavigationFromSectionData(props.cvSectionsStates, cvid, 'en-US');
    let isPastStep = true;

    sectionsMap.forEach((v, k) => {
        const isSectionNotRequired = !!NotRecuiredSectionsToLegalEntityIds[k]?.find((legalEntityId) => legalEntityId === props.legalEntityId || legalEntityId === -1);
        if (k === props.currentStep) {
            isPastStep = false;
        }
        const row: TimelineRow = {
            aside: null,
            rowUrl: (isPastStep || (v && v.canPublish)) ? mapSectionToUrl(k, cvid) : null,
            svg: getSvgForNav(k, v, k === props.currentStep),
            dotVariant: k === props.currentStep ? 'accent-primary' : (isCompleted(v) ? 'accent-secondary' : 'none'),
            flag:
                <div className='o-step__nav-flag'>
                    <div className='o-step__nav-text'>
                        {t(`${k}__nav`)}
                    </div>
                </div>,
            isRequired: !isSectionNotRequired
        }
        rows.push(row);
    })
    return (
        <Timeline timelineRows={rows} dotSmall={true}></Timeline>
    )
    //#endregion
}

export default StepNav;
