import React from 'react';
import { Link } from 'react-router-dom';
import SimpleSvg from 'cv-app/shared/ui/molecules/simple-svg/simple-svg';

import './add-link.module.scss';
import { SectionType } from 'cv-app/utils/section-types';
import { useTranslation } from 'react-i18next';
import { getAddNewLinkForSection } from 'cv-app/utils/helpers/cv-section-helper';

/* eslint-disable-next-line */
export interface AddLinkProps {
  isFirstEntity: boolean;
  cvId: string;
  sectionKey: SectionType;
}

export function AddLink(props: AddLinkProps) {
  const { t } = useTranslation();
  const wrapperClass = props.isFirstEntity ? "o-flex o-flex--justify-content-center" : "o-flex o-flex--justify-content-end";
  const btnCss = props.isFirstEntity ? "c-btn c-btn--primary" : "c-btn";
  const linkTo = getAddNewLinkForSection(props.sectionKey, props.cvId);
  const linkType = props.isFirstEntity ? "add-first-btn-text" : "add-another-btn-text";
  
  return (
    <div className={wrapperClass}>
      <Link className={btnCss} to={linkTo}>
        {!props.isFirstEntity &&
          <SimpleSvg
            svgId="icon-plus"
            svgWidth={10}
            svgHeight={10}
            cssClass="u-padding-right-tiny"
          ></SimpleSvg>
        }
        {t(`${props.sectionKey}__${linkType}`)}
      </Link>
    </div>
  );
}

export default AddLink;
