import React, { useState } from 'react';
import { LanguageCard } from 'cv-app/pages/step-languages-skills/components/language-card/language-card';

import './languages-lister.module.scss';
import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import { validateData, haveIdenticalData, filteredOptions } from 'cv-app/utils/language-proficiency/language-proficiency-helper';
import { LanguageProficiencyEntity, LanguageProficiency } from 'cv-app/shared/redux/language-proficiency/language-proficiency.slice';
import { getOptionName } from 'cv-app/utils/helpers/form-helper';
import AddButton from 'cv-app/shared/ui/components/add-btn/add-btn';
import { SectionType } from 'cv-app/utils/section-types';

/* eslint-disable-next-line */
export interface LanguagesListerProps {
  languageProficiencyCards?: LanguageProficiencyEntity[];
  languageProficiencyLevels: LanguageProficiency[];
  deleteCallback: (langId: number) => void;
  addLanguageCallback?: (language: LanguageProficiencyEntity) => void;
  editLanguageCallback?: (language: LanguageProficiencyEntity) => void;
  cancelLanguageCallback?:() => void;
  isEmpty: boolean;
  languageOptions?: SelectOption[];
  newLanguageForm: LanguageProficiencyEntity;
  inputChangeCallback: (data: LanguageProficiencyEntity) => void;
}

export function LanguagesLister(props: LanguagesListerProps) {
  const [validationOn, setValidationOn] = useState(false);
  const [showNewLanguageForm, setshowNewLanguageForm] = useState(false);
  const emptyLanguageForm = () => {
    setshowNewLanguageForm(true)
  }
  const hideLanguageForm = () => {
    setshowNewLanguageForm(false);
    props.cancelLanguageCallback();
  }

  const tryAddLanguageCallback = (newLanguage: LanguageProficiencyEntity) => {
    if (validateData(newLanguage) && !haveIdenticalData(newLanguage, props.languageProficiencyCards)) {
      props.addLanguageCallback(newLanguage);
      setValidationOn(false);
      hideLanguageForm();
    } else {
      setValidationOn(true);
    }
  }

  const editLanguageCallback = (newLanguage: LanguageProficiencyEntity) => {
    if (validateData(newLanguage)) {
      props.editLanguageCallback(newLanguage);
      setValidationOn(false);
      hideLanguageForm();
    } else {
      setValidationOn(true);
    }
  }

  return (
    <div className="c-card-lister">
      {!props.isEmpty && <div className="c-card-lister__item-wrapper">
        <React.Fragment>
          {props.languageProficiencyCards?.map((card, i) => {
            return (
              <LanguageCard
                editLanguageCallback={editLanguageCallback}
                languageProficiencyLevels={props.languageProficiencyLevels}
                languageName={getOptionName(card.languageId, props.languageOptions)}
                deleteCallback={props.deleteCallback}
                languageProficiency={card}
                languageOptions={props.languageOptions}
                key={`language-proficiency-card-${i}`}
              ></LanguageCard>
            );
          })}
        </React.Fragment>
      </div>}
      {showNewLanguageForm && <div className="c-card-lister__item-wrapper">
        <LanguageCard
          languageProficiencyLevels={props.languageProficiencyLevels}
          inputChangeCallback={props.inputChangeCallback}
          addLanguageCallback={tryAddLanguageCallback}
          languageProficiencyInput={props.newLanguageForm}
          doValidate={validationOn}
          languageName="New Language"
          editingMode={true}
          languageOptions={filteredOptions(props.languageProficiencyCards, props.languageOptions)}
          cancelAddLanguage={hideLanguageForm}
        ></LanguageCard>
      </div>}
      {!showNewLanguageForm &&
      <AddButton
        isFirstEntity={props.isEmpty}
        sectionKey={SectionType.Language}
        onClickCallback={emptyLanguageForm}
      ></AddButton>
      }
    </div>
  );
}

export default LanguagesLister;
