import React from 'react';

import './box.module.scss';

/* eslint-disable-next-line */
export interface BoxProps {
  asSection?: boolean;
  isLight?: boolean;
  isBordered?: boolean;
  cssClass?: string;
  boxTitle?: string;
  boxDescription?: string;
}

export function Box(props: React.PropsWithChildren<BoxProps>) {
  const boxClass = `
        ${props.cssClass ? props.cssClass : ''}
        c-box
        ${props.asSection ? 'c-box--section' : ''}
        ${props.isBordered ? 'c-box--bordered' : ''}
        ${props.isLight ? 'u-bg-color-white' : ''}
        `;
  return (
    <div className={boxClass}>
      {(props.boxTitle || props.boxDescription) && (
        <div className="c-box__intro">
          {props.boxTitle && (
            <div  className="c-box__title">{props.boxTitle}</div>
          )}
          {props.boxDescription && (
            <div className="c-box__description" dangerouslySetInnerHTML={{ __html: props.boxDescription }}></div>
          )}
        </div>
      )}

      <div className="c-box__content">{props.children}</div>
    </div>
  );
}

export default Box;
