import React, { useEffect, useState } from 'react';
import FormValidation from 'cv-app/shared/ui/molecules/form-validation/form-validation';

import { isValidOption } from 'cv-app/utils/helpers/form-helper';

/* eslint-disable-next-line */
export interface FormSelectProps {
  inputId: string;
  inputName: string;
  inputValue?: string;
  isRequired: boolean;
  isDisabled?: boolean;
  doValidate: boolean;
  labelText: string;
  errorText?: string;
  onChangeCallback?: (e) => void;
  fieldClassVariant?: string;
  selectDataSource: SelectOption[];
  defaultValue?: string;
  defaultValueOption?: string;
}

export interface SelectOption {
  optionValue: string;
  optionName: string;
}

export function FormSelect(props: FormSelectProps) {
  const [selected, setSelected] = useState(props.defaultValue ? 'default' : '');
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (props.doValidate)
      setIsValid(isValidOption(props.inputValue, props.selectDataSource));
  }, [props.doValidate, props.inputValue]);

  useEffect(()=>{
    if(props.inputValue !== '' && props.inputValue !== '-1'){
      setSelected(props.inputValue.toString())
    }
  },[props.inputValue])

  const cssClass = `c-field ${isValid ? '' : 'c-field--error'}`;

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // Do not accept unacceptable values
    if (!isValidOption(e.target.value, props.selectDataSource) && !props.defaultValueOption) {
      setIsValid(false);
      return;
    }

    const validValue = !props.doValidate || validateRequired(e.target.value);
    setIsValid(validValue);
    setSelected(e.target.value);

    if (e.target.value === 'default') {
      props.onChangeCallback(-1);
    } else {
      props.onChangeCallback(e.target.value);
    }
  };

  const validateRequired = (inputValue: string) => {
    // required and empty
    if (props.isRequired && inputValue === '')
      return false;
  };

  return (
    <div className={cssClass}>
      <label htmlFor={props.inputId} className='c-field__label'>
        {props.isRequired &&
          <span className='c-field__required-mark'>*</span>}
        {props.labelText}
      </label>
      <select value={selected} disabled={props.isDisabled} className={`c-input ${selected === 'default' && 'c-input-default'}`}
              onChange={handleChange} name={props.inputName} id={props.inputId} defaultValue={'default'}>
        {props.defaultValue && <option value={'default'} disabled hidden>{props.defaultValue}</option>}
        {props.defaultValueOption && <option value={'default'} key={`${props.inputId}-option-default`}>{props.defaultValueOption}</option>}
        {props.selectDataSource.map((op: SelectOption, index: number) => {
          return (
            <option value={op.optionValue} key={`${props.inputId}-option-${index}`}>{op.optionName}</option>
          );
        })}
      </select>
      <FormValidation errorText={props.errorText} showError={!isValid}></FormValidation>
    </div>
  );
}

export default FormSelect;
