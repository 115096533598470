import { stringIsNullOrEmpty } from 'cv-app/utils/helpers/string-helper';
import React, { useEffect, useState } from 'react';
import SimpleSvg, { SimpleSvgProps } from '../simple-svg/simple-svg';

import './media.module.scss';

/* eslint-disable-next-line */
export interface MediaProps {
  imgSrc: string;
  imgAlt: string;
  extraCssClass?: string;
  rounded?: boolean;
  hasBorder?: boolean;
  borderPlacing?: string;
  fallbackImgSrc?: string;
  forceFit: boolean;
  placeholderSvgProps?: SimpleSvgProps;
}
/**
 * Flow: 
 * if props.imgSrc is loading async we use placeholder until available
 * then use props.imgSrc as source if not empty
 * onError - try to use fallback if not empty
 * when all fails, use placeholder
 * 
 */

export function Media(props: MediaProps) {
  const [src, setSrc] = useState(stringIsNullOrEmpty(props.imgSrc) ? props.fallbackImgSrc : props.imgSrc);
  const [srcIsFallback, setSrcIsFallback] = useState(stringIsNullOrEmpty(props.imgSrc));
  const [usePlaceholder, setUsePlaceholder] = useState(true);
  const borderClass = `c-media--border-${props.borderPlacing}`;
  const mediaClass = `${props.extraCssClass ? props.extraCssClass : ""} 
                        c-media ${props.rounded ? "c-media--round" : ""} 
                        ${props.forceFit ? "c-media--fit" : ""}
                        ${props.hasBorder ? borderClass : ""}`;
  const imgClass = `c-media__img ${props.forceFit ? "c-media--fit o-fit" : ""}`;

  const doFallbackLogic = () => {
    // fallback to the fallbackImg, then to the placeholder
    const hasFallback = !stringIsNullOrEmpty(props.fallbackImgSrc);
    if (hasFallback)
      setSrc(props.fallbackImgSrc);
    setUsePlaceholder(!hasFallback);
    setSrcIsFallback(hasFallback);
  }
  const onImageLoadingError = () => {
    // If error on the original image, try use fallback
    if (!srcIsFallback) {
      doFallbackLogic();
    }
    // if there isn't a fallback or we already tried it and it errored
    else {
      setUsePlaceholder(true);
    }
  }

  // Start by using the placeholder, and once the imgSrc is loaded set states
  useEffect(() => {
    if (!stringIsNullOrEmpty(props.imgSrc)) {
      // we have an image src, use it, life is easy
      setSrc(props.imgSrc);
      setSrcIsFallback(false);
      setUsePlaceholder(false);
    }
    else {
      doFallbackLogic();
    }
  }, [props.imgSrc])

  if (usePlaceholder) {
    return (
      <div className={mediaClass}>
        <div className={imgClass}>
          <div className="c-media-placeholder">
            {props.placeholderSvgProps &&
              <SimpleSvg
                {...props.placeholderSvgProps}
              ></SimpleSvg>
            }
          </div>
        </div>
      </div>
    )
  }
  return (
    <figure className={mediaClass}>
      <img className={imgClass} src={src} alt={props.imgAlt} onError={() => onImageLoadingError} />
    </figure>
  );
}

export default Media;
