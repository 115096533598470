import { JobEntity } from "cv-app/shared/redux/jobs/jobs.slice";
import CheckBox from "cv-app/shared/ui/molecules/check-box/check-box";
import FormInput from "cv-app/shared/ui/molecules/form-input/form-input";
import { useTranslation } from 'react-i18next';
import React, { useState } from "react";
import DateInterval from "cv-app/shared/ui/components/date-interval/date-interval";
import { SectionType } from "cv-app/utils/section-types";
import FormSelect, { SelectOption } from "cv-app/shared/ui/molecules/form-select/form-select";
import { RichTextEditor } from "cv-app/shared/ui/components/rich-text-editor/rich-text-editor";
import { htmlDecode } from "cv-app/shared/services/html-serialize/html-serialize";
import { cleanHtml } from 'cv-app/shared/services/html-serialize/html-serialize';

export interface OtherWorkFormProps {
    doValidate: boolean;
    isOnGoing: boolean;
    formInput: JobEntity;
    dataChangeCallback: (data: JobEntity) => void;
    checkboxChangeCallback: (checked: boolean) => void;
    industries: SelectOption[];
}

const MIN_DATE = '1950-01-01';

export function OtherWorkForm(props: OtherWorkFormProps) {    
    const [isMaxLengthCompany, setIsMaxLengthCompany] = useState(false);  
    const [isMaxLengthRole, setIsMaxLengthRole] = useState(false);
    const { t } = useTranslation();

    const handleEndDateChange = (newValue: string) => {
      const data : JobEntity = {
        ...props.formInput,
        endDate: newValue
      };
      props.dataChangeCallback(data);
    };

    const handleInputChange = (newValue: string, propName: string) => {
        const data: JobEntity = {
            ...props.formInput,
            [propName]: newValue
        }
        if (propName === "companyName") {
            if (newValue.length > 80) {
                setIsMaxLengthCompany(true);
;            }
            else {
                setIsMaxLengthCompany(false);
            }
        }        
        if (propName === "role") {
            if (newValue.length > 80) {
                setIsMaxLengthRole(true);
;            }
            else {
                setIsMaxLengthRole(false);
            }
        }
        props.dataChangeCallback(data);
    }

    const handleCheckboxChange = e => {
      props.checkboxChangeCallback(!props.isOnGoing);
      const checked = e.target.checked;
      if (checked) {
        handleEndDateChange(null);
      }
    };

    return (
        <div className="c-form">
            <div className="c-form__input-grid">
                <FormInput
                    isRequired={true}
                    type="input"
                    doValidate={props.doValidate}
                    onChangeCallback={(newValue: string) => { handleInputChange(newValue, "companyName") }}
                    inputValue={props.formInput.companyName}
                    inputId="companyName"
                    labelText={t("other-work-reference__lbl-companyName")}
                    errorText={t("other-work-reference__lbl-companyNameError")}
                    minLength={2}
                    maxLenght={80}                    
                    maxLengthErrorText={t('limit_reached') + " (" + props.formInput.companyName.length + t("characters") + ")"}
                    isMaxLengthError={isMaxLengthCompany}
                ></FormInput>
                <FormSelect
                    isRequired={true}
                    doValidate={props.doValidate}
                    inputValue={props.formInput.industryId}
                    inputId="industry"
                    inputName="industry"
                    labelText={t("other-work-reference__lbl-industry")}
                    errorText={t("other-work-reference__lbl-industryError")}
                    onChangeCallback={(newValue: string) => { handleInputChange(newValue, "industryId") }}
                    selectDataSource={props.industries}
                    defaultValue={`${t('general__select-choose')} ${t("other-work-reference__lbl-industry")}`}
                    ></FormSelect>
            </div>
            <div className="c-form__input-grid">
                <FormInput
                    isRequired={true}
                    type="input"
                    doValidate={props.doValidate}
                    onChangeCallback={(newValue: string) => { handleInputChange(newValue, "role") }}
                    inputValue={props.formInput.role}
                    inputId="role"
                    labelText={t("other-work-reference__lbl-role")}
                    errorText={t("other-work-reference__lbl-roleError")}
                    minLength={2}
                    maxLenght={80}                    
                    maxLengthErrorText={t('limit_reached') + " (" + props.formInput.role.length + t("characters") + ")"}
                    isMaxLengthError={isMaxLengthRole}
                ></FormInput>
                <div>
                    <div className="c-form__input-grid">
                        <DateInterval
                            doValidate={props.doValidate}
                            componentKey={SectionType.OtherWork}
                            startDate={props.formInput.startDate}
                            endDate={props.formInput.endDate}
                            // maxEndDate={maxDateToday()}
                            requireEndDate={!props.isOnGoing}
                            showEndDate={!props.isOnGoing}
                            onStartDateChanged={(newValue: string) => { handleInputChange(newValue, "startDate") }}
                            onEndDateChanged={(newValue: string) => { handleInputChange(newValue, "endDate") }}
                            minDate={MIN_DATE}
                        ></DateInterval>
                    </div>
                    <CheckBox
                        inputId="ongoingProject"
                        isChecked={props.isOnGoing}
                        labelText={t("other-work-reference__lbl-ongoingProject")}
                        changeCallback={handleCheckboxChange}
                    ></CheckBox>
                </div>
            </div>
            <RichTextEditor
              title={t("other-work-reference__lbl-description")}
              value={cleanHtml(props.formInput.description)}
              placeholder={t("consultancy-work-reference__description-placeholder")}
              helpText={t("consultancy-work-reference__help-text")}
              errorText={t("other-work-reference__description-error") + " (" + (200 - htmlDecode(props.formInput.description).length) + t("remaining") + ")"}
              doValidate={props.doValidate}
              onChange={(newValue: string) => { handleInputChange(newValue, 'description') }}
              subtitle={t("character_limit")}
              maxLength={700}
              maxLengthErrorText={t('limit_reached') + " (" + htmlDecode(props.formInput.description).length + t("characters") + ")"}
            />
        </div>
    );
}

export default OtherWorkForm;
