import React, { useEffect, useState } from 'react';
import CvCard from 'cv-app/pages/dashboard/components/cv-card/cv-card';
import CvPlaceholder from 'cv-app/pages/dashboard/components/cv-placeholder/cv-placeholder';

import './cv-lister.module.scss';
import { AuthInfo, useAuth } from 'cv-app/utils/api-auth-helper';
import Loader from 'cv-app/shared/ui/molecules/loader/loader';
import { StatusEnums } from 'cv-app/utils/status-enums';
import {
  CvEntity,
  CvSectionStateEntity,
  FetchParameters,
} from 'cv-app/shared/redux/cvs/cvs.slice';
import ErrorView from 'cv-app/shared/ui/molecules/error-view/error-view';
import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import { getOptionName } from 'cv-app/utils/helpers/form-helper';
import { TrackedErrorBoundary } from 'cv-app/shared/ui/tracked-error-boundary';
import Modal from 'cv-app/shared/ui/molecules/modal/modal';
import {
  getContinueEditingUrl,
  mapNavigationFromSectionData,
} from 'cv-app/utils/helpers/cv-section-helper';
import { useTranslation } from 'react-i18next';
import { NotRecuiredSectionsToLegalEntityIds } from 'cv-app/shared/constants/not-required-sections';
import { SectionType } from 'cv-app/utils/section-types';
import { PersonalInformationInput } from 'cv-app/shared/services/personal-information/personal-types';

/* eslint-disable-next-line */
export interface CvListerProps {
  loadingStatus: StatusEnums;
  loadingStatusCvStates: StatusEnums;
  loadingDownloadStatus: StatusEnums;
  errorMessage: string;
  cvs: CvEntity[];
  cvSectionsStates: CvSectionStateEntity[];
  teamsLoadingStatus: StatusEnums;
  teamsData: SelectOption[];
  loadingStatusPersonal: StatusEnums;
  legalEntityId: number;
  personalSectionDataInput: PersonalInformationInput;
  fetchPersonal: (authInfo: AuthInfo) => void;
  fetchCvs: (authInfo: AuthInfo) => void;
  fetchCvSectionStates: (authInfo: AuthInfo) => void;
  fetchTeams: (authInfo: AuthInfo) => void;
  fetchDownloadCV: (params: FetchParameters) => void;
}

export function CvLister(props: CvListerProps) {
  const validCvs = props.cvs.filter((x) => x.hasOwnProperty('content'));
  const [showErrorModal, setShowErrorModal] = useState(false);

  /**HOOKS */
  const authInfo = useAuth();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      props.loadingStatus === StatusEnums.Initial ||
      props.loadingStatus === StatusEnums.Loaded
    ) {
      props.fetchCvs(authInfo);
    }
  }, []);
  useEffect(() => {
    if (props.teamsLoadingStatus === StatusEnums.Initial) {
      props.fetchTeams(authInfo);
    }
  }, []);
  useEffect(() => {
    if (props.loadingStatusCvStates === StatusEnums.Initial) {
      props.fetchCvSectionStates(authInfo);
    }
  }, []);
  useEffect(() => {
    if (props.loadingDownloadStatus === StatusEnums.Error) {
      setShowErrorModal(true);
    }
  }, [props.loadingDownloadStatus]);
  useEffect(() => {
    if (props.loadingStatusPersonal === StatusEnums.Error) {
      setShowErrorModal(true);
    }
    if (props.loadingStatusPersonal === StatusEnums.Initial) {
      props.fetchPersonal(authInfo);
    }
  }, [props.loadingStatusPersonal]);

  function keepLoading() {
    return (
      props.loadingStatus === StatusEnums.Initial ||
      props.loadingStatus === StatusEnums.Loading ||
      props.loadingStatusCvStates === StatusEnums.Initial ||
      props.loadingStatusCvStates === StatusEnums.Loading ||
      props.loadingDownloadStatus === StatusEnums.Loading
    );
  }

  const downloadCV = (id: number, personalInfo: PersonalInformationInput) => {
    const fetchParameter: FetchParameters = {
      id,
      authInfo,
      personalInfo,
    };
    props.fetchDownloadCV(fetchParameter);
  };
  /**RENDERING */
  // On error
  if (props.loadingStatus === StatusEnums.Error) {
    return <ErrorView errorMessage={props.errorMessage}></ErrorView>;
  }

  // Initial
  if (keepLoading()) {
    return (
      <div className="c-cv-lister">
        <Loader></Loader>
      </div>
    );
  }

  const incompleteSections = props?.cvSectionsStates
    .filter((section) => {
      if (NotRecuiredSectionsToLegalEntityIds[section.type]?.find((legalEntityId) => legalEntityId === props.legalEntityId) && !section.canPublish) {
        return section.canPublish;
      }
      return !section.canPublish;
    })
    .map((section) => section.type);

  return (
    <TrackedErrorBoundary>
      <div className="c-cv-lister">
        {validCvs.length <= 0 && <CvPlaceholder></CvPlaceholder>}
        {validCvs.length > 0 && (
          <div className="o-grid-template o-grid-template--fill o-grid--column-gap-large o-grid-template--large-cells">
            {validCvs.map((cv, i) => {
              const sectionsMap: Map<SectionType, CvSectionStateEntity> = mapNavigationFromSectionData(
                props.cvSectionsStates,
                cv.cvId.toString(),
                cv.content.locale
              );
              return (
                <CvCard
                  key={`cv-card-${cv.cvId}`}
                  canPublish={!incompleteSections.length}
                  hasDraft={cv.hasDraft}
                  cvId={cv.cvId.toString()}
                  jobTitle={cv.content.input.jobTitle}
                  description={cv.content.input.description}
                  language={cv.content.locale}
                  continueUrl={getContinueEditingUrl(
                    sectionsMap,
                    cv.cvId.toString(),
                    props.legalEntityId,
                  )}
                  team={getOptionName(
                    cv.content.input.teamId.toString(),
                    props.teamsData
                  )}
                  lastEditDate={cv.content.changedOn}
                  incompleteSections={incompleteSections}
                  personalInfo={props.personalSectionDataInput}
                  onDownloadCv={() => downloadCV(cv.cvId, props.personalSectionDataInput)}
                ></CvCard>
              );
            })}
            <Modal
              modalId="savingError"
              isVisible={showErrorModal}
              modalText={t('general_modal-error')}
              textAccept={t('general__btn-ok')}
              handleAccept={() => {
                setShowErrorModal(false);
              }}
            ></Modal>
          </div>
        )}
      </div>
    </TrackedErrorBoundary>
  );
}

export default CvLister;
