import { AccountInfo, AuthenticationResult, IPublicClientApplication } from "@azure/msal-browser";
import { useAccount, useMsal } from "@azure/msal-react";
import { PersonalInfoFormData } from "cv-app/shared/services/personal-information/personal-types";
const { getAuthScope } = require("./getAuthScope");
let tokenPromise: Promise<AuthenticationResult> | null;

declare global {
    interface Window {
        serverConfig: any;
    }
}

export interface AuthInfo {
    instance: IPublicClientApplication;
    account: AccountInfo;
};

export interface DownloadCVPayload {
  cvId: number;
  lang: string;
  downloadType: string;
  documentType: string;
  sections: string[];
}


export const useAuth = () => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const authInfo: AuthInfo = {
        account: account,
        instance: instance
    }
    return authInfo;
}

const obtainToken = (authInfo: AuthInfo) => {
    if (!tokenPromise) tokenPromise = authInfo.instance.acquireTokenSilent({
        scopes: getAuthScope(),
        account: authInfo.account
    })
    return tokenPromise.then(response => {
        tokenPromise = null
        return response
    })
}

const createAuthHeaders = (token: string) => {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
    return headers;
}

const handleError = (response: Response) => {
    if (!response.ok) {
        throw Error(response.status.toString());
    }
    return response;
}

export function fetchJsonPost<T>(url: string, param: any, authInfo: AuthInfo): Promise<T> {
    const promise = obtainToken(authInfo);
    return promise.then((response) => {
        const headers = createAuthHeaders(response.accessToken);
        headers.append("Accept", "application/json");
        headers.append("Content-Type", "application/json");
        return fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(param)
        }).then((response) => {
            handleError(response);
            return response.json() as Promise<T>;
        });
    })
}

export function fetchJson<T>(url: string, authInfo: AuthInfo): Promise<T> {
    const promise = obtainToken(authInfo);
    return promise.then((response) => {
        const headers = createAuthHeaders(response.accessToken);
        headers.append("Content-Type", "application/json");
        return fetch(url, {
            method: 'GET',
            headers: headers
        }).then((data) => {
            handleError(data);
            return data.json() as Promise<T>;
        })
    })
}

export function fetchDownloadFile(url: string, authInfo: AuthInfo, personalInfo: PersonalInfoFormData): Promise<void> {
    const promise = obtainToken(authInfo);
    return promise.then((response => {
        const headers = createAuthHeaders(response.accessToken);

        return fetch(url, {
            method: 'GET',
            headers: headers
        }).then(response => {
            handleError(response);
            return response.blob()
        })
            .then(blob => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${personalInfo.firstName} ${personalInfo.lastName}_${personalInfo.jobTitle}.pdf`);
                document.body.appendChild(link);
                link.click();
            })
    }));
}

export function fetchDownloadCVFile(url: string, authInfo: AuthInfo, personalInfo: PersonalInfoFormData, body: DownloadCVPayload): Promise<void> {
    const promise = obtainToken(authInfo);
    return promise.then((response => {
      const headers = createAuthHeaders(response.accessToken);
      headers.append("Content-Type", "application/json");

      return fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(body),
      }).then(response => {
        handleError(response);
        return response.blob();
      }).then(blob => {
        let fileType = body.downloadType;
        if (fileType === 'word') {
          fileType = 'doc';
        }
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${personalInfo.firstName} ${personalInfo.lastName}_${personalInfo.jobTitle}.${fileType}`);
        document.body.appendChild(link);
        link.click();
      })
    }));
}

export function fetchDataBase64(url: string, authInfo: AuthInfo): Promise<string> {
    const promise = obtainToken(authInfo);
    return promise.then((response => {
        const headers = createAuthHeaders(response.accessToken);
        return fetch(url, {
            method: 'GET',
            headers: headers
        }).then((data) => {
            return data.arrayBuffer()
                .then((buffer) => {
                    let binary = '';
                    const bytes = new Uint8Array(buffer);
                    const len = bytes.byteLength;
                    for (let i = 0; i < len; i++) {
                        binary += String.fromCharCode(bytes[i]);
                    }
                    return Promise.resolve(window.btoa(binary));
                });
        });
    }));
}
