import { SectionType } from 'cv-app/utils/section-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TrackedErrorBoundary } from 'cv-app/shared/ui/tracked-error-boundary';

import './step-base.module.scss';
import StepLayout from '../../layout/step-layout/step-layout';
import { StatusEnums } from 'cv-app/utils/status-enums';
import { AuthInfo, useAuth } from 'cv-app/utils/api-auth-helper';
import { mapSectionToUrl, alreadyExistingCv } from 'cv-app/utils/helpers/cv-section-helper';
import { stringIsNullOrEmpty } from 'cv-app/utils/helpers/string-helper';
import { useParams, Redirect } from 'react-router-dom';
import { CvSectionStateEntity } from 'cv-app/shared/redux/cvs/cvs.slice';

/* eslint-disable-next-line */
export interface StepBaseProps {
  isInitialState: boolean;
  isError: boolean;
  errorMessage: string;
  stepKey: SectionType;
  nextBtnText?: string;
  prevBtnText?: string;
  onNextClicked: () => void;
  onPrevClicked: () => void;
  loadingStatusCvStates: StatusEnums;
  cvSectionsStates: CvSectionStateEntity[];
  fetchCvSectionStates: (authInfo: AuthInfo) => void;
}


export function StepBase(props: React.PropsWithChildren<StepBaseProps>) {
  //#region STATES
  const [redirectTo, setRedirectTo] = useState("");
  //#endregion

  const authInfo = useAuth();
  const { t, i18n } = useTranslation();
  const { cvid } = useParams<{ cvid: string }>();

  //#region Load states
  useEffect(() => {
    if (props.loadingStatusCvStates === StatusEnums.Initial) {
      props.fetchCvSectionStates(authInfo);
    }
  }, []);
  //#endregion

  //#region Url validation
  useEffect(() => {
    // Check if cvid is in url from the begining. Only personal step is allowed without it
    if (!cvid || props.loadingStatusCvStates === StatusEnums.Error) {
      setRedirectTo(mapSectionToUrl(SectionType.Personal));
    }
    // check cvid, in case the states are already loaded
    validateCvId();
  }, [])

  // if cv states are change, validate cvid
  useEffect(() => {
    validateCvId();
  }, [props.loadingStatusCvStates])

  function validateCvId() {
    if (props.loadingStatusCvStates === StatusEnums.Loaded || props.loadingStatusCvStates === StatusEnums.NotFound) {
      // It has to be the cvid of an existing cv (that has personal section), 
      // otherwise we need to redirect to personal step
      if (!alreadyExistingCv(props.cvSectionsStates, cvid, "en-US")) {
        setRedirectTo(mapSectionToUrl(SectionType.Personal));
      }
    }
  }
  //#endregion

  //#region RENDERING
  // When redirectTo was set (wrong url)
  if (!stringIsNullOrEmpty(redirectTo)) {
    return (<Redirect to={redirectTo}></Redirect>)
  }
  
  return (
    <TrackedErrorBoundary>
      <StepLayout
        isInitialState={props.isInitialState}
        isError={props.isError}
        errorMessage={props.errorMessage}
        stepKey={props.stepKey}
        nextBtnText={props.nextBtnText}
        prevBtnText={props.prevBtnText}
        onNextClicked={props.onNextClicked}
        onPrevClicked={props.onPrevClicked}
      >
        {props.children}
      </StepLayout>
    </TrackedErrorBoundary>
  );
  //#endregion
}

export default StepBase;
