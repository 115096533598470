import React from 'react';
import EduCard from 'cv-app/pages/step-education-view/components/edu-card/edu-card';

import './edu-lister.module.scss';
import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import { EducationEntity } from 'cv-app/shared/redux/education/education.slice';
import { getOptionName } from 'cv-app/utils/helpers/form-helper';
import AddLink from 'cv-app/shared/ui/components/add-link/add-link';
import { SectionType } from 'cv-app/utils/section-types';

/* eslint-disable-next-line */
export interface EduListerProps {
  deleteCallback: (eduId: number) => void;
  cvId: string;
  degreeMapping: SelectOption[];
  educations: EducationEntity[];
}

export function EduLister(props: EduListerProps) {
  function hasEducations() {
    return props.educations.length > 0;
  }
  
  return (
    <React.Fragment>
      {props.educations.map((card, i) => {
        return (
          <EduCard
            education={card}
            cvId={props.cvId}
            degreeName={getOptionName(card.educationDegreeId, props.degreeMapping)}
            deleteCallback={props.deleteCallback}
            key={`edu-card-${i}`} ></EduCard>
        );
      })}
      {
        <AddLink
          cvId={props.cvId}
          isFirstEntity={!hasEducations()}
          sectionKey={SectionType.Education}
        ></AddLink>
      }
    </React.Fragment>
  );
}

export default EduLister;
