import Box from 'cv-app/shared/ui/layout/box/box';
import Button from 'cv-app/shared/ui/molecules/button/button';
import Modal from 'cv-app/shared/ui/molecules/modal/modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ExportModal } from 'cv-app/shared/ui/components/export-modal/export-modal';
import { useAuth } from 'cv-app/utils/api-auth-helper';
import { PersonalInfoFormData } from 'cv-app/shared/services/personal-information/personal-types';
import { cleanHtml } from 'cv-app/shared/services/html-serialize/html-serialize';
import './cv-card.module.scss';

/* eslint-disable-next-line */
export interface CvCardProps {
  //name: string;
  team: string;
  jobTitle: string;
  language: string;
  description: string;
  cvId: string;
  hasDraft: boolean;
  canPublish: boolean;
  continueUrl: string;
  lastEditDate: string;
  incompleteSections: string[];
  personalInfo: PersonalInfoFormData
  onDownloadCv: () => void;
}

export function CvCard(props: CvCardProps) {
  const { t } = useTranslation();
  const lastEditDate = new Date(props.lastEditDate).toLocaleString("en-GB");

  const [showExportModal, setShowExportModal] = useState(false);

  let description = props.description.length > 249 ? `${props.description.slice(0, 249)}...` : props.description;

  if (description.includes('<![CDATA')) {
    description = description.replace('<![CDATA[', '').slice(0, -3);
  }

  const authInfo = useAuth();

  return (
    <article className='c-cv-card'>
      <Box isBordered={true} cssClass='c-cv-card__box u-margin-bottom-none'>
        <div className='o-flex o-flex--justify-content-between'>
          <div className='c-cv-card__hdr o-hidden'>
            <div className='c-cv-card__title text-truncate' title={props.jobTitle}>
              {props.jobTitle}
            </div>
          </div>
        </div>
        <p className='c-cv-card__description' dangerouslySetInnerHTML={{ __html: cleanHtml(description) }}></p>
        <div className='c-cv-card__date-changed'>
          {t('cv__last-edit')} {lastEditDate}
        </div>
        <div className='o-flex o-flex--justify-content-start'>
          <Button callbackOnClick={() => {setShowExportModal(true)}} buttonText={t('cv__btn-publish')} btnType='primary'
                  isEnabled={props.canPublish}></Button>
          <Modal
            modalId='exportModal'
            isVisible={showExportModal}
            modalContent={<ExportModal cvId={props.cvId} authInfo={authInfo} personalInfo={props.personalInfo} handleCancel={() => {
              setShowExportModal(false);
            }}  handleAccept={() => { setShowExportModal(false) }} />}
            buttonsPosition='start'
            customClass='export-modal'
          ></Modal>
          <Link className='c-btn c-btn--primary' to={props.continueUrl}>{t('cv__btn-edit')}</Link>
        </div>
        {props.incompleteSections.length > 0 &&
          <div className={'c-cv-card__alert'}>
            {t('cv__alert')}
            <div>{props.incompleteSections.map(section => t(`${section}__nav`)).join(',')}</div>
          </div>
        }
      </Box>
    </article>
  );
}

export default CvCard;
