import Box from 'cv-app/shared/ui/layout/box/box';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './cv-placeholder.module.scss';

/* eslint-disable-next-line */
export interface CvPlaceholderProps {
}

export function CvPlaceholder(props: CvPlaceholderProps) {
  const { t } = useTranslation();
  
  return (
    <Box cssClass="c-cv-placeholder__wrapper">
      <div className="c-cv-placeholder">
        <div className="c-cv-placeholder__title">
          {t("cv-placeholder__title")}
        </div>
        <Link className="c-btn c-btn--primary" to="/cv/personal">{t("cv-placeholder__btn-text")}</Link>
      </div>
      
    </Box>
  );
}

export default CvPlaceholder;
