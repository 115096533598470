// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import timeZonesList from 'moment-timezone/data/meta/latest.json';

export enum AVAILABLE_LANGUAGE_CODES {
  ENGLISH = 'en',
  GERMAN = 'de',
  FRENCH = 'fr',
  CHINESE = 'zh'
}

export const getLanguageByCountry = (country: string) => {
  switch (country) {
    case 'Belgium':
      return AVAILABLE_LANGUAGE_CODES.ENGLISH;
    case 'Netherlands':
      return AVAILABLE_LANGUAGE_CODES.ENGLISH;
    case 'Germany':
      return AVAILABLE_LANGUAGE_CODES.GERMAN;
    case 'France':
      return AVAILABLE_LANGUAGE_CODES.FRENCH;
    case 'Morocco':
      return AVAILABLE_LANGUAGE_CODES.FRENCH;
    case 'China':
      return AVAILABLE_LANGUAGE_CODES.CHINESE;
    default:
      return AVAILABLE_LANGUAGE_CODES.ENGLISH;
  }
};
export const getUserLocaleAndCountry = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //as any to fix types of timeZonesList
  const zone = (timeZonesList as any).zones[timeZone];
  if (zone) {
    const country = (timeZonesList as any).countries[zone.countries[0]].name as string;
    const locale = getLanguageByCountry(country);
    return {
      country,
      locale
    };
  } else {
    return {
      country: '',
      locale: 'en'
    };
  }

};

