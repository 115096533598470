//we need to separate into started and completed because
//backend fails if endDate is empty

import { compareDates } from "cv-app/utils/helpers/date-helper";
import { dateIsValid } from "../helpers/form-helper";
import { ListItem } from "../helpers/list-item-helper";

export interface HistoryItem extends ListItem {
    startDate: string;
    endDate?: string;
}
/**
 * Formats start and end date into "start to end" period
 * @param history HistoryItem with startDate and optional endDate
 * @returns a string of "start_date to end_date" or just the "start_date" if endDate is missing or equal
 */
export function gethistoryDates(history: HistoryItem) {
    const startDate = new Date(history.startDate).toLocaleDateString("en-GB");
    if (!history.endDate || !dateIsValid(history.endDate))
        return `${startDate}`;

    const endDate = new Date(history.endDate).toLocaleDateString("en-GB");
    if (endDate === startDate)
        return `${startDate}`;
    return `${startDate} to ${endDate}`
}

/**
 * Formats start and end date into "start_year-end_year" period
 * @param history HistoryItem with startDate and optional endDate
 * @returns a string of "start_year-end_year" or just the "start_year" if endDate is missing or equal
 */
export function gethistoryYears(history: HistoryItem, presentTranslation?: string) {
    const startDate = new Date(history.startDate).getFullYear();
    if (!history.endDate)
        return `${startDate}-${presentTranslation ?? 'present'}`;

    const endDate = new Date(history.endDate).getFullYear();
    if (endDate === startDate)
        return `${startDate}`;
    return `${startDate}-${endDate}`
}
 /**
  * Compares history items based on end date, then on start date (if end date is equal)
  * We sort them from the latest to the oldest, so see returns..
  * @param a HistoryItem to compare
  * @param b HistoryItem to compare
  * @returns -1 if a is newer than b, 1 if a is older than b, 0 if startDate and endDate are equal
  */
export function compareHistoryDates(a: HistoryItem, b: HistoryItem) {
    // If a is finished and b is in progress => sort b first
    if (a.endDate && !b.endDate) {
        return 1;
    }
    // If a is in progress and b is finished => sort a first
    if (!a.endDate && b.endDate) {
        return -1;
    }
    // If both have an end date, try to sort on that
    if (a.endDate && b.endDate) {
        const endDateCompareResult = compareDates(a.endDate, b.endDate);
        if (endDateCompareResult !== 0) {
            return -1 * endDateCompareResult; // Revert order because we *always* show latest first
        }
    }

    // We are here if both are in progress, or end date is the same (how probable is that?)
    const startDateCompareResult = compareDates(a.startDate, b.startDate);
    if (startDateCompareResult === 0)
        return 0; // Because -1 * 0 is -0 in js!!
    return -1 * startDateCompareResult;
}

export function isValidHistoryItem(item: HistoryItem, isFinished: boolean) {
    if (!dateIsValid(item.startDate))
        return false;
    if (isFinished) {
        if (!dateIsValid(item.endDate))
            return false;
        if (compareDates(item.startDate, item.endDate) > 0 )
            return false;
    }
    return true;
}
