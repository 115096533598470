import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'cv-app/shared/ui/molecules/button/button';
import SimpleSvg from 'cv-app/shared/ui/molecules/simple-svg/simple-svg';

import './card-actions.module.scss';

/* eslint-disable-next-line */
export interface CardActionsProps {
  editLink: string;
  deleteCallback?: () => void;
  extraCssClass?: string;
}

export function CardActions(props: CardActionsProps) {
  const svgSize = 28;
  return (
    <div className={`c-card__actions ${props.extraCssClass}`}>
      <Link to={props.editLink}>
        <SimpleSvg svgId="icon-edit" svgHeight={svgSize} svgWidth={svgSize}></SimpleSvg>
      </Link>
      {props.deleteCallback &&
        <Button
          buttonIcon="icon-trashcan"
          buttonIconHeight={svgSize}
          buttonIconWidth={svgSize}
          callbackOnClick={props.deleteCallback}
        ></Button>
      }

    </div>
  );
}

export default CardActions;
