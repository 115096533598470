import { connect } from "react-redux";
import { fetchCvSectionStates } from "cv-app/shared/redux/cvs/cvs.slice";
import { fetchConsultancy, postConsultancyInput } from 'cv-app/shared/redux/consultancy/consultancy.slice';
import StepConsultancyView from 'cv-app/pages/step-consultancy-view/step-consultancy-view';
import { fetchPersonal } from 'cv-app/shared/redux/personal/personal.slice';

const mapStateToProps = (state: any) => ({
    loadingStatus: state.consultancy.loadingStatus,
    loadingStatusCvStates: state.cvs.loadingSectionsStatus,
    cvSectionsStates: state.cvs.sections,
    errorMessage: state.consultancy.error,
    consultancies: Object.values(state.consultancy.sectionData.input.projects),
    sectionData: state.consultancy.sectionData,
    loadingStatusPersonal: state.personal.loadingStatus,
    legalEntityId: state.personal.sectionData.input.legalEntityId,
});

const mapDispatchToProps = {
    fetchPersonal,
    fetchConsultancy,
    postConsultancyInput,
    fetchCvSectionStates
};

export default connect(mapStateToProps, mapDispatchToProps)(StepConsultancyView)
