import { JobEntity } from 'cv-app/shared/redux/jobs/jobs.slice';
import AddLink from 'cv-app/shared/ui/components/add-link/add-link';
import CvArticleCard from 'cv-app/shared/ui/components/cv-article-card/cv-article-card';
import Timeline, { TimelineRow } from 'cv-app/shared/ui/components/timeline/timeline';
import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import { gethistoryYears } from 'cv-app/utils/base/history-helper';
import { mapJobToArticle } from 'cv-app/utils/jobs-helper/job-helper';
import { SectionType } from 'cv-app/utils/section-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

/* eslint-disable-next-line */
export interface OtherWorkListerProps {
  articleDeleteCallback: (articleId: number) => void;
  cvId: string;
  entities: JobEntity[];
  industries: SelectOption[];
}

export function OtherWorkLister(props: OtherWorkListerProps) {
  const { t } = useTranslation();

  const renderAddLink = () => {
    return (
      <AddLink
          cvId={props.cvId}
          isFirstEntity={props.entities.length === 0}
          sectionKey={SectionType.OtherWork}
        ></AddLink>
    );
  }

  const rows: TimelineRow[] = [];
  props.entities.forEach((x,i) => {
    const article = mapJobToArticle(x, props.industries);
    const row: TimelineRow = {
      aside: <div>{gethistoryYears(x, t('timeline_present'))}</div>,
      svg:  null,
      dotVariant: null,
      flag:
        <CvArticleCard
          article={article}
          editLink={`/cv/${props.cvId}/work-history/other/${article.id}`}
          deleteCallback={props.articleDeleteCallback}
          key={`project-card-${i}`}
        ></CvArticleCard>

    }
    rows.push(row);
  });

  return (
    <React.Fragment>
      {props.entities.length > 2 &&
        <div className='u-margin-bottom-small'>
          {renderAddLink()}
        </div>
      }

      <Timeline timelineRows={rows} isRequiredMarksDisabled={true}></Timeline>

      {renderAddLink()}
    </React.Fragment>
  );
}

export default OtherWorkLister;
