import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getCertificatesSection, postCertificates } from 'cv-app/shared/services/certificates/certificates-service';
import { CertificatesInput } from 'cv-app/shared/services/certificates/certificates-types';
import { PostListData, SectionData } from 'cv-app/shared/services/sections';
import { AuthInfo } from 'cv-app/utils/api-auth-helper';
import { HistoryItem } from 'cv-app/utils/base/history-helper';
import { SectionType } from 'cv-app/utils/section-types';
import { StatusEnums } from 'cv-app/utils/status-enums';
import { removeSpace } from 'cv-app/utils/helpers/string-helper';

export const CERTIFICATE_FEATURE_KEY = 'certificate';

/*
 * Update these interfaces according to your requirements.
 */
export interface CertificateEntity extends HistoryItem {
  id: number;
  name: string;
  issuedBy: string;
  labels: string[];
}

export interface CertificateState extends EntityState<CertificateEntity> {
  loadingStatus: StatusEnums;
  error: string;
  sectionData: SectionData<CertificatesInput>;
}

export const certificateAdapter = createEntityAdapter<CertificateEntity>();

export const fetchCertificate = createAsyncThunk(
  'certificate/fetchStatus',
  async (authInfo: AuthInfo) => {
    const response = await getCertificatesSection(authInfo);
    return Promise.resolve(response);
  }
);

export const postCertificateInput = createAsyncThunk(
  'certificate/postStatus',
  async (postData: PostListData<CertificateEntity>) => {
    postData.entities.forEach(element => {
      try {
       element.name = removeSpace(element.name);
       element.issuedBy = removeSpace(element.issuedBy);
     }
     catch { }
    });
    const response = await postCertificates({certificates: postData.entities, type: SectionType.Certificates}, postData.authInfo);    
    return Promise.resolve(response);
  }
);

export const initialCertificateState: CertificateState = certificateAdapter.getInitialState(
  {
    loadingStatus: StatusEnums.Initial,
    error: null,
    sectionData: {
      id: '',
      cvId: '',
      locale: '',
      canPublish: false,
      changedBy: '',
      changedOn: '',
      type: SectionType.Certificates,
      input: {
        type: SectionType.Certificates,
        certificates: []
      }
    }
  }
);

export const certificateSlice = createSlice({
  name: CERTIFICATE_FEATURE_KEY,
  initialState: initialCertificateState,
  reducers: {
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCertificate.pending, (state: CertificateState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(postCertificateInput.pending, (state: CertificateState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(
        fetchCertificate.fulfilled,
        (
          state: CertificateState,
          action: PayloadAction<SectionData<CertificatesInput>>
        ) => {
          state.loadingStatus = StatusEnums.Loaded;
          state.sectionData = action.payload;
        }
      )
      .addCase(
        postCertificateInput.fulfilled,
        (
          state: CertificateState,
          action: PayloadAction<SectionData<CertificatesInput>>
        ) => {
          state.loadingStatus = StatusEnums.Loaded;
          state.sectionData = action.payload;
        }
      )
      .addCase(fetchCertificate.rejected, (state: CertificateState, action) => {
        if (action.error.message === '404') {
          state.loadingStatus = StatusEnums.NotFound;
          state.sectionData = initialCertificateState.sectionData;
        } else {
          state.loadingStatus = StatusEnums.Error;
        }
        state.error = action.error.message;
      })
      .addCase(postCertificateInput.rejected, (state: CertificateState, action) => {
        state.loadingStatus = StatusEnums.SubmitError;
        state.error = action.error.message;
      });
  },
});

/*
 * Export reducer for store configuration.
 */
export const certificateReducer = certificateSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(certificateActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const certificateActions = certificateSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllCertificate);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = certificateAdapter.getSelectors();

export const getCertificateState = (rootState: unknown): CertificateState =>
  rootState[CERTIFICATE_FEATURE_KEY];

export const selectAllCertificate = createSelector(
  getCertificateState,
  selectAll
);

export const selectCertificateEntities = createSelector(
  getCertificateState,
  selectEntities
);
