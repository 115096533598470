import { SelectOption } from "cv-app/shared/ui/molecules/form-select/form-select";
import { AuthInfo, fetchJson, fetchJsonPost } from "cv-app/utils/api-auth-helper";
import { SectionData } from "../sections";
import { PersonalInformationInput } from "cv-app/shared/services/personal-information/personal-types";
import 'cv-app/utils/base/array-helper';

// GET for teams data
//#region
interface Team {
  id: number;
  code: string;
  name: string;
}

export function getTeamsData(authInfo: AuthInfo): Promise<SelectOption[]> {
  const teams = JSON.parse(sessionStorage.getItem('teams'))
  if (teams) return getTeamsFromSession<SelectOption[]>();
  return getTeamsFromApi<SelectOption[]>(authInfo);
}
function getTeamsFromSession<T>(): Promise<T> {
  return new Promise((resolve, reject) => {
    const teams = JSON.parse(sessionStorage.getItem('teams'))
    if (teams) resolve(teams)
    reject()
  })
}
function getTeamsFromApi<T> (authInfo: AuthInfo): Promise<SelectOption[]> {
  return new Promise((resolve, reject) => {
    fetchJson<Team[]>('/api/teams', authInfo)
    .then(teams => {
      const selectOption: SelectOption[] = teams.map(d => {
        return {
            optionName: d.name,
            optionValue: d.id.toString()
        }
      });
      selectOption.sortObjects(x => x.optionName.toUpperCase());
      sessionStorage.setItem('teams', JSON.stringify(selectOption));
      resolve(selectOption);
    })
    .catch(err => {
      reject(err)
    })
  })
}
//#endregion

export function getLegalEntitieTeams<T> (authInfo: AuthInfo, legalEntityId: number | string): Promise<SelectOption[]> {
  return new Promise((resolve, reject) => {
    fetchJson<Team[]>(`/api/teams/${legalEntityId}`, authInfo)
    .then(teams => {
      const selectOption: SelectOption[] = teams.map(d => {
        return {
            optionName: d.name,
            optionValue: d.id.toString()
        }
      });
      selectOption.sortObjects(x => x.optionName.toUpperCase());
      resolve(selectOption);
    })
    .catch(err => {
      reject(err)
    })
  })
}

// GET and POST for personal-info section
//#region
export function getPeronsalInformationSection(authInfo: AuthInfo): Promise<SectionData<PersonalInformationInput>> {
  return fetchJson<SectionData<PersonalInformationInput>>('/api/cvs/sections/personal-information?draft=true', authInfo);
}
export function postPeronsalInformation(data: PersonalInformationInput, authInfo: AuthInfo) {
  return fetchJsonPost<SectionData<PersonalInformationInput>>('/api/cvs/sections', data, authInfo)
}
//#endregion
