import React from 'react';
import Box from '../../layout/box/box';
import Section from '../../layout/section/section';
import { getUserLocaleAndCountry } from 'cv-app/utils/helpers/user-language-helper';
import * as Bowser from 'bowser';
import './error-view.module.scss';

/* eslint-disable-next-line */
export interface ErrorViewProps {
  errorMessage: string;
}

export function ErrorView(props: ErrorViewProps) {
  const { os, platform, browser } = Bowser.parse(window.navigator.userAgent);

  return (
    <Section>
      <Box asSection={true} cssClass="u-bg-color-white">
        <div>
          <h1>Oops!</h1>
          <h4>It seems an error has occured with the following message:</h4>
          <div className="c-codeblock">
            { navigator.onLine ? <code>{props.errorMessage}</code> : <code>Please check your internet connection.</code> }
            <br />
            <br />
            Please contact <a href="https://internal.delaware.pro/" target="_blank">ICC</a> if this error persists
          </div>
          <br/>
          <div>{new Date().toLocaleString("en-GB")}</div>
          <div>{getUserLocaleAndCountry().country}</div>
          <div>{`${os.name} ${os.versionName} ${os.version} ${platform.type}`}</div>
          <div>{`${browser.name} ${browser.version}`}</div>
        </div>
      </Box>
    </Section>
  );
}

export default ErrorView;
