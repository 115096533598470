// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cv-card_c-cv-card__1TJ9t {\n  width: 570px;\n}\n.cv-card_c-cv-card__title__R4LBL {\n  font-size: 24px;\n  font-weight: 600;\n  align-items: center;\n  margin-bottom: 26px;\n}\n.cv-card_c-cv-card__subtitle__rBWbU {\n  font-size: 18px;\n  padding-bottom: 27px;\n}\n.cv-card_c-cv-card__date-changed__20URF {\n  font-size: 20px;\n  margin: 16px 0;\n}\n.cv-card_c-cv-card__alert__3yF3y {\n  color: brandColor(\"dot-red\");\n}\n.cv-card_c-cv-card__language-flag__2XJW- {\n  --c-card__language-color: brandColor(\"dot-red\");\n  background-color: var(--c-card__language-color);\n  color: brandColor(\"white\");\n  font-size: 12px;\n  font-weight: 500;\n  text-transform: uppercase;\n  padding: 3px 10px;\n  margin-left: 5px;\n  border-radius: 10px;\n}\n.cv-card_c-cv-card__language-flag--fr__2NLNX {\n  --c-card__language-color: var(--brand__color--steal);\n}\n.cv-card_c-cv-card__language-flag--nl__2W0t2 {\n  --c-card__language-color: var(--brand__color--purple);\n}\n.cv-card_c-cv-card__description__3Pk8U {\n  overflow-wrap: break-word;\n}", ""]);
// Exports
exports.locals = {
	"c-cv-card": "cv-card_c-cv-card__1TJ9t",
	"c-cv-card__title": "cv-card_c-cv-card__title__R4LBL",
	"c-cv-card__subtitle": "cv-card_c-cv-card__subtitle__rBWbU",
	"c-cv-card__date-changed": "cv-card_c-cv-card__date-changed__20URF",
	"c-cv-card__alert": "cv-card_c-cv-card__alert__3yF3y",
	"c-cv-card__language-flag": "cv-card_c-cv-card__language-flag__2XJW-",
	"c-cv-card__language-flag--fr": "cv-card_c-cv-card__language-flag--fr__2NLNX",
	"c-cv-card__language-flag--nl": "cv-card_c-cv-card__language-flag--nl__2W0t2",
	"c-cv-card__description": "cv-card_c-cv-card__description__3Pk8U"
};
module.exports = exports;
