// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".example-component_c-example__title__vtP56 {\n  font-weight: 700;\n  font-size: 18px;\n  margin-bottom: 8px;\n}\n.example-component_c-example__link__15328 {\n  color: #c42828;\n}\n.example-component_c-example__YgrOu table {\n  width: 100%;\n}", ""]);
// Exports
exports.locals = {
	"c-example__title": "example-component_c-example__title__vtP56",
	"c-example__link": "example-component_c-example__link__15328",
	"c-example": "example-component_c-example__YgrOu"
};
module.exports = exports;
