import React from 'react';
import Section from 'cv-app/shared/ui/layout/section/section';
import Box from 'cv-app/shared/ui/layout/box/box';

import './boxed-component.module.scss';

/* eslint-disable-next-line */
export interface BoxedComponentProps {
  componentTitle?: string;
  componentDescription?: string;
}

export function BoxedComponent(props: React.PropsWithChildren<BoxedComponentProps>) {
  return (
    <Section>
      <Box isLight={true} asSection={true} boxTitle={props.componentTitle} boxDescription={props.componentDescription}>
        {props.children}
      </Box>
    </Section>
  );
}

export default BoxedComponent;
