import Box from 'cv-app/shared/ui/layout/box/box';
import React from 'react';

import './edu-card.module.scss';
import CardActions from 'cv-app/shared/ui/components/card-actions/card-actions';
import { gethistoryDates } from 'cv-app/utils/base/history-helper';
import { EducationEntity } from 'cv-app/shared/redux/education/education.slice';


/* eslint-disable-next-line */
export interface EduCardProps {
  cvId: string;
  deleteCallback: (eduId: number) => void;
  education: EducationEntity;
  degreeName: string;
}

export function EduCard(props: EduCardProps) {
  return (
    <Box isBordered={true}>
      <article className="c-edu-card">
        <div className="c-edu-card__wrapper">
          <div className="c-edu-card__info o-hidden">
            <div className="c-edu-card__title text-truncate" title={props.education.schoolName}>
              {props.education.schoolName}
            </div>
            <div className="c-edu-card__subtitle">
              {props.degreeName}, {props.education.fieldOfStudy}
            </div>
            <div className="c-edu-card__extra-info">{gethistoryDates(props.education)}</div>
          </div>
          <CardActions
            editLink={`/cv/${props.cvId}/education/${props.education.id}`}
            deleteCallback={() => {
              props.deleteCallback(props.education.id);
            }}
            extraCssClass="c-edu-card__actions"
          ></CardActions>
        </div>
        <p className="u-margin-bottom-none text-truncate">{props.education.description}</p>
      </article>
    </Box>
  );
}

export default EduCard;
