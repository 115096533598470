import React from 'react';
import './export-modal.scss';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';
import Button from 'cv-app/shared/ui/molecules/button/button';
import { downloadCVPost } from 'cv-app/shared/services/cvs/cv-service';
import { AuthInfo, DownloadCVPayload } from 'cv-app/utils/api-auth-helper';
import { PersonalInfoFormData } from 'cv-app/shared/services/personal-information/personal-types';

/* eslint-disable-next-line */
export interface ExportModalProps {
  cvId: string;
  authInfo: AuthInfo;
  personalInfo: PersonalInfoFormData;
  handleCancel: () => void;
  handleAccept: () => void;
}

export function ExportModal(props: ExportModalProps) {
  const { t } = useTranslation();

  const modularItmes = [
    { name: 'profileInfo', value: 'personal' },
    { name: 'education', value: 'educations' },
    { name: 'certificates', value: 'certifications' },
    { name: 'languages', value: 'languages' },
    { name: 'consultancy', value: 'consultancyWorkExperience' },
    { name: 'otherWork', value: 'otherWorkExperience' },
  ];

  return (
    <div className='c-export-modal'>
      <div className='c-export-modal__title'>{t('export_modal_header')}</div>
      <div className='c-export-modal__description'>{t('export_modal_description')}</div>
      <Formik
        initialValues={{ lang: '', fileFormat: '', docType: 'default', modular: [
          'personal',
          'educations',
          'certifications',
          'languages',
          'consultancyWorkExperience',
          'otherWorkExperience',
        ]}}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const body: DownloadCVPayload = {
            cvId: +props.cvId,
            lang: values.lang,
            downloadType: values.fileFormat,
            documentType: values.docType,
            sections: values.docType === 'modular' ? values.modular : undefined,
          }
          downloadCVPost(props.authInfo, props.personalInfo, body);
          setSubmitting(false);
          resetForm();
        }}
     >
       {({values}) => (
        <Form>
          <div>
            <div className="select-type">
              <div className="type-title">{t('export_modal_select_language')}</div>
              <div className="types-container d-flex flex-wrap" role="group">
                <label htmlFor="de" className="d-flex align-center">
                  <Field type="radio" name="lang" id="de" value="de" />
                  German
                </label>
                <label htmlFor="en" className="d-flex align-center">
                  <Field type="radio" name="lang" id="en" value="en" />
                  English
                </label>
                <label htmlFor="fr" className="d-flex align-center">
                  <Field type="radio" name="lang" id="fr" value="fr" />
                  French
                </label>
                <label htmlFor="nl" className="d-flex align-center">
                  <Field type="radio" name="lang" id="nl" value="nl" />
                  Dutch
                </label>
                <label htmlFor="pt" className="d-flex align-center">
                  <Field type="radio" name="lang" id="pt" value="pt" />
                  Portuguese (Brazil)
                </label>
                <label htmlFor="zh-Hans" className="d-flex align-center">
                  <Field type="radio" name="lang" id="zh-Hans" value="zh-Hans" />
                  Chinese Simplified
                </label>
              </div>
            </div>
            <div className="select-type">
              <div className="type-title">{t('export_modal_document_type_text')}</div>
              <div className="types-container d-flex flex-wrap" role="group">
                <label htmlFor="pdf" className="d-flex align-center">
                  <Field type="radio" name="fileFormat" id="pdf" value="pdf" />
                  PDF
                </label>
                <label htmlFor="word" className="d-flex align-center">
                  <Field type="radio" name="fileFormat" id="word" value="word" />
                  Word
                </label>
              </div>
            </div>
            <div className="doc-type">
              <div className="doc-type-title">{t('export_modal_document_type_title')}</div>
              <div className="doc-types-container d-flex flex-wrap" role="group">
                <div className="d-flex flex-column" style={{width: "31%"}}>
                  <label htmlFor="basic" className="d-flex align-center">
                    <Field type="radio" name="docType" id="basic" value="default" />
                    {t('export_modal_basic')}
                  </label>
                  <label htmlFor="anon" className="d-flex align-center">
                    <Field type="radio" name="docType" id="anon" value="anon" />
                    {t('export_modal_anonymous')}
                  </label>
                </div>
                <div className="d-flex flex-column">
                  <label htmlFor="onePage" className="d-flex align-center">
                    <Field type="radio" name="docType" id="onePage" value="onePage" />
                    {t('export_modal_onePager')}
                  </label>
                  <label htmlFor="modular" className="d-flex align-center">
                    <Field type="radio" name="docType" id="modular" value="modular" />
                    {t('export_modal_modular')}
                  </label>
                </div>
              </div>
            </div>
            { values.docType === 'modular' &&
             <div className="modular-section">
              <div className="modular-title">{t('export_modal_modular')}</div>
              <div className="modular-container d-flex flex-wrap" role="group" aria-labelledby="checkbox-group">
                { modularItmes.map((item, i) => {
                  return <label className="d-flex align-center" key={i}>
                    <Field type="checkbox" name="modular" value={item.value} />
                    {t('export_modal_' + item.name)}
                  </label>
                })
              }
              </div>
            </div>
            }
            <div className="o-flex o-flex--justify-content-start actions-panel">
              <Button
                buttonText={t('export_modal_cancel')}
                btnType='secondary'
                type='button'
                callbackOnClick={props.handleCancel}
              ></Button>
              <Button
                buttonText={t('export_modal_download')}
                btnType='primary'
                type='submit'
                isEnabled={!!values.lang && !!values.docType && !!values.fileFormat}
                callbackOnClick={props.handleAccept}
              ></Button>
            </div>
          </div>
        </Form>
       )}
     </Formik>
    </div>
  );
}
