// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".media_c-media-placeholder__1gD0r {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: brandColor(\"white\");\n  filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.15));\n}\n.media_c-media--round__3x5_T .media_c-media-placeholder__1gD0r {\n  border-radius: 50%;\n}\n\n.media_c-media--fit__1TNql {\n  height: 100%;\n}\n\n@media screen and (max-width: 600px) {\n  .media_c-media--fit__1TNql {\n    width: 150px;\n    height: 150px;\n  }\n}", ""]);
// Exports
exports.locals = {
	"c-media-placeholder": "media_c-media-placeholder__1gD0r",
	"c-media--round": "media_c-media--round__3x5_T",
	"c-media--fit": "media_c-media--fit__1TNql"
};
module.exports = exports;
