import React from 'react';
import './add-btn.module.scss';
import { SectionType } from 'cv-app/utils/section-types';
import { useTranslation } from 'react-i18next';
import Button from '../../molecules/button/button';

/* eslint-disable-next-line */
export interface AddButtonProps {
  isFirstEntity: boolean;
  sectionKey: SectionType;
  onClickCallback: () => void;
}

export function AddButton(props: AddButtonProps) {
  const { t } = useTranslation();
  const wrapperClass = props.isFirstEntity ? "o-flex o-flex--justify-content-center" : "o-flex o-flex--justify-content-end";
  const btnTextKey = props.isFirstEntity ? "add-first-btn-text" : "add-another-btn-text";
  
  return (
    <div className={wrapperClass}>
      <Button
          btnType={props.isFirstEntity ? 'primary' : null}
          buttonText={t(`${props.sectionKey}__${btnTextKey}`)}
          callbackOnClick={props.onClickCallback}
          buttonIcon={props.isFirstEntity ? null : "icon-plus"}
          buttonIconHeight={props.isFirstEntity ? null : 10}
          buttonIconWidth={props.isFirstEntity ? null : 10}
          buttonIconExtraCss={props.isFirstEntity ? null : "u-padding-right-tiny"}
      ></Button>
    </div>
  );
}

export default AddButton;
