export interface ListItem {
    id: number;
}

export function getNextListItemId(items: ListItem[]) {
    if (!items)
        return 1;
    const ids = items.map((item) => {return item.id});
    if (ids.length <= 0)
        return 1;
    const maxId = Math.max(...ids);
    return maxId + 1;
}

export function isValidListItem(item: ListItem) {
    return item.id > 0;
}