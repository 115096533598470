import { AuthInfo, fetchJson, fetchJsonPost } from "cv-app/utils/api-auth-helper";
import { SectionData } from "cv-app/shared/services/sections";
import { OtherWorkInput } from "cv-app/shared/services/jobs/jobs-types";
import { unescapeHtml } from "../html-serialize/html-serialize";

//GET and POST for consultancy section
//#region
export function getWorkSection(authInfo: AuthInfo): Promise<SectionData<OtherWorkInput>> {
  return fetchJson<SectionData<OtherWorkInput>>('/api/cvs/sections/other-work-reference?draft=true', authInfo);
}
export function postWorkSection(input: OtherWorkInput, authInfo: AuthInfo) : Promise<SectionData<OtherWorkInput>> {
  const otherWorkHistoryItems = input.otherWorkHistoryItems.map(item => {
    return Object.assign({}, item, { description: unescapeHtml(item.description) });
  });
  return fetchJsonPost<SectionData<OtherWorkInput>>('/api/cvs/sections', { otherWorkHistoryItems, type: input.type }, authInfo);
}
//#endregion
