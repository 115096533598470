import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getEntities } from 'cv-app/shared/services/data-service';
import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import { AuthInfo } from 'cv-app/utils/api-auth-helper';
import { StatusEnums } from 'cv-app/utils/status-enums';

export const ENTITIES_FEATURE_KEY = 'legalentities';

export interface EntitiesState extends EntityState<SelectOption> {
  loadingStatus: StatusEnums;
  error: string;
  legalEntities: SelectOption[];
}

export const entitiesAdapter = createEntityAdapter<SelectOption>();

export const fetchLegalEntities = createAsyncThunk(
  'entities/fetchStatus',
  async (authInfo: AuthInfo, thunkAPI) => {
    const response = getEntities(authInfo);
    return Promise.resolve(response);
  }
);

export const initialEntitiesState: EntitiesState = entitiesAdapter.getInitialState(
  {
    loadingStatus: StatusEnums.Initial,
    error: null,
    legalEntities: []
  }
);

export const entitiesSlice = createSlice({
  name: ENTITIES_FEATURE_KEY,
  initialState: initialEntitiesState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLegalEntities.pending, (state: EntitiesState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(
        fetchLegalEntities.fulfilled,
        (
          state: EntitiesState,
          action: PayloadAction<SelectOption[]>
        ) => {
          state.legalEntities = action.payload;
          state.loadingStatus = StatusEnums.Loaded;
        }
      )
      .addCase(
        fetchLegalEntities.rejected,
        (state: EntitiesState, action) => {
          state.loadingStatus = StatusEnums.Error;
          state.error = action.error.message;
        }
      );
  },
});

/*
 * Export reducer for store configuration.
 */
export const entitiesReducer = entitiesSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(entitiesActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const entitiesActions = entitiesSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllEntities);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = entitiesAdapter.getSelectors();

export const getEntitiesState = (rootState: unknown): EntitiesState =>
  rootState[ENTITIES_FEATURE_KEY];

export const selectAllentities = createSelector(
  getEntitiesState,
  selectAll
);

export const selectEntityItemEntities = createSelector(
  getEntitiesState,
  selectEntities
);
