/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getLoggedUserData, getUserPhoto } from 'cv-app/shared/services/me/me-service';
import { AuthInfo } from 'cv-app/utils/api-auth-helper';
import { stringIsNullOrEmpty } from 'cv-app/utils/helpers/string-helper';
import { StatusEnums } from 'cv-app/utils/status-enums';

export const ME_FEATURE_KEY = 'me';

// INTERFACES
//#region
export interface MeEntity {
  id: number;
  firstName: string;
  lastName: string;
  nationalityId: number;
  entityId: number;
  jobTitle: string;
  photoUri: string;
  hasPhoto: boolean;
  photoDataBase64: string;
  noOdsUserData?: boolean;
}

export interface MeState extends EntityState<MeEntity> {
  loadingStatus: StatusEnums;
  imageLoadingStatus: StatusEnums;
  error: string;
  userData: MeEntity;
}

export interface FetchPhotoParameters {
  photoUrl: string;
  authInfo: AuthInfo;
}
//#endregion

// ADAPTER
//#region
export const meAdapter = createEntityAdapter<MeEntity>();
//#endregion

// ASYNC THUNK
//#region
export const fetchMe = createAsyncThunk(
  'me/fetchStatus',
  async (authInfo: AuthInfo, thunkAPI) => {
    let response: MeEntity | unknown;
    try {
      response = await getLoggedUserData(authInfo);
      if (Object.keys(response).length === 0) {
        response = { noOdsUserData: true };
      }
    } catch (error) {
      response = { noOdsUserData: true };
    }
    return Promise.resolve(response);
  }
);
//#endregion

// ASYNC THUNK
//#region
export const fetchPhoto = createAsyncThunk(
  'me/fetchPhotoStatus',
  async (parameters: FetchPhotoParameters, thunkAPI) => {
    const response = await getUserPhoto(parameters.photoUrl, parameters.authInfo);
    return Promise.resolve(response);
  }
);
//#endregion

// INITIAL STATE
//#region
export const initialMeState: MeState = meAdapter.getInitialState({
  loadingStatus: StatusEnums.Initial,
  imageLoadingStatus: StatusEnums.Initial,
  error: null,
  userData: {
    firstName: '',
    lastName: '',
    jobTitle: '',
    nationalityId: -1,
    entityId: -1,
    hasPhoto: false,
    photoUri: '',
    photoDataBase64: '',
    id: -1
  }
});
//#endregion

// CREATE SLICE
//#region
export const meSlice = createSlice({
  name: ME_FEATURE_KEY,
  initialState: initialMeState,
  reducers: {
    // ...
  },
  extraReducers: (builder) => {
    // @ts-ignore
    builder
      .addCase(fetchMe.pending, (state: MeState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(
        fetchMe.fulfilled,
        (state: MeState, action: PayloadAction<MeEntity>) => {
          state.loadingStatus = StatusEnums.Loaded;
          state.userData = action.payload;
          state.userData.hasPhoto = !stringIsNullOrEmpty(action.payload.photoUri);
        }
      )
      .addCase(fetchMe.rejected, (state: MeState, action) => {
        state.loadingStatus = StatusEnums.Error;
        state.error = action.error.message;
      })
      .addCase(fetchPhoto.pending, (state: MeState) => {
        state.imageLoadingStatus = StatusEnums.Loading;
      })
      .addCase(
        fetchPhoto.fulfilled,
        (state: MeState, action: PayloadAction<string>) => {
          state.imageLoadingStatus = StatusEnums.Loaded;
          state.userData.photoDataBase64 = action.payload;
        }
      )
      .addCase(fetchPhoto.rejected, (state: MeState, action) => {
        state.imageLoadingStatus = StatusEnums.Error;
        state.error = action.error.message;
      });;
  },
});
//#endregion

// REDUCER
//#region
/*
 * Export reducer for store configuration.
 */
export const meReducer = meSlice.reducer;
//#endregion

// ACTIONS
//#region
/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(meActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const meActions = meSlice.actions;
//#endregion

// SELECTORS
//#region
/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllMe);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = meAdapter.getSelectors();

export const getMeState = (rootState: unknown): MeState =>
  rootState[ME_FEATURE_KEY];

export const selectAllMe = createSelector(getMeState, selectAll);

export const selectMeEntities = createSelector(getMeState, selectEntities);
//#endregion
