// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".certificates-lister_c-card-lister__1Fg3T {\n  display: flex;\n  flex-direction: column;\n}\n\n.certificates-lister_c-card-lister__item-wrapper__3S0FX {\n  display: grid;\n  grid-template-columns: var(--c-card-lister__item-wrapper--grid-template-columns, 1fr);\n  grid-gap: var(--c-card-lister__item-wrapper--grid-gap, 0);\n}\n@media only screen and (min-width: 768px) {\n  .certificates-lister_c-card-lister__item-wrapper__3S0FX {\n    --c-card-lister__item-wrapper--grid-template-columns: 1fr 1fr;\n    --c-card-lister__item-wrapper--grid-gap: 20px;\n  }\n}\n\n.certificates-lister_c-card-lister__button__3jopW {\n  margin-top: 20px;\n  align-self: flex-end;\n}\n.certificates-lister_c-card-lister__button--centered__2Y8YJ {\n  align-self: center;\n}", ""]);
// Exports
exports.locals = {
	"c-card-lister": "certificates-lister_c-card-lister__1Fg3T",
	"c-card-lister__item-wrapper": "certificates-lister_c-card-lister__item-wrapper__3S0FX",
	"c-card-lister__button": "certificates-lister_c-card-lister__button__3jopW",
	"c-card-lister__button--centered": "certificates-lister_c-card-lister__button--centered__2Y8YJ"
};
module.exports = exports;
