import { fetchCvSectionStates } from "cv-app/shared/redux/cvs/cvs.slice";
import { SectionType } from "cv-app/utils/section-types";
import { connect } from "react-redux";
import StepNav from "../components/step-nav";
import { fetchPersonal } from 'cv-app/shared/redux/personal/personal.slice';

export interface StepNavContainerProps {
    currentStep: SectionType;
}

const mapStateToProps = (state: any, ownProps: StepNavContainerProps) => ({
    loadingStatusCvStates: state.cvs.loadingSectionsStatus,
    cvSectionsStates: state.cvs.sections,
    currentStep: ownProps.currentStep,
    loadingStatusPersonal: state.personal.loadingStatus,
    legalEntityId: state.personal.sectionData.input.legalEntityId,
});

const mapDispatchToProps = {
    fetchCvSectionStates,
    fetchPersonal,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepNav);
