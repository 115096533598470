import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getWorkSection, postWorkSection } from 'cv-app/shared/services/jobs/jobs-service';
import { OtherWorkInput, WorkEntity } from 'cv-app/shared/services/jobs/jobs-types';
import { PostListData, SectionData } from 'cv-app/shared/services/sections';
import { AuthInfo } from 'cv-app/utils/api-auth-helper';
import { SectionType } from 'cv-app/utils/section-types';
import { StatusEnums } from 'cv-app/utils/status-enums';

export const JOBS_FEATURE_KEY = 'jobs';

export interface JobEntity extends WorkEntity {
  companyName: string;
}

export interface JobsState extends EntityState<JobEntity> {
  loadingStatus: StatusEnums;
  error: string;
  sectionData: SectionData<OtherWorkInput>;
}

export const jobsAdapter = createEntityAdapter<JobEntity>();

export const fetchJobs = createAsyncThunk(
  'jobs/fetchStatus',
  async (authInfo: AuthInfo) => {
    const response = await getWorkSection(authInfo)
    return Promise.resolve(response);
  }
);

export const postJobsInput = createAsyncThunk(
  'jobs/postStatus',
  async (postData: PostListData<JobEntity>) => {
    const response = await postWorkSection({
      otherWorkHistoryItems: postData.entities,
      type: SectionType.OtherWork
    }, postData.authInfo);
    return Promise.resolve(response);
  }
)

export const initialJobsState: JobsState = jobsAdapter.getInitialState({
  loadingStatus: StatusEnums.Initial,
    error: null,
    sectionData: {
      id: '',
      cvId: '',
      locale: '',
      canPublish: false,
      changedBy: '',
      changedOn: '',
      type: SectionType.OtherWork,
      input: {
        type: SectionType.OtherWork,
        otherWorkHistoryItems: []
      }
    }
});

export const jobsSlice = createSlice({
  name: JOBS_FEATURE_KEY,
  initialState: initialJobsState,
  reducers: {
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobs.pending, (state: JobsState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(postJobsInput.pending, (state: JobsState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(
        fetchJobs.fulfilled,
        (state: JobsState, action: PayloadAction<SectionData<OtherWorkInput>>) => {
          state.loadingStatus = StatusEnums.Loaded;
          state.sectionData = action.payload;
        }
      )
      .addCase(
        postJobsInput.fulfilled,
        (state: JobsState, action: PayloadAction<SectionData<OtherWorkInput>>) => {
          state.loadingStatus = StatusEnums.Loaded;
          state.sectionData = action.payload;
        }
      )
      .addCase(fetchJobs.rejected, (state: JobsState, action) => {
        if (action.error.message === '404') {
          state.loadingStatus = StatusEnums.NotFound;
          state.sectionData = initialJobsState.sectionData;
        } else {
          state.loadingStatus = StatusEnums.Error;
        }
        state.error = action.error.message;
      })
      .addCase(postJobsInput.rejected, (state: JobsState, action) => {
        state.loadingStatus = StatusEnums.SubmitError;
        state.error = action.error.message;
      })
      ;
  },
});

/*
 * Export reducer for store configuration.
 */
export const jobsReducer = jobsSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(jobsActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const jobsActions = jobsSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllJobs);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = jobsAdapter.getSelectors();

export const getJobsState = (rootState: unknown): JobsState =>
  rootState[JOBS_FEATURE_KEY];

export const selectAllJobs = createSelector(getJobsState, selectAll);

export const selectJobsEntities = createSelector(getJobsState, selectEntities);
