import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getConsultancySection, postConsultancies } from 'cv-app/shared/services/consultancy/consultancy-service';
import { ConsultancyInput } from 'cv-app/shared/services/consultancy/consultancy-types';
import { WorkEntity } from 'cv-app/shared/services/jobs/jobs-types';
import { PostListData, SectionData } from 'cv-app/shared/services/sections';
import { AuthInfo } from 'cv-app/utils/api-auth-helper';
import { SectionType } from 'cv-app/utils/section-types';
import { StatusEnums } from 'cv-app/utils/status-enums';

export const CONSULTANCY_FEATURE_KEY = 'consultancy';

// INTERFACES
//#region 
export interface ConsultancyEntity extends WorkEntity {
  id: number;
  isDelaware: boolean;
  customerName: string;
  timesheetCode: string;
  projectInformation: string;
}
export interface ConsultancyState extends EntityState<ConsultancyEntity> {
  loadingStatus: StatusEnums;
  error: string;
  sectionData: SectionData<ConsultancyInput>;
}
//#endregion

// ADAPTER
//#region 
export const consultancyAdapter = createEntityAdapter<ConsultancyEntity>();
//#endregion

// ASYNC THUNK
//#region 
export const fetchConsultancy = createAsyncThunk(
  'consultancy/fetchStatus',
  async (authInfo: AuthInfo, thunkAPI) => {
    const response = await getConsultancySection(authInfo);
    return Promise.resolve(response);
  }
);
export const postConsultancyInput = createAsyncThunk(
  'consultancy/postStatus',
  async (postData: PostListData<ConsultancyEntity>, thunkAPI) => {
    const response = await postConsultancies({
      projects: postData.entities,
      type: SectionType.Consultancy
    }, postData.authInfo);
    return Promise.resolve(response);
  }
);
//#endregion

// INITIAL STATE
//#region 
export const initialConsultancyState: ConsultancyState = consultancyAdapter.getInitialState(
  {
    loadingStatus: StatusEnums.Initial,
    error: null,
    sectionData: {
      id: '',
      cvId: '',
      locale: '',
      canPublish: false,
      changedBy: '',
      changedOn: '',
      type: SectionType.Consultancy,
      input: {
        type: SectionType.Consultancy,
        projects: []
      }
    }
  }
);
//#endregion

// CREATE SLICE
//#region 
export const consultancySlice = createSlice({
  name: CONSULTANCY_FEATURE_KEY,
  initialState: initialConsultancyState,
  reducers: {
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConsultancy.pending, (state: ConsultancyState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(postConsultancyInput.pending, (state: ConsultancyState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(
        fetchConsultancy.fulfilled,
        (
          state: ConsultancyState,
          action: PayloadAction<SectionData<ConsultancyInput>>
        ) => {
          // consultancyAdapter.setAll(state, action.payload.input.projects);
          state.loadingStatus = StatusEnums.Loaded;
          state.sectionData = action.payload;
        }
      )
      .addCase(
        postConsultancyInput.fulfilled,
        (
          state: ConsultancyState,
          action: PayloadAction<SectionData<ConsultancyInput>>
        ) => {
          // consultancyAdapter.setAll(state, action.payload.input.projects);
          state.loadingStatus = StatusEnums.Loaded;
          state.sectionData = action.payload;
        }
      )
      .addCase(fetchConsultancy.rejected, (state: ConsultancyState, action) => {
        if (action.error.message === '404') {
          state.loadingStatus = StatusEnums.NotFound;
          state.sectionData = initialConsultancyState.sectionData;
        } else {
          state.loadingStatus = StatusEnums.Error;
        }
        state.error = action.error.message;
      })
      .addCase(postConsultancyInput.rejected, (state: ConsultancyState, action) => {
        state.loadingStatus = StatusEnums.SubmitError;
        state.error = action.error.message;
      });
  },
});
//#endregion

// REDUCER
//#region 
/*
 * Export reducer for store configuration.
 */
export const consultancyReducer = consultancySlice.reducer;

//#endregion

// ACTIONS
//#region 
/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(consultancyActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const consultancyActions = consultancySlice.actions;

//#endregion

// SELECTORS
//#region 
/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllConsultancy);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = consultancyAdapter.getSelectors();

export const getConsultancyState = (rootState: unknown): ConsultancyState =>
  rootState[CONSULTANCY_FEATURE_KEY];

export const selectAllConsultancy = createSelector(
  getConsultancyState,
  selectAll
);

export const selectConsultancyEntities = createSelector(
  getConsultancyState,
  selectEntities
);
//#endregion

