import { withRouter } from "react-router";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from './AppInsights';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import React from "react";

interface CustomAiProviderProps {
    onInitializedCallback: () => void;
    history: any;
}
interface CustomAiProviderState {
    initialized: boolean;
}

class CustomAiProvider extends React.Component<CustomAiProviderProps, CustomAiProviderState> {
    constructor(props) {
        super(props);
        this.state = {
            initialized: false
        };
    }

    componentDidMount() {

        if (!this.state.initialized) {
            ai.initialize(this.props.history);
            this.setState({
                initialized: true
            });
            this.props.onInitializedCallback();
        }
    }

    render() {
        return (
            <AppInsightsContext.Provider value={ai.reactPlugin}>
                {this.props.children}
            </AppInsightsContext.Provider>
        );
    }
}

export default withRouter(withAITracking(ai.reactPlugin, CustomAiProvider));