interface Array<T> {
/**
 * Sorts an array in place.
 * This method mutates the array and returns a reference to the same array.
 * @param valueFactory Function used to fetch a member of the object on which the array must be sorted
 * ```ts
 * class Student {    
 *      constructor(public classNumber: number, public firstName: string, public lastName: string) { }
 * }
 * 
 * var students = [
 *   new Student(9, 'John', 'Smith'),
 *   new Student(3, 'Jane', 'Doe'),
 *   new Student(5, 'Mark', 'Spencer')
 * ];
 * 
 * students.sort(x => x.classNumber); // returns Jane, Mark, John
 * students.sort(x => x.lastName); // returns Jane, John, Mark
 *
 * ```
 */
    sortObjects(valueFactory: (obj: T) => any): Array<T>
}

if (!Array.prototype.sortObjects){ 
    Array.prototype.sortObjects = function<T>(this: T[], valueFactory: (obj: T) => any): T[] {
        return this.sort((a, b) => {
            var valueA = valueFactory(a);
            var valueB = valueFactory(b);
            if (valueA < valueB) {
                return -1;
            }
            if (valueA > valueB) {
                return 1;
            }
            
            // names must be equal
            return 0;
            });
    }
}