import BoxedComponent from 'cv-app/shared/ui/layout/boxed-component/boxed-component';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './initial-screen.module.scss';

/* eslint-disable-next-line */
export interface InitialScreenProps {}

export function InitialScreen(props: InitialScreenProps) {

  const { t } = useTranslation();
  
  return (
    <BoxedComponent>
      <h1>{t("welcome-screen__title")}</h1>
      <div>
        {t("welcome-screen__text")}
      </div>
    </BoxedComponent>
  );
}

export default InitialScreen;
