import React, { useEffect, useState } from 'react';
import PersonalInfo from 'cv-app/pages/step-personal/components/personal-info/personal-info';
import './step-personal.module.scss';
import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import { Redirect, useParams } from 'react-router';
import {
  PersonalInfoFormData,
  PersonalInformationInput
} from 'cv-app/shared/services/personal-information/personal-types';
import Modal from 'cv-app/shared/ui/molecules/modal/modal';
import { AuthInfo, useAuth } from 'cv-app/utils/api-auth-helper';
import { PostInputData, PostListData, SectionData } from 'cv-app/shared/services/sections';
import { StatusEnums } from 'cv-app/utils/status-enums';
import { FetchPhotoParameters, MeEntity } from 'cv-app/shared/redux/me/me.slice';
import { isValidOption } from 'cv-app/utils/helpers/form-helper';
import { SectionType } from 'cv-app/utils/section-types';
import { getProfilePhoto, getUserNationality, personalInputUnchanged } from 'cv-app/utils/personal/personal-helper';
import StepLayout from 'cv-app/shared/ui/layout/step-layout/step-layout';
import { useTranslation } from 'react-i18next';
import { CvSectionStateEntity } from 'cv-app/shared/redux/cvs/cvs.slice';
import { alreadyExistingCv, mapSectionToUrl } from 'cv-app/utils/helpers/cv-section-helper';
import { stringIsNullOrEmpty, removeSpace } from 'cv-app/utils/helpers/string-helper';
import Example from 'cv-app/shared/ui/components/example-component/example-component';
import Box from 'cv-app/shared/ui/layout/box/box';
import Button from 'cv-app/shared/ui/molecules/button/button';
import { getLegalEntitieTeams } from 'cv-app/shared/services/personal-information/personal-information-service';
import { getLegalEntitieIndustries } from 'cv-app/shared/services/data-service';
import { ConsultancyEntity } from 'cv-app/shared/redux/consultancy/consultancy.slice';
import { JobEntity } from 'cv-app/shared/redux/jobs/jobs.slice';
import { ConsultancyInput } from 'cv-app/shared/services/consultancy/consultancy-types';
import { OtherWorkInput } from 'cv-app/shared/services/jobs/jobs-types';
import { htmlDecode } from 'cv-app/shared/services/html-serialize/html-serialize';
import { EducationEntity } from 'cv-app/shared/redux/education/education.slice';
import { getLegalEntitiesEducationDegrees } from 'cv-app/shared/services/educations/education-service';
import { EducationInput } from 'cv-app/shared/services/educations/education-types';

export interface StepPersonalProps {
  errorMessage: string;
  loadingStatus: StatusEnums;
  meLoaded: StatusEnums;
  imageLoadingStatus: StatusEnums;
  nationalitiesLoadingStatus: StatusEnums;
  nationalities: SelectOption[];
  userData: MeEntity;
  sectionData: SectionData<PersonalInformationInput>;
  consultancySectionData: SectionData<ConsultancyInput>;
  jobsSectionData: SectionData<OtherWorkInput>;
  educationSectionData: SectionData<EducationInput>;
  entitiesLoadingStatus: StatusEnums;
  legalEntities: SelectOption[];
  loadingStatusCvStates: StatusEnums;
  loadingStatusConsultancies: StatusEnums;
  loadingStatusJobs: StatusEnums;
  cvSectionsStates: CvSectionStateEntity[];
  loadingStatusEducation: StatusEnums;
  fetchPersonal: (authInfo: AuthInfo) => void;
  fetchMe: (authInfo: AuthInfo) => void;
  fetchPhoto: (params: FetchPhotoParameters) => void;
  fetchNationalities: (authInfo: AuthInfo) => void;
  fetchLegalEntities: (authInfo: AuthInfo) => void;
  fetchCvs: (authInfo: AuthInfo) => void;
  fetchConsultancy: (authInfo: AuthInfo) => void;
  fetchJobs: (authInfo: AuthInfo) => void;
  fetchCvSectionStates: (authInfo: AuthInfo) => void;
  fetchEducation: (authInfo: AuthInfo) => void;
  postPersonalInput: (data: PostInputData<PersonalInformationInput>) => void;
  postConsultancyInput: (postData: PostListData<ConsultancyEntity>) => void;
  postJobsInput: (postData: PostListData<JobEntity>) => void;
  postEducationInput: (postData: PostListData<EducationEntity>) => void;
}

export function StepPersonal(props: StepPersonalProps) {
  /**STATES */
    //#region
  const [redirectTo, setRedirectTo] = useState('');
  const [validationOn, setValidationOn] = useState(false);
  const [canReturn, setCanReturn] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSavingErrorModal, setShowSavingErrorModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showExampleModal, setShowExampleModal] = useState(false);
  const [teamOptions, setTeamsOptions] = useState([]);
  const [legalEntityId, setLegalEntityId] = useState(undefined);
  const [prevLegalEntityId, setPrevLegalEntityId] = useState(undefined);
  const [formInput, setFormInput] = useState<PersonalInfoFormData>({
    firstName: '',
    lastName: '',
    jobTitle: '',
    description: '',
    imgSrc: '',
    nationality: '',
    teamId: -1,
    legalEntityId: -1,
    nationalityId: -1,
  });
  //#endregion

  /**HOOKS */
  const authInfo = useAuth();
  const { cvid } = useParams<{ cvid: string }>();
  const { t } = useTranslation();

  //#region Load data
  // List of nationalities
  useEffect(() => {
    if (props.nationalitiesLoadingStatus === StatusEnums.Initial) {
      props.fetchNationalities(authInfo);
    }
  }, []);
  // List of entities
  useEffect(() => {
    if (props.entitiesLoadingStatus === StatusEnums.Initial) {
      props.fetchLegalEntities(authInfo);
    }
  }, []);
  // Section data
  useEffect(() => {
    if (props.loadingStatus === StatusEnums.Initial) {
      props.fetchPersonal(authInfo);
    }
  }, []);
  // Current user data
  useEffect(() => {
    if (props.meLoaded === StatusEnums.Initial) {
      props.fetchMe(authInfo);
    }
  }, []);
  // Sections states (for the step nav)
  useEffect(() => {
    if (props.loadingStatusCvStates === StatusEnums.Initial) {
      props.fetchCvSectionStates(authInfo);
    }
  }, []);
  //#endregion

  //#region Validation of url
  useEffect(() => {
    if (props.loadingStatusCvStates === StatusEnums.Loaded) {
      if (cvid && !alreadyExistingCv(props.cvSectionsStates, cvid, 'en-US')) {
        setRedirectTo(mapSectionToUrl(SectionType.Personal));
      }
    }
    if (props.loadingStatusCvStates === StatusEnums.NotFound) {
      if (cvid) {
        setRedirectTo(mapSectionToUrl(SectionType.Personal));
      }
    }
  }, [props.loadingStatusCvStates]);
  //#endregion

  //#region Form data logic
  useEffect(() => {
    // When me data is loaded
    if (props.meLoaded === StatusEnums.Loaded &&
      // look for the encoded photo only if there is a photo url (and we don't already have the photo)
      props.imageLoadingStatus === StatusEnums.Initial && props.userData.hasPhoto) {
      props.fetchPhoto({
        photoUrl: props.userData.photoUri,
        authInfo: authInfo
      });
    }
  }, [props.meLoaded]);

  useEffect(() => {
    // When the section data is not found it means we didn't create this section yet
    // and we need to use the current user data (api/me) to pre-fill the form fields that are not editable
    if (props.loadingStatus === StatusEnums.NotFound) {
      // wait to be sure user data and nationalities are loaded
      if (
        props.meLoaded === StatusEnums.Loaded &&
        !props.userData?.noOdsUserData &&
        props.nationalitiesLoadingStatus === StatusEnums.Loaded &&
        props.entitiesLoadingStatus === StatusEnums.Loaded
      ) {
        const inputData: PersonalInfoFormData = {
          ...formInput,
          firstName: props.userData.firstName,
          lastName: props.userData.lastName,
          jobTitle: props.userData.jobTitle,
          nationality: getUserNationality(props.userData, props.nationalities),
          // legalEntity: getUserLegalEntity(props.userData, props.legalEntities),
        };
        setFormInput(inputData);
      }
    } else {
      // if we have section data, use that one
      if (
        props.loadingStatus === StatusEnums.Loaded &&
        props.nationalitiesLoadingStatus === StatusEnums.Loaded &&
        props.entitiesLoadingStatus === StatusEnums.Loaded
      ) {
        if (props.sectionData.input.legalEntityId > 0) {
          populateTeamsByLegalEntityId(props.sectionData.input.legalEntityId);
        }
        setLegalEntityId(props.sectionData.input.legalEntityId);
        setPrevLegalEntityId(props.sectionData.input.legalEntityId)
        setFormInput(props.sectionData.input);
      }
    }
  }, [props.loadingStatus, props.meLoaded, props.nationalitiesLoadingStatus, props.entitiesLoadingStatus]);
  //#endregion

  //#region Error management
  useEffect(() => {
    if (props.loadingStatus === StatusEnums.SubmitError) {
      setShowSavingErrorModal(true);
    }
  }, [props.loadingStatus]);
  //#endregion

  useEffect(() => {
    if (props.loadingStatusConsultancies === StatusEnums.Initial) {
      props.fetchConsultancy(authInfo);
    }
  }, []);

  useEffect(() => {
    if (props.loadingStatusJobs === StatusEnums.Initial) {
      props.fetchJobs(authInfo);
    }
  }, []);

  //#region On save/cancel/modal buttons
  
  const onSaveButtonClicked = async () => { 
    const newData: PersonalInformationInput = {
      type: SectionType.Personal,
      ...formInput,
      jobTitle: removeSpace(formInput.jobTitle),        
      description: removeSpace(formInput.description)
    };    
    setFormInput(newData);

    if (!validateState()) {
      setValidationOn(true);
    } else { 
      props.postPersonalInput({
        authInfo: authInfo,
        sectionInput: newData
      });

      const industries = await getLegalEntitieIndustries(authInfo, newData.legalEntityId);

      if (industries?.length && prevLegalEntityId !== legalEntityId) {
        const consultancyInput = props.consultancySectionData.input.projects.map(project => {
          return Object.assign({}, project, { industryId: Number(industries[0].optionValue) })
        });
        const workInput = props.jobsSectionData.input.otherWorkHistoryItems.map(work => {
          return Object.assign({}, work, { industryId: Number(industries[0].optionValue) })
        });

        if (consultancyInput?.length) {
          props.postConsultancyInput({
            authInfo,
            entities: consultancyInput,
          });
        }
        if (workInput?.length) {
          props.postJobsInput({
            authInfo,
            entities: workInput,
          });
        }
      }

      const degrees = await getLegalEntitiesEducationDegrees(authInfo, newData.legalEntityId);

      if(degrees?.length && prevLegalEntityId !== legalEntityId) {
        const educationInput = props.educationSectionData.input.educations.map(project => {
          return Object.assign({}, project, { educationDegreeId: Number(degrees[0].optionValue) })
        });

        if (educationInput?.length) {
          props.postEducationInput({
            authInfo,
            entities: educationInput,
          });
        }
      }
      setIsSubmitting(true);
    }
  };

  const onCancelButtonClicked = () => {
    if (personalInputUnchanged(formInput, props.sectionData.input)) {
      cancelAndReturn();
    } else {
      setShowDiscardModal(true);
    }
  };
  //#endregion

  //#region On form changes
  const handleFormChange = async (newData: PersonalInfoFormData) => {
    if (newData.legalEntityId !== legalEntityId) {
      setPrevLegalEntityId(legalEntityId);
      setLegalEntityId(newData.legalEntityId);
      try {
        const teams = await getLegalEntitieTeams(authInfo, newData.legalEntityId);
        setTeamsOptions(teams);
        newData.teamId = Number(teams[0].optionValue);
      } catch (err) {
        console.log(err);
      }
    }
    setFormInput(newData);
  };
  //#endregion

  const populateTeamsByLegalEntityId = async (legalEntityId: number) => {
    try {
      const response = await getLegalEntitieTeams(authInfo, legalEntityId);
      setTeamsOptions(response);
    } catch (err) {
      console.log(err);
    }
  };

  //#region helpers
  const validateState = () => {
    // Validate personal data
    if (formInput.firstName === ''
      || formInput.lastName === '') {
      // || formInput.nationality === "" - it can be missing it seems
      return false;
    }
    // Validate cv specific data
    if (formInput.jobTitle === '' || formInput.teamId === 0 || formInput.legalEntityId === 0) {
      return false;
    }
    if (formInput.jobTitle.length < 2 || formInput.jobTitle.length > 80){
      return false;
    }
    if (formInput.firstName.length < 2 || formInput.firstName.length > 80){
      return false;
    }
    if (formInput.lastName.length < 2 || formInput.lastName.length > 80){
      return false;
    }
    if (!isValidOption(formInput.legalEntityId.toString(), props.legalEntities)) {
      return false;
    }
    if (!isValidOption(formInput.teamId.toString(), teamOptions)) {
      return false;
    }
    if (htmlDecode(removeSpace(formInput.description)).length < 200 || htmlDecode(removeSpace(formInput.description)).length > 700) {
      return false;
    }
    return true;
  };

  const cancelAndReturn = () => {
    setCanReturn(true);
  };
  //#endregion

  //#region RENDERINGS
  // When on the wrong url
  if (!stringIsNullOrEmpty(redirectTo) && cvid) {
    return (<Redirect to={redirectTo}></Redirect>);
  }
  // On cancel
  if (canReturn) {
    return (<Redirect to={`/`}></Redirect>);
  }
  // After saving data is successful
  if (isSubmitting && props.loadingStatus === StatusEnums.Loaded) {
    // make sure cv list is updated
    props.fetchCvs(authInfo);
    // make sure the cv states are updated (for the nav)
    props.fetchCvSectionStates(authInfo);
    return (<Redirect to={`/cv/${props.sectionData.cvId}/education`}></Redirect>);
  }

  const box = <Box isLight={true} boxTitle={t(`${SectionType.Personal}__welcome-title`)}
                   boxDescription={t(`${SectionType.Personal}__welcome-description`)}>
    <Button
      buttonText={t(`${SectionType.Personal}__add-first-btn-text`)}
      btnType='primary'
      buttonCss='examples-button'
      callbackOnClick={() => {
        setShowExampleModal(true);
      }}
    />

    <Modal
      modalId='exampleModal'
      isVisible={showExampleModal}
      modalContent={<Example />}
      buttonsPosition='end'
      textCancel={t('general__close')}
      handleCancel={() => {
        setShowExampleModal(false);
      }}
    ></Modal>
  </Box>;

  // Page view
  return (
    <StepLayout
      isInitialState={props.loadingStatusCvStates === StatusEnums.Initial || props.loadingStatusCvStates === StatusEnums.Loading}
      isError={props.loadingStatus === StatusEnums.Error}
      errorMessage={props.errorMessage}
      stepKey={SectionType.Personal}
      onNextClicked={onSaveButtonClicked}
      onPrevClicked={onCancelButtonClicked}
      topInfoBox={box}
    >
      <form className='c-form'>
        <PersonalInfo
          doValidate={validationOn}
          personalInfoSectionData={formInput}
          profileImg={getProfilePhoto(props.userData, props.imageLoadingStatus)}
          dataChangeCallback={handleFormChange}
          teamOptions={teamOptions}
          legalEntities={props.legalEntities}
          nationalitites={props.nationalities}
          noOdsUserData={props.userData.noOdsUserData}
        ></PersonalInfo>
      </form>
      <Modal
        modalId='savingError'
        isVisible={showSavingErrorModal}
        modalText='Something went wrong and the data was not saved, please try again later.'
        textAccept='Ok'
        handleAccept={() => {
          setShowSavingErrorModal(false);
        }}
      ></Modal>
      <Modal
        modalId='discardChanges'
        isVisible={showDiscardModal}
        modalText='You have unsaved changes, are you sure you want to continue'
        textCancel='Stay on page'
        textAccept='Discard'
        handleAccept={() => {
          cancelAndReturn();
        }}
        handleCancel={() => {
          setShowDiscardModal(false);
        }}
      ></Modal>
    </StepLayout>
  );
  //#endregion
}

export default StepPersonal;
