import Loader from 'cv-app/shared/ui/molecules/loader/loader';
import { AuthInfo, useAuth } from 'cv-app/utils/api-auth-helper';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PostListData } from 'cv-app/shared/services/sections';
import { StatusEnums } from 'cv-app/utils/status-enums';
import { JobEntity } from 'cv-app/shared/redux/jobs/jobs.slice';
import { compareJobEntities } from 'cv-app/utils/jobs-helper/job-helper';
import OtherWorkLister from './components/other-work-lister';
import { SectionType } from 'cv-app/utils/section-types';
import { CvSectionStateEntity } from 'cv-app/shared/redux/cvs/cvs.slice';
import StepLister from 'cv-app/shared/ui/modules/step-lister/step-lister';
import { getLegalEntitieIndustries } from 'cv-app/shared/services/data-service';

/* eslint-disable-next-line */
export interface StepOtherWorkViewProps {
  errorMessage: string;
  loadingStatus: StatusEnums;
  entities: JobEntity[];
  loadingStatusCvStates: StatusEnums;
  cvSectionsStates: CvSectionStateEntity[];
  legalEntityId: number;
  loadingStatusPersonal: StatusEnums;
  fetchPersonal: (authInfo: AuthInfo) => void;
  fetchCvSectionStates: (authInfo: AuthInfo) => void;
  fetchJobs: (authInfo: AuthInfo) => void;
  postJobsInput: (postData: PostListData<JobEntity>) => void;
}

export function StepOtherWorkView(props: StepOtherWorkViewProps) {

  /**STATES */
    //#region
  const [entityToDelete, setEntityToDelete] = useState(-1);
  const [industries, setIndustries] = useState([]);
  //#endregion

  /**HOOKS */
    //#region
  const { cvid } = useParams<{ cvid: string }>();

  const authInfo = useAuth();

  useEffect(() => {
    if (props.loadingStatus === StatusEnums.Initial) {
      props.fetchPersonal(authInfo);
    }
  }, []);

  useEffect(() => {
    async function fetchIndustries() {
      if (props.loadingStatus === StatusEnums.Initial || props.loadingStatusPersonal === StatusEnums.Loaded) {
        try {
          const response = await getLegalEntitieIndustries(authInfo, props.legalEntityId);
          setIndustries(response);
        } catch (error) {
          console.log(error);
        }
      }
    }
    fetchIndustries();
  }, [props.loadingStatusPersonal]);
  //#endregion

  /**CALLBACKS */
    //#region
  const onDeleteEntityCallback = (id: number) => {
      setEntityToDelete(id);
    };
  //#endregion

  //#region RENDERING
  return (
    <StepLister
      entities={props.entities}
      cvSectionsStates={props.cvSectionsStates}
      loadingStatus={props.loadingStatus}
      loadingStatusCvStates={props.loadingStatusCvStates}
      errorMessage={props.errorMessage}
      stepKey={SectionType.OtherWork}
      entityToDelete={entityToDelete}
      setParentEntityToDelete={setEntityToDelete}
      fetchEntities={props.fetchJobs}
      fetchCvSectionStates={props.fetchCvSectionStates}
      postEntityList={props.postJobsInput}
    >
      {props.loadingStatus === StatusEnums.Loading && <Loader></Loader>}
      <OtherWorkLister
        articleDeleteCallback={onDeleteEntityCallback}
        cvId={cvid}
        entities={props.entities?.sort(compareJobEntities) || []}
        industries={industries}
      ></OtherWorkLister>
    </StepLister>
  );
  //#endregion
}

export default StepOtherWorkView;
