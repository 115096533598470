import { PostListData } from 'cv-app/shared/services/sections';
import { AuthInfo, useAuth } from 'cv-app/utils/api-auth-helper';
import { ListItem } from 'cv-app/utils/helpers/list-item-helper';
import { StatusEnums } from 'cv-app/utils/status-enums';
import React, { useEffect, useState } from 'react';
import { TrackedErrorBoundary } from 'cv-app/shared/ui/tracked-error-boundary';
import ErrorView from 'cv-app/shared/ui/molecules/error-view/error-view';
import Loader from 'cv-app/shared/ui/molecules/loader/loader';
import Modal from 'cv-app/shared/ui/molecules/modal/modal';

import './edit-article.module.scss';

/**
 * EditArticleProps
 *
 * Each section will have a different slice in the redux state tree, the following parameters should be based on that data corresponding to the TEntity type:
 * - entities: list of entities of current TEntity type. Should come from the sectionData entities, which are usually sectionData.input.projects/educations/someotherlist
 * - loadingStatus: indicates wheter the section data has been loaded. Also part of the current state
 * - errorMessage: an error text, if the loading of the section data failed. Also part of the current state
 *
 * Data from parent
 * - entityToEditId: in case of editting, this is the id of the current entity. Empty if we create a new one
 *
 * Events that are triggered in the parent form, but must be handled here, depending on the form state
 * - submitForm: true if form submit was triggered in the parent component (eg. Save button was clicked)
 * - discardForm: true if form cancel was triggered in the parent component (eg. Cancel button was clicked)
 *
 * Async thunk functions from the corresponding slice
 * - fetchEntities: called to populate the state, is not already loaded
 * - postEntityList: called to save the data in the backend, updates the state
 *
 * Entity specific functions - since we don't know how to handle different implementations of the TEntity, we get the right functions from the parent component
 * - createEmptyEntity: returns an "initial" object, data is empty, id is -1
 * - createNewEntity: similar to empty, but calculates the correct id, based on the already existing entities (generally max value + 1)
 * - isValid: checks if the TEntity is valid (called here when for is submitting)
 * - haveIdenticalData: used to check if the form data has changed (so we know to alert the user on cancel)
 * - preSubmissionHandleData: some entity types might need some adjustments before saving (eg. the endDate must be removed is the isStudying checkbox is on). This must be done in the parent component
 *
 * Callbacks to parent, depending on the state of the data after an action was taken by the user (cancel/save):
 * - cancelSubmission: when props.submitForm is true, and needs to be set back to false by the parent, we call this (eg. error in form/invalid data - otherwise the form would submit automatically as soon as valid)
 * - continueEditing: when we cannot cancel or continue (discard modal is not ok'ed by user/user wants to try again after error) -> call parent to keep editing
 * - closeForm: when the user is done with the form (cancel was accepted or data saved with success), we can trigger the part to close the form or move to the next page.
 *
 * This component is meant to be a logic wrapper around an actual editing form. The modals can be rendered by this, but we are unaware of the particular view of the form, hence:
 * - renderForm: function to render the specific form. Takes the current state formInput as data and a callback to adjust the state when changes occure in the form.
 */
export interface EditArticleProps<TEntity extends ListItem> {
  entities: TEntity[];
  loadingStatus: StatusEnums;
  errorMessage: string;
  entityToEditId?: string;
  submitForm: boolean;
  discardForm: boolean;
  fetchEntities: (authInfo: AuthInfo) => void;
  postEntityList: (postList: PostListData<TEntity>) => void;
  createEmptyEntity: () => TEntity;
  createNewEntity: (entities: TEntity[]) => TEntity;
  isValid: (formInput: TEntity) => boolean;
  haveIdenticalData: (formInput: TEntity, currentData: TEntity) => boolean;
  preSubmissionHandleData?: (formInput: TEntity) => TEntity;
  setInitialSetForInput?: (formInput: TEntity, isNew: boolean) => void;
  cancelSubmission: () => void;
  continueEditing: () => void;
  closeForm: () => void;
  renderForm: (formInput: TEntity, dataChangeCallback: (data: TEntity) => void) => React.ReactNode;
}

export function EditArticle<TEntity extends ListItem>(props: EditArticleProps<TEntity>) {
  /**STATES */
    //#region
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showSavingErrorModal, setShowSavingErrorModal] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [formInput, setFormInput] = useState<TEntity>(props.createEmptyEntity());
  //#endregion

  /**HOOKS */
    //#region
  const authInfo = useAuth();

  // Initial loading of entities (needed to post)
  useEffect(() => {
    if (props.loadingStatus === StatusEnums.Initial) {
      props.fetchEntities(authInfo);
    }
  }, []);

  // Determine if we edit or add, and set the form data
  useEffect(() => {
    if (props.loadingStatus === StatusEnums.Loaded || props.loadingStatus === StatusEnums.NotFound) {
      let currentEntity: TEntity;
      if (props.entityToEditId) {
        currentEntity = getCurrentEntity(props.entities, props.entityToEditId);
        if (!currentEntity) {
          setNotFound(true);
          return;
        }
        if (props.setInitialSetForInput) {
          props.setInitialSetForInput(currentEntity, false);
        }
      } else {
        currentEntity = props.createNewEntity(props.entities);
        if (props.setInitialSetForInput) {
          props.setInitialSetForInput(currentEntity, true);
        }
      }

      setFormInput(currentEntity);
    }
  }, [props.loadingStatus, props.entityToEditId]);

  // When submission was triggered
  useEffect(() => {
    if (props.submitForm) {
      if (props.isValid(formInput)) {
        trySubmitForm();
      } else {
        props.cancelSubmission();
      }
    }
  }, [props.submitForm]);

  // When cancel was triggered
  useEffect(() => {
    if (props.discardForm) {
      const currentData = props.entityToEditId ?
        getCurrentEntity(props.entities, props.entityToEditId) : props.createEmptyEntity();
      if (props.haveIdenticalData(formInput, currentData)) {
        props.closeForm();
      } else {
        setShowDiscardModal(true);
      }
    }
  }, [props.discardForm]);

  // Error handle
  useEffect(() => {
    if (props.loadingStatus === StatusEnums.SubmitError) {
      setShowSavingErrorModal(true);
      props.cancelSubmission();
    }
  }, [props.loadingStatus]);

  // On submission ok - TODO: will we ever get false positives on this?
  useEffect(() => {
    if (props.loadingStatus === StatusEnums.Loaded && props.submitForm) {
      props.closeForm();
    }
  }, [props.loadingStatus]);

  //#endregion

  /**CALLBACKS */
    //#region
  const dataChangeCallback = (data: TEntity) => {
      setFormInput(data);
    };
  const keepEditing = () => {
    props.continueEditing();
    setShowDiscardModal(false);
  };

  //#endregion

  /**Helpers */
  //#region
  function getCurrentEntity(entities: TEntity[], entityId: string) {
    return entities.find(entity => {
      return entity.id.toString() === entityId;
    });
  }

  function trySubmitForm() {
    const data = props.preSubmissionHandleData ? props.preSubmissionHandleData(formInput) : formInput;
    const entities: TEntity[] = [];
    props.entities?.forEach(element => {
      entities.push(element);
    });
    const indexOf = entities.findIndex(x => x.id === data.id);
    if (indexOf === -1) {
      entities.push(data);
    } else {
      entities[indexOf] = data;
    }
    props.postEntityList({
      authInfo: authInfo,
      entities: entities
    });
  }

  //#endregion

  if (props.loadingStatus === StatusEnums.Initial) {
    return (<Loader></Loader>);
  }

  return (
    <TrackedErrorBoundary>
      {props.loadingStatus === StatusEnums.Loading && <Loader></Loader>}
      {!notFound &&
        <React.Fragment>
          {props.renderForm(formInput, dataChangeCallback)}
          <Modal
            modalId='savingError'
            isVisible={showSavingErrorModal}
            modalText='Something went wrong, please try again later!'
            textCancel='Stay on page'
            textAccept='Back to overview'
            handleAccept={() => {
              props.closeForm();
            }}
            handleCancel={() => {
              setShowSavingErrorModal(false);
            }}
          ></Modal>
          <Modal
            modalId='discardChanges'
            isVisible={showDiscardModal}
            modalText='You have unsaved changes, are you sure you want to continue'
            textCancel='Stay on page'
            textAccept='Discard'
            handleAccept={() => {
              props.closeForm();
            }}
            handleCancel={keepEditing}
          ></Modal>
        </React.Fragment>
      }
      {notFound &&
        <ErrorView
          errorMessage={`Could not find the article you are trying to edit (id: ${props.entityToEditId})`}></ErrorView>}
    </TrackedErrorBoundary>
  );
}

export default EditArticle;
