import { AuthInfo, fetchJson } from "cv-app/utils/api-auth-helper";
import { SelectOption } from "../ui/molecules/form-select/form-select";
import { EducationDegree } from "./educations/education-types";
// GET nationalities
//#region
interface Nationality {
    id: number;
    name: string;
}

const cachingKey = 'nationalities';
function getNationalitiesFromApi(authInfo: AuthInfo): Promise<SelectOption[]> {
    return new Promise((resolve) => {
        fetchJson<Nationality[]>('/api/nationalities', authInfo)
            .then((data) => {
                const selectOption: SelectOption[] = data.map(d => {
                  return {
                      optionName: d.name,
                      optionValue: d.id.toString()
                  }
                }).sort((a, b) => {
                  if (a.optionName < b.optionName) {
                    return -1;
                  }
                  if (a.optionName > b.optionName) {
                    return 1;
                  }
                  return 0;
                });
                sessionStorage.setItem(cachingKey, JSON.stringify(selectOption));
                resolve(selectOption);
            })
    })
}
export function getNationalities(authInfo: AuthInfo): Promise<SelectOption[]> {
    const nationality = JSON.parse(sessionStorage.getItem(cachingKey));
    if (nationality) {
        return new Promise((resolve) => {
            resolve(nationality);
        })
    }
    // If nothing is cached in the session, get from API
    return getNationalitiesFromApi(authInfo);
}
//#endregion

// GET legalentities
//#region
const entitiesCachingKey = 'legalentities';
function getEntitiesFromApi(authInfo: AuthInfo): Promise<SelectOption[]> {
    return new Promise((resolve, reject) => {
        fetchJson<SelectOption[]>('/api/legalentities', authInfo)
            .then((data) => {
              sessionStorage.setItem(entitiesCachingKey, JSON.stringify(data));
              resolve(data);
            })
    })
}
export function getEntities(authInfo: AuthInfo): Promise<SelectOption[]> {
    const entitie = JSON.parse(sessionStorage.getItem(entitiesCachingKey));

    if (entitie) {
        return new Promise((resolve) => {
            resolve(entitie);
        })
    }
    // If nothing is cached in the session, get from API
    return getEntitiesFromApi(authInfo);
}
//#endregion


// GET for the degree
//#region
const degreeCachingKey = 'degree-datasourse';
function getDegreesFromApi(authInfo: AuthInfo)
    : Promise<SelectOption[]> {
    return new Promise((resolve, reject) => {
        fetchJson<EducationDegree[]>('/api/educationdegrees', authInfo)
            .then((degreeData) => {
                const degreeSelectOption: SelectOption[] = degreeData.map(d => {
                    return {
                        optionName: d.name,
                        optionValue: d.id.toString()
                    }
                })
                sessionStorage.setItem(degreeCachingKey, JSON.stringify(degreeSelectOption));
                resolve(degreeSelectOption);
            })
    })
}
export function getDegrees(authInfo: AuthInfo): Promise<SelectOption[]> {
    const degrees = JSON.parse(sessionStorage.getItem(degreeCachingKey));
    if (degrees) {
        return new Promise((resolve, reject) => {
            resolve(degrees);
        })
    }
    // If nothing is cached in the session, get from API
    return getDegreesFromApi(authInfo);
}
//#endregion

// GET industries
//#region
interface Industry {
    id: number;
    code: string;
    name: string;
}

const industriesCachingKey = 'industries';
function getIndustriesFromApi(authInfo: AuthInfo): Promise<SelectOption[]> {
    return new Promise((resolve) => {
        fetchJson<Industry[]>('/api/industries', authInfo)
            .then((data) => {
                const selectOption: SelectOption[] = data.map(d => {
                    return {
                        optionName: d.name,
                        optionValue: d.id.toString()
                    }
                });
                selectOption.sortObjects(x => x.optionName.toUpperCase());
                sessionStorage.setItem(industriesCachingKey, JSON.stringify(selectOption));
                resolve(selectOption);
            })
    })
}
export function getIndustries(authInfo: AuthInfo): Promise<SelectOption[]> {
    const industries = JSON.parse(sessionStorage.getItem(industriesCachingKey));
    if (industries) {
        return new Promise((resolve) => {
            resolve(industries);
        })
    }
    // If nothing is cached in the session, get from API
    return getIndustriesFromApi(authInfo);
}
//#endregion

export function getLegalEntitieIndustries(authInfo: AuthInfo, legalEntityId: number | string): Promise<SelectOption[]> {
  return new Promise((resolve) => {
      fetchJson<Industry[]>(`/api/industries/${legalEntityId}`, authInfo)
          .then((data) => {
              const selectOption: SelectOption[] = data.map(d => {
                  return {
                      optionName: d.name,
                      optionValue: d.id.toString()
                  }
              });
              selectOption.sortObjects(x => x.optionName.toUpperCase());
              resolve(selectOption);
          })
  })
}
