import { NotRecuiredSectionsToLegalEntityIds } from "cv-app/shared/constants/not-required-sections";
import { CvSectionStateEntity } from "cv-app/shared/redux/cvs/cvs.slice";
import { SectionType } from "../section-types";
import { stringIsNullOrEmpty } from "./string-helper";

//#region Sections order

// Create array of section types in the correct order, to be used for different purposes
function createSectionTypeArray() {
    const sectionTypesArray: SectionType[] = [
        SectionType.Personal,
        SectionType.Education,
        SectionType.Certificates,
        SectionType.Language,
        SectionType.Consultancy,
        SectionType.OtherWork
    ];
    return sectionTypesArray;
}

function getPreviousSection(section: SectionType) {
    const sections = createSectionTypeArray();
    const indexOf = sections.findIndex(x => x === section);
    if (indexOf === -1 || indexOf === 0)
        return null;
    return sections[indexOf - 1];
}
function getNextSection(section: SectionType) {
    const sections = createSectionTypeArray();
    const indexOf = sections.findIndex(x => x === section);
    if (indexOf === -1 || indexOf === (sections.length - 1))
        return null;
    return sections[indexOf + 1];
}
//#endregion

//#region Section state
// add sectionStateEntity to map, with sectionType as key
function addSectionType(sectionType: SectionType, map: Map<SectionType, CvSectionStateEntity>, filteredSectionStates: CvSectionStateEntity[]) {
    const sectionState = filteredSectionStates.find(x => x.type === sectionType)
    map.set(sectionType, sectionState);
}

/**
 * Filter all CvSectionStateEntites for current cv (id and locale) and maps them by SectionType (in steps order)
 * @param sectionData array of @type CvSectionStateEntity
 * @param cvId cvId to filter on
 * @param locale locale to filter on
 * @returns A map of CvSectionStateEntity values for the given cvId and locale, ordered by SectionType as keys
 */
export function mapNavigationFromSectionData(sectionData: CvSectionStateEntity[], cvId: string, locale: string) {
    const filteredByCvVariant = sectionData.filter((item) => {
        return item.cvId === cvId && item.locale === locale;
    });
    const map = new Map<SectionType, CvSectionStateEntity>();
    createSectionTypeArray().forEach((sType) => {
        addSectionType(sType, map, filteredByCvVariant);
    });
    return map;
}

/**
 * Checks if given cv (id & locale), already has a personal section (personal is mandatory)
 * @param sectionData array of @type CvSectionStateEntity
 * @param cvId cvId to filter on
 * @param locale locale to filter on
 * @returns true is a personal section exists for this cv, false otherwise
 */
export function alreadyExistingCv(sectionData: CvSectionStateEntity[], cvId: string, locale: string) {
    const personalSection = sectionData.filter((item) => {
        return item.cvId === cvId && item.locale === locale && item.type === SectionType.Personal;
    })
    return personalSection.length > 0;
}

function getFirstIncompleteSection(map: Map<SectionType, CvSectionStateEntity>, legalEntityId: number): SectionType {
    let section = SectionType.Personal;
    let foundIncompleteSection = false;
    map.forEach((v, k) => {
      if (!foundIncompleteSection && !isCompleted(v) && !NotRecuiredSectionsToLegalEntityIds[k]?.find((entityId) => entityId === legalEntityId)) {
          section = k;
          foundIncompleteSection = true;
      }
    });
    return section;
}
/**
 * Determines url to navigate to when editing cv
 * @param map of @type CvSectionStateEntity mapped on SectionType
 * @returns url of first incomplete section, or Personal section if all are complete
 */
export function getContinueEditingUrl(map: Map<SectionType, CvSectionStateEntity>, cvId: string, legalEntityId: number) {
    const firstStepToGoTo = getFirstIncompleteSection(map, legalEntityId);
    const url = mapSectionToUrl(firstStepToGoTo, cvId);
    return url;
}
//#endregion


/**
 * Determines if section data is ready to be published
 * @param sectionData section data to check
 * @returns true if not null and can publish
 */
export function isCompleted(sectionData: CvSectionStateEntity) {
    if (sectionData && sectionData.canPublish)
        return true;
    return false;
}



//#region Section icons
export function getSvgForNav(sectionType: SectionType, sectionData: CvSectionStateEntity, isCurrent: boolean) {
    if (!isCurrent && isCompleted(sectionData))
        return "icon-tick-negative";
    if (sectionData)
        sectionType = sectionData.type; // use parameter just in case the section data is null
    const icon = mapSectionToSvgIcon(sectionType);
    if (isCurrent)
        return `${icon}-negative`;
    return icon;
}
function mapSectionToSvgIcon(sectionType: SectionType) {
    switch (sectionType) {
        case SectionType.Personal: {
            return "icon-people"
        }
        case SectionType.Education: {
            return "icon-education"
        }
        case SectionType.Certificates: {
            return "icon-star"
        }
        case SectionType.Language: {
            return "icon-think"
        }
        case SectionType.Consultancy: {
            return "icon-briefcase"
        }
        case SectionType.OtherWork: {
            return "icon-layers"
        }
    }
}
//#endregion

//#region Navigation logic
export function mapSectionToUrl(sectionType: SectionType, cvId?: string) {
    if (stringIsNullOrEmpty(cvId)) {
        return "/cv/personal"
    }
    switch (sectionType) {
        case SectionType.Personal: {
            return `/cv/${cvId}/personal`
        }
        case SectionType.Education: {
            return `/cv/${cvId}/education`
        }
        case SectionType.Certificates: {
            return `/cv/${cvId}/certifications`
        }
        case SectionType.Language: {
            return `/cv/${cvId}/skills-and-languages`
        }
        case SectionType.Consultancy: {
            return `/cv/${cvId}/work-history/consultancy`
        }
        case SectionType.OtherWork: {
            return `/cv/${cvId}/work-history/other`
        }
        default: {
            return "/"
        }
    }
}
export function getPreviousUrlForSection(sectiontype: SectionType, cvId?: string) {
    if (sectiontype === SectionType.Personal) {
        return "/";
    }
    const prevStep = getPreviousSection(sectiontype);
    return mapSectionToUrl(prevStep, cvId);
}
export function getNextUrlForSection(sectiontype: SectionType, cvId?: string) {
    if (sectiontype === SectionType.OtherWork) {
        return "/";
    }
    const nextStep = getNextSection(sectiontype);
    return mapSectionToUrl(nextStep, cvId);
}

export function getAddNewLinkForSection(sectionType: SectionType, cvId: string) {
    if (sectionType === SectionType.Education
        || sectionType === SectionType.Consultancy
        || sectionType === SectionType.OtherWork) {
        return `${mapSectionToUrl(sectionType, cvId)}/add`
    }
    // For now all other sections are in page editing
    return null;
}
//#endregion


