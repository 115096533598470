import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getIndustries } from 'cv-app/shared/services/data-service';
import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import { AuthInfo } from 'cv-app/utils/api-auth-helper';
import { StatusEnums } from 'cv-app/utils/status-enums';

export const INDUSTRIES_FEATURE_KEY = 'industries';

/*
 * Update these interfaces according to your requirements.
 */

export interface IndustriesState extends EntityState<SelectOption> {
  loadingStatus: StatusEnums;
  error: string;
  industries: SelectOption[];
}

export const industriesAdapter = createEntityAdapter<SelectOption>();

export const fetchIndustries = createAsyncThunk(
  'industries/fetchStatus',
  async (authInfo: AuthInfo, thunkAPI) => {
    const response = getIndustries(authInfo);
    return Promise.resolve(response);
  }
);

export const initialIndustriesState: IndustriesState = industriesAdapter.getInitialState({
  loadingStatus: StatusEnums.Initial,
  error: null,
  industries: []
});

export const industriesSlice = createSlice({
  name: INDUSTRIES_FEATURE_KEY,
  initialState: initialIndustriesState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIndustries.pending, (state: IndustriesState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(
        fetchIndustries.fulfilled,
        (state: IndustriesState, action: PayloadAction<SelectOption[]>) => {
          state.industries = action.payload;
          state.loadingStatus = StatusEnums.Loaded;
        }
      )
      .addCase(fetchIndustries.rejected, (state: IndustriesState, action) => {
        state.loadingStatus = StatusEnums.Error;
        state.error = action.error.message;
      });
  },
});

/*
 * Export reducer for store configuration.
 */
export const industriesReducer = industriesSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(teamsActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const industriesActions = industriesSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllTeams);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = industriesAdapter.getSelectors();

export const getIndustriesState = (rootState: unknown): IndustriesState =>
  rootState[INDUSTRIES_FEATURE_KEY];

export const selectAllIndustries = createSelector(getIndustriesState, selectAll);

export const selectIndustriessEntities = createSelector(
  getIndustriesState,
  selectEntities
);
