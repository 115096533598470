import React from 'react';
import Button from '../../molecules/button/button';

import './form-nav-buttons.module.scss';

/* eslint-disable-next-line */
export interface FormNavButtonsProps {
  saveText: string;
  onSaveClickedCallback: () => void;
  cancelText?: string;
  onCancelClickedCallback: () => void;
}

export function FormNavButtons(props: FormNavButtonsProps) {
  return (
    <div className="o-flex o-flex--justify-content-end">
      {(props.cancelText && props.onCancelClickedCallback) &&
        <Button
          buttonText={props.cancelText}
          btnType='secondary'
          callbackOnClick={props.onCancelClickedCallback}
        ></Button>}
      <Button
        buttonText={props.saveText}
        btnType='primary'
        callbackOnClick={props.onSaveClickedCallback}
      ></Button>
    </div>
  );
}

export default FormNavButtons;
