import { CertificateEntity } from 'cv-app/shared/redux/certificate/certificate.slice';
import DateInterval from 'cv-app/shared/ui/components/date-interval/date-interval';
import Box from 'cv-app/shared/ui/layout/box/box';
import Button from 'cv-app/shared/ui/molecules/button/button';
import { CheckBox } from 'cv-app/shared/ui/molecules/check-box/check-box';
import FormInput from 'cv-app/shared/ui/molecules/form-input/form-input';
import { SectionType } from 'cv-app/utils/section-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';


/* eslint-disable-next-line */

const MIN_DATE = '1950-01-01'

export interface CertificateItemProps {
  doValidate: boolean;
  certificateData: CertificateEntity;
  isNew: boolean;
  cancelCallback: () => void;
  saveButtonCallback: () => void;
  dataChangeCallback: (data: CertificateEntity) => void;
}

export function CertificateEdit(props: CertificateItemProps) {
    const { t } = useTranslation();
    const [neverExpires, setNeverExpires] = useState(!props.certificateData.endDate);
    const [isMaxLengthName, setIsMaxLengthName] = useState(false);
    const [isMaxLengthIssued, setIsMaxLengthIssued] = useState(false);

    /**CALLBACKS */
    //#region
    const handleNameChange = (newValue: string) => {
      if (newValue.length > 80) {
        setIsMaxLengthName(true);
      } else {
        setIsMaxLengthName(false);
      }
      const data: CertificateEntity = {
        ...props.certificateData,
        name: newValue
      }
      props.dataChangeCallback(data);
    }
    const handleStartDateChange = (newValue: string) => {
      const data: CertificateEntity = {
        ...props.certificateData,
        startDate: newValue
      }
      props.dataChangeCallback(data);
    }
    const handleEndDateChange = (newValue: string) => {
      const data: CertificateEntity = {
        ...props.certificateData,
        endDate: newValue
      }
      props.dataChangeCallback(data);
    }
    const handleNeverExpiresChange = () => {
      setNeverExpires(!neverExpires);
      handleEndDateChange(neverExpires ? new Date().toISOString() : null);
    }
    const handleIssuedByChange = (newValue: string) => {      
      if (newValue.length > 80) {
        setIsMaxLengthIssued(true);
      } else {
        setIsMaxLengthIssued(false);
      }
      const data: CertificateEntity = {
        ...props.certificateData,
        issuedBy: newValue
      }
      props.dataChangeCallback(data);
    }
    //#endregion
    return (
      <Box cssClass="c-certificate-card" isBordered={true}>
          <form className="c-form">
            <FormInput
              isRequired={true}
              type="input"
              doValidate={props.doValidate}
              onChangeCallback={handleNameChange}
              inputValue={props.certificateData.name}
              inputId="certificate-name"
              labelText={t("certificates__lbl-name")}
              errorText={t("certificates__lbl-nameError")}
              minLength={2}
              maxLenght={80}
              maxLengthErrorText={t("limit_reached") + " (" + props.certificateData.name.length + t("characters") + ")"}            
              isMaxLengthError={isMaxLengthName}
            ></FormInput>
            <div className="c-form__input-grid">
                <DateInterval
                  doValidate={props.doValidate}
                  componentKey={SectionType.Certificates}
                  startDate={props.certificateData.startDate}
                  endDate={props.certificateData.endDate}
                  requireEndDate={!neverExpires}
                  showEndDate={!neverExpires}
                  onStartDateChanged={handleStartDateChange}
                  onEndDateChanged={handleEndDateChange}
                  minDate={MIN_DATE}
                ></DateInterval>
                </div>
                <CheckBox
                  inputId="certificate-never-expires"
                  isChecked={neverExpires}
                  labelText={t("certificates__lbl-neverExpires")}
                  changeCallback={handleNeverExpiresChange}
                ></CheckBox>
                <FormInput
                    isRequired={false}
                    type="input"
                    doValidate={props.doValidate}
                    onChangeCallback={handleIssuedByChange}
                    inputValue={props.certificateData.issuedBy}
                    inputId="issued-by"
                    labelText={t("certificates__lbl-issuedBy")}
                    placeholder={t("certificates__placeholder-issuedBy")}                    
                    maxLenght={80}
                    maxLengthErrorText={t("limit_reached") + " (" + props.certificateData.issuedBy.length + t("characters") + ")"}             
                    isMaxLengthError={isMaxLengthIssued}
                ></FormInput>
          </form>

      <div className="c-certificate-card__button-wrapper">
        <Button
          callbackOnClick={props.cancelCallback}
          buttonText={t("general__btn-cancel")}
        ></Button>
        <div className="u-padding-left"></div>
        <Button
          callbackOnClick={props.saveButtonCallback}
          buttonText={`${props.isNew ? t("general__btn-save") : t("general__btn-update")}`}
          btnType="primary"
        ></Button>
      </div>
    </Box>
  );

}

export default CertificateEdit;
