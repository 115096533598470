import { CvSectionStateEntity } from 'cv-app/shared/redux/cvs/cvs.slice';
import { PostListData } from 'cv-app/shared/services/sections';
import { AuthInfo, useAuth } from 'cv-app/utils/api-auth-helper';
import { getNextUrlForSection, getPreviousUrlForSection } from 'cv-app/utils/helpers/cv-section-helper';
import { ListItem } from 'cv-app/utils/helpers/list-item-helper';
import { SectionType } from 'cv-app/utils/section-types';
import { StatusEnums } from 'cv-app/utils/status-enums';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import Modal from '../../molecules/modal/modal';
import StepBase from '../step-base/step-base';

import './step-lister.module.scss';

/* eslint-disable-next-line */
export interface StepListerProps<TEntity extends ListItem> {
  entities: TEntity[];
  cvSectionsStates: CvSectionStateEntity[];
  loadingStatus: StatusEnums;
  loadingStatusCvStates: StatusEnums;
  errorMessage: string;
  stepKey: SectionType;
  entityToDelete: number;
  setParentEntityToDelete: (number) => void;
  fetchEntities: (authInfo: AuthInfo) => void;
  fetchCvSectionStates: (authInfo: AuthInfo) => void;
  postEntityList: (postList: PostListData<TEntity>) => void;
}

export function StepLister<TEntity extends ListItem>(props: React.PropsWithChildren<StepListerProps<TEntity>>) {
  /**STATES */
    //#region

  const [canContinue, setCanContinue] = useState(false);
  const [canReturn, setCanReturn] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [entityToDelete, setEntityToDelete] = useState(-1);
  const [showDeleteErrorModal, setShowDeleteErrorModal] = useState(false);
  //#endregion

  //#region HOOKS
  const authInfo = useAuth();
  const { t, i18n } = useTranslation();
  const { cvid } = useParams<{ cvid: string }>();

  // Initial loading of entities
  useEffect(() => {
    if (props.loadingStatus === StatusEnums.Initial) {
      props.fetchEntities(authInfo);
    }
  }, []);

  // When next clicked (isSaving), wait for data refresh, then move on
  useEffect(() => {
    if (isSaving) {
      if (props.loadingStatus === StatusEnums.Loaded) {
        // Update section states, so the nav states are correct on the next page
        props.fetchCvSectionStates(authInfo);
        setCanContinue(true);
      }
      if (props.loadingStatus === StatusEnums.SubmitError) {
        // TODO what should we do in this case?
      }
    }
  }, [props.loadingStatus]);

  // When deleting is triggered in parent
  useEffect(() => {
    setEntityToDelete(props.entityToDelete);
  }, [props.entityToDelete]);

  // In case of delete error
  useEffect(() => {
    if (isDeleting) {
      if (props.loadingStatus === StatusEnums.SubmitError) {
        setShowDeleteErrorModal(true);
      } else {
        if (props.loadingStatus === StatusEnums.Loaded) {
          setIsDeleting(false);
          setEntityToDelete(-1);
        }
      }
    }
  }, [props.loadingStatus]);
  //#endregion

  //#region CALLBACKS
  const onPrevClickedCallback = () => {
    setCanReturn(true);
  };
  const onNextClickedCallback = () => {
    setIsSaving(true);
    // Do a post to make sure section is created (even if empty)
    props.postEntityList({
      authInfo: authInfo,
      entities: props.entities
    });
  };
  const onDeleteConfirmedCallback = () => {
    props.postEntityList({
      authInfo: authInfo,
      entities: props.entities.filter(x => x.id !== props.entityToDelete)
    });
    setIsDeleting(true);
  };
  const onDeleteCancelledCallback = () => {
    setEntityToDelete(-1);
    props.setParentEntityToDelete(-1);
    setIsDeleting(false);
  };
  //#endregion
  //#region Redirecting
  // On cancel
  if (canReturn) {
    return (<Redirect to={getPreviousUrlForSection(props.stepKey, cvid)}></Redirect>);
  }
  // On going next
  if (canContinue) {
    return (<Redirect to={getNextUrlForSection(props.stepKey, cvid)}></Redirect>);
  }
  //#endregion

  //#region RENDERING
  return (
    <StepBase
      isInitialState={props.loadingStatus === StatusEnums.Initial}
      isError={props.loadingStatus === StatusEnums.Error}
      errorMessage={props.errorMessage}
      stepKey={props.stepKey}
      onNextClicked={onNextClickedCallback}
      onPrevClicked={onPrevClickedCallback}
      loadingStatusCvStates={props.loadingStatusCvStates}
      cvSectionsStates={props.cvSectionsStates}
      fetchCvSectionStates={props.fetchCvSectionStates}
    >
      {props.children}
      <Modal
        modalId='deleteConfirm'
        isVisible={entityToDelete !== -1}
        modalText={t(`${props.stepKey}__delete-confirm`)}
        textCancel={t('general__btn-cancel')}
        textAccept={t('general__btn-delete')}
        handleAccept={onDeleteConfirmedCallback}
        handleCancel={onDeleteCancelledCallback}
      ></Modal>
      <Modal
        modalId='deleteError'
        isVisible={showDeleteErrorModal}
        modalText={t(`${props.stepKey}__delete-error`)}
        textAccept={t('general__btn-ok')}
        handleAccept={() => {
          setShowDeleteErrorModal(false);
        }}
      ></Modal>
    </StepBase>
  );
  //#endregion
}

export default StepLister;
