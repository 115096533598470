import { SectionType } from 'cv-app/utils/section-types';
import React from 'react';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useTranslation } from 'react-i18next';
import FormNavButtons from 'cv-app/shared/ui/components/form-nav-buttons/form-nav-buttons';
import Box from 'cv-app/shared/ui/layout/box/box';
import Section from 'cv-app/shared/ui/layout/section/section';
import StepNavContainer from 'cv-app/shared/ui/layout/step-layout/containers/step-nav-container';
import ErrorView from 'cv-app/shared/ui/molecules/error-view/error-view';
import Loader from 'cv-app/shared/ui/molecules/loader/loader';

import './step-layout.module.scss';
import TrackedErrorBoundary from 'cv-app/shared/ui/tracked-error-boundary';

/* eslint-disable-next-line */
export interface StepLayoutProps {
  isInitialState: boolean;
  isError: boolean;
  errorMessage: string;
  stepKey: SectionType;
  nextBtnText?: string;
  prevBtnText?: string;
  onNextClicked: () => void;
  onPrevClicked: () => void;
  topInfoBox?: JSX.Element;
}



export function StepLayout(props: React.PropsWithChildren<StepLayoutProps>) {

  //#region HOOKS
  const { t, i18n } = useTranslation();
  const appInsights = useAppInsightsContext();
  //#endregion

  //#region RENDERING
  // On error
  if (props.isError) {
    appInsights.trackException({ error: new Error(props.stepKey + ":" + props.errorMessage)});
    return (<ErrorView errorMessage={props.errorMessage}></ErrorView>)
  }

  // Initial view
  if (props.isInitialState) {
    return (<Loader></Loader>)
  }

  appInsights.trackEvent({ name: `Step: ${props.stepKey}`})
  return (
    <TrackedErrorBoundary>
      <div className="o-step">
        <Section>
          <div className="o-step__grid">
            <nav className="o-step__nav u-hide-until-tablet">
              <StepNavContainer
                currentStep={props.stepKey}
              ></StepNavContainer>
            </nav>
            <div className="o-step__main o-hidden">
              {!!(props.topInfoBox) && <>{props.topInfoBox}</>}
              <Box isLight={true} boxTitle={t(`${props.stepKey}__title`)} boxDescription={t(`${props.stepKey}__description`)}>
                {props.children}
              </Box>
            </div>
          </div>
        </Section>
        <Section>
          <FormNavButtons
            saveText={props.nextBtnText ? t(props.nextBtnText) : t(`${props.stepKey}__btn-next`)}
            cancelText={props.prevBtnText ? t(props.prevBtnText) : t(`${props.stepKey}__btn-back`)}
            onSaveClickedCallback={props.onNextClicked}
            onCancelClickedCallback={props.onPrevClicked}
          ></FormNavButtons>
        </Section>
      </div>
    </TrackedErrorBoundary>
  );
  //#endregion
}

export default StepLayout;
