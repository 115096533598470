import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';

// import logo from 'cv-assets/images/delaware-logo_white.png';
import logo from '../../../../../../src/assets/images/delaware-logo_white.png'; // TODO: despite the fact that the path above works just fine, the tests cannot resolve it.
import lang from '../../../../../../src/assets/images/lang.svg';
import './header.module.scss';
import { useTranslation } from 'react-i18next';
import { getUserLocaleAndCountry } from 'cv-app/utils/helpers/user-language-helper';

const languageList = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'German' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'zh', label: 'Chinese' },
];

export function Header() {

  const { i18n, t } = useTranslation();

  const [selectedLanguage, setSelectedLanguage] = useState(languageList[0]);

  const onClickLanguageChange = useCallback(({ value, label }: { value: string, label?: string }) => {
    setSelectedLanguage({ value, label });
    i18n.changeLanguage(value); //change the language
  }, [i18n]);

  useEffect(() => {
    const { locale } = getUserLocaleAndCountry();
    const isAllowedLanguage = languageList.some(lang => lang.value === locale);
    if (!isAllowedLanguage) return;
    i18n.changeLanguage(locale);
    setSelectedLanguage(languageList.find(value => value.value === locale));
  }, [i18n]);

  return (
    <header className='c-header' id='header'>
      <div className='c-header__wrapper'>
        <div className='c-header__logo'>
          <a className='c-header__logo-link' href="/" aria-label='Logo'>
            <img src={logo} alt='' />
          </a>
        </div>
        <div className='c-header__title'>
          <div className='c-header__title-left'>CV4U</div>
          <a href='https://cv4u-search.delaware.pro/' target='_blank' rel='noreferrer' className='c-header__link'>
            {t('link__search-app')}
          </a>
          <a href='https://delawareconsulting.sharepoint.com/sites/Inside-dbesales/SitePages/CV4U-FAQ.aspx?Mode=Edit' target='_blank' rel='noreferrer' className='c-header__link'>
            {t('link__faq')}
          </a>
        </div>

        <div className='c-header__lang'>
          <img className='c-header__lang-svg' src={lang} alt='' />
          <form className='c-form'>
            <div className='c-form__section'>
              <Select
                onChange={onClickLanguageChange}
                options={languageList}
                value={selectedLanguage}
                classNamePrefix={'language-selector'}
                isSearchable={false}
              />
            </div>
          </form>
        </div>

      </div>
    </header>
  );
}

export default Header;
