import React, { useEffect, useState } from 'react';
import CertificatesLister from 'cv-app/pages/step-certificates/components/certificates-lister/certificates-lister';

import './step-certificates.module.scss';
import { StatusEnums } from 'cv-app/utils/status-enums';
import { CertificatesInput } from 'cv-app/shared/services/certificates/certificates-types';
import { PostListData, SectionData } from 'cv-app/shared/services/sections';
import { CertificateEntity } from 'cv-app/shared/redux/certificate/certificate.slice';
import { AuthInfo, useAuth } from 'cv-app/utils/api-auth-helper';
import Loader from 'cv-app/shared/ui/molecules/loader/loader';
import Modal from 'cv-app/shared/ui/molecules/modal/modal';
import CertificateEdit from './components/certificate-edit/certificate-edit';
import {
  createEmptyCertification,
  createNewCertification,
  validateData
} from 'cv-app/utils/certifications/certification-helper';
import { SectionType } from 'cv-app/utils/section-types';
import { CvSectionStateEntity } from 'cv-app/shared/redux/cvs/cvs.slice';
import StepLister from 'cv-app/shared/ui/modules/step-lister/step-lister';
import AddButton from 'cv-app/shared/ui/components/add-btn/add-btn';
import { removeSpace } from 'cv-app/utils/helpers/string-helper';

/* eslint-disable-next-line */
export interface StepCertificatesProps {
  errorMessage: string;
  loadingStatus: StatusEnums;
  sectionData: SectionData<CertificatesInput>;
  certificates: CertificateEntity[];
  loadingStatusCvStates: StatusEnums;
  cvSectionsStates: CvSectionStateEntity[];
  fetchCvSectionStates: (authInfo: AuthInfo) => void;
  fetchCertificate: (authInfo: AuthInfo) => void;
  postCertificateInput: (postData: PostListData<CertificateEntity>) => void;
}

export function StepCertificates(props: StepCertificatesProps) {
  /**STATES */
    //#region
  const [entityToDelete, setEntityToDelete] = useState(-1);
  const [formInput, setFormInput] = useState<CertificateEntity>(createEmptyCertification());
  const [isEditing, setIsEditing] = useState(false);
  const [validationOn, setValidationOn] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [showSavingErrorModal, setShowSavingErrorModal] = useState(false);
  //#endregion

  /**HOOKS */
    //#region
  const authInfo = useAuth();
  useEffect(() => {
    if (props.loadingStatus === StatusEnums.Loaded && isSubmitting) {
      setIsEditing(false);
      setIsCreatingNew(false);
    }
  }, [props.loadingStatus]);

  useEffect(() => {
    if (props.loadingStatus === StatusEnums.SubmitError && isSubmitting) {
      setShowSavingErrorModal(true);
    }
  }, [props.loadingStatus]);
  //#endregion

  /**CALLBACKS */
    //#region
  const handleFormChange = (newData: CertificateEntity) => {
      setFormInput(newData);
    };
  const onCertificateDelete = (certId: number) => {
    setEntityToDelete(certId);
  };
  const onCertificateEditClicked = (certId: number) => {
    const toEditCert = props.certificates.find(cert => {
      return cert.id === certId;
    });
    showForm(toEditCert, false);
  };
  const onAddNewCertificationClicked = () => {
    showForm(createNewCertification(props.certificates), true);
  };
  const onCancel = () => {
    setIsEditing(false);
    setIsCreatingNew(false);
    setFormInput(createEmptyCertification());
  };
  const onSaveDataClicked = () => {
    if (!validateData(formInput)) {
      setValidationOn(true);
    } else {
      props.certificates.forEach(element => {
         try {
          element.name = removeSpace(element.name);
          element.issuedBy = removeSpace(element.issuedBy);
        }
        catch { }
      });
      props.postCertificateInput({
        authInfo: authInfo,
        entities: getNewListOfEntities()
      });
      setIsSubmitting(true);
      setFormInput(createEmptyCertification());
    }
  };

  //#endregion

  /**Helpers */
  //#region
  function hasCertificates() {
    return props.certificates.length > 0;
  }

  function getNewListOfEntities() {
    const certificates: CertificateEntity[] = [];
    props.certificates.forEach(element => {
      certificates.push(element);
    });
    const indexOf = certificates.findIndex(x => x.id === formInput.id);
    if (indexOf === -1) {
      certificates.push(formInput);
    } else {
      certificates[indexOf] = formInput;
    }
    return certificates;
  }

  function showForm(data: CertificateEntity, isNew: boolean) {
    setFormInput(data);
    if (isNew) {
      setIsCreatingNew(true);
    } else {
      setIsEditing(true);
    }
    setValidationOn(false);
  }

  //#endregion
  //#region RENDERING
  return (
    <StepLister
      entities={props.certificates}
      cvSectionsStates={props.cvSectionsStates}
      loadingStatus={props.loadingStatus}
      loadingStatusCvStates={props.loadingStatusCvStates}
      errorMessage={props.errorMessage}
      stepKey={SectionType.Certificates}
      entityToDelete={entityToDelete}
      setParentEntityToDelete={setEntityToDelete}
      fetchEntities={props.fetchCertificate}
      fetchCvSectionStates={props.fetchCvSectionStates}
      postEntityList={props.postCertificateInput}
    >
      {props.loadingStatus === StatusEnums.Loading && <Loader></Loader>}
      {hasCertificates() &&
        <CertificatesLister
          certificates={props.certificates}
          deleteCallback={onCertificateDelete}
          editCallback={onCertificateEditClicked}
          isInteractive={!(isEditing || isCreatingNew)}
          doValidate={validationOn}
          saveButtonCallback={onSaveDataClicked}
          certificateData={formInput}
          dataChangeCallback={handleFormChange}
          cancelCallback={onCancel}
          isNew={false}
        ></CertificatesLister>
      }
      {!(isEditing || isCreatingNew) &&
        <AddButton
          isFirstEntity={!hasCertificates()}
          sectionKey={SectionType.Certificates}
          onClickCallback={onAddNewCertificationClicked}
        ></AddButton>
      }
      {(isCreatingNew) &&
        <div className='c-card-lister__item-wrapper'>
          <CertificateEdit
            certificateData={formInput}
            dataChangeCallback={handleFormChange}
            cancelCallback={onCancel}
            doValidate={validationOn}
            isNew={true}
            saveButtonCallback={onSaveDataClicked}
          ></CertificateEdit>
        </div>
      }
      <Modal
        modalId='savingError'
        isVisible={showSavingErrorModal}
        modalText='Something went wrong, please try again later!'
        textAccept='Ok'
        handleAccept={() => {
          setShowSavingErrorModal(false);
        }}
      ></Modal>
    </StepLister>
  );
  //#endregion
}

export default StepCertificates;
