import Loader from 'cv-app/shared/ui/molecules/loader/loader';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EduLister from 'cv-app/pages/step-education-view/components/edu-lister/edu-lister';

import './step-education-view.module.scss';
import { AuthInfo, useAuth } from 'cv-app/utils/api-auth-helper';
import { PostListData } from 'cv-app/shared/services/sections';
import { StatusEnums } from 'cv-app/utils/status-enums';
import { EducationEntity } from 'cv-app/shared/redux/education/education.slice';
import { compareEducations } from 'cv-app/utils/educations/education-helper';
import { SectionType } from 'cv-app/utils/section-types';
import { CvSectionStateEntity } from 'cv-app/shared/redux/cvs/cvs.slice';
import StepLister from 'cv-app/shared/ui/modules/step-lister/step-lister';
import { getLegalEntitiesEducationDegrees } from 'cv-app/shared/services/educations/education-service';

/* eslint-disable-next-line */
export interface StepEducationViewProps {
  errorMessage: string;
  loadingStatus: StatusEnums;
  educations: EducationEntity[];
  loadingStatusCvStates: StatusEnums;
  cvSectionsStates: CvSectionStateEntity[];
  legalEntityId: number;
  loadingStatusPersonal: StatusEnums;
  fetchPersonal: (authInfo: AuthInfo) => void;
  fetchCvSectionStates: (authInfo: AuthInfo) => void;
  fetchEducation: (authInfo: AuthInfo) => void;
  postEducationInput: (postData: PostListData<EducationEntity>) => void;
}

export function StepEducationView(props: StepEducationViewProps) {
  /**STATES */
    //#region
  const [entityToDelete, setEntityToDelete] = useState(-1);
  const [degrees, setDegrees] = useState([]);
  //#endregion

  /**HOOKS */
    //#region
  const authInfo = useAuth();
  const { cvid } = useParams<{ cvid: string }>();
  useEffect(() => {
    if (props.loadingStatus  === StatusEnums.Initial) {
      props.fetchPersonal(authInfo);
    }
  }, []);

  useEffect(() => {
    async function fetchEducationDegrees() {
      if (props.loadingStatus === StatusEnums.Initial || props.loadingStatusPersonal === StatusEnums.Loaded) {
        try {
          const response = await getLegalEntitiesEducationDegrees(authInfo, props.legalEntityId);
          setDegrees(response);
        } catch (error) {
          console.log(error);
        }
      }
    }
    fetchEducationDegrees();
  }, [props.loadingStatusPersonal]);
  //#endregion

  /**CALLBACKS */
    //#region
  const handleDeletingEducation = (eduId: number) => {
      setEntityToDelete(eduId);
    };
  //#endregion

  /**RENDERING */
  //#region
  // Page view
  return (
    <StepLister
      entities={props.educations}
      cvSectionsStates={props.cvSectionsStates}
      loadingStatus={props.loadingStatus}
      loadingStatusCvStates={props.loadingStatusCvStates}
      errorMessage={props.errorMessage}
      stepKey={SectionType.Education}
      entityToDelete={entityToDelete}
      setParentEntityToDelete={setEntityToDelete}
      fetchEntities={props.fetchEducation}
      fetchCvSectionStates={props.fetchCvSectionStates}
      postEntityList={props.postEducationInput}
    >
      {props.loadingStatus === StatusEnums.Loading && <Loader></Loader>}
      <EduLister
        educations={props.educations?.sort(compareEducations)}
        deleteCallback={handleDeletingEducation}
        cvId={cvid}
        degreeMapping={degrees}
      ></EduLister>

    </StepLister>
  );
  //#endregion
}

export default StepEducationView;
