import { ConsultancyEntity } from 'cv-app/shared/redux/consultancy/consultancy.slice';
import { htmlDecode } from 'cv-app/shared/services/html-serialize/html-serialize';
import { ArticleEntity } from 'cv-app/shared/ui/components/cv-article-card/cv-article-card';
import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import { compareHistoryDates, gethistoryDates, isValidHistoryItem } from 'cv-app/utils/base/history-helper';
import { isValidDateRange, isValidOption } from '../helpers/form-helper';
import { getNextListItemId, isValidListItem } from '../helpers/list-item-helper';
import { stringIsNullOrEmpty, removeSpace } from '../helpers/string-helper';

export function mapConsultancyToArticle(c: ConsultancyEntity): ArticleEntity {
  const entity: ArticleEntity = {
    id: c.id,
    title: c.customerName,
    subtitle: `${c.projectInformation}, ${c.role}`,
    info: gethistoryDates(c),
    description: c.description
  };
  return entity;
}

export function haveIdenticalData(c: ConsultancyEntity, otherC: ConsultancyEntity) {
  return (
    c.timesheetCode === otherC.timesheetCode &&
    c.customerName === otherC.customerName &&
    c.projectInformation === otherC.projectInformation &&
    c.industryId === otherC.industryId &&
    c.role === otherC.role &&
    c.startDate === otherC.startDate &&
    c.endDate === otherC.endDate &&
    c.description === otherC.description &&
    c.isDelaware === otherC.isDelaware
  );
}

export function validateConsultancy(c: ConsultancyEntity, isFinished: boolean, industryOptions: SelectOption[]): boolean {
  try {
    c.customerName = removeSpace(c.customerName);
    c.projectInformation = removeSpace(c.projectInformation);
    c.role = removeSpace(c.role);
    c.timesheetCode = removeSpace(c.timesheetCode);
    c.description = removeSpace(c.description);
  }
  catch (exception)  {}

  if (!isValidListItem(c)) {
    return false;
  }
  if (!isValidHistoryItem(c, isFinished)) {
    return false;
  }
  if (!isValidOption(c.industryId, industryOptions)) {
    return false;
  }
  if (stringIsNullOrEmpty(c.customerName)
    || stringIsNullOrEmpty(c.projectInformation)
    || stringIsNullOrEmpty(c.role)
  ) {
    return false;
  }
  if (c.customerName.length < 2 || c.customerName.length > 80) {
    return false;
  }
  if (c.projectInformation.length < 2 || c.projectInformation.length > 80) {
    return false;
  }
  if (c.role.length < 2 || c.role.length > 80) {
    return false;
  }
  if (c.timesheetCode.length > 80) {
    return false;
  }
  if (!isValidDateRange(c.startDate, c.endDate, false)) {
    return false;
  }
  if (htmlDecode(c.description).length < 200) {
    return false;
  }
  if (htmlDecode(c.description).length > 700) {
    return false;
  }
  return true;
}

export function compareConsultancies(a: ConsultancyEntity, b: ConsultancyEntity) {
  const dateCompare = compareHistoryDates(a, b);
  if (dateCompare !== 0)
    return dateCompare;
  // in the unlikly event that two consultancies have the same start and end date, do it by customerName
  return a.customerName.localeCompare(b.customerName);
}

export function createEmptyConsultancy(): ConsultancyEntity {
  const emptyEntity: ConsultancyEntity = {
    id: -1,
    customerName: '',
    description: '',
    industryId: '',
    isDelaware: true,
    projectInformation: '',
    role: '',
    startDate: '',
    endDate: '',
    timesheetCode: ''
  };
  return emptyEntity;
}

export function createNewConsultancy(entities: ConsultancyEntity[]): ConsultancyEntity {
  return {
    ...createEmptyConsultancy(),
    id: getNextListItemId(entities)
  };
}
