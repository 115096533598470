import React from 'react';
import './example-component.module.scss';
import { useTranslation } from 'react-i18next';

/* eslint-disable-next-line */
export interface ExampleProps {
  modalId: string;
  isVisible: boolean;
  modalText: string;
  textCancel?: string;
  textAccept: string;
  handleCancel?: () => void;
  handleAccept: () => void;
}

export function Example() {
  const { t } = useTranslation();

  const data = [
    {
      jobTitle: 'Analyst',
      team: 'SAP',
      link: '../assets/cvs/Analyst.pdf'
    },
    {
      jobTitle: 'Consultant',
      team: 'operate',
      link: '../assets/cvs/Consultant.pdf'
    },
    {
      jobTitle: 'Senior Consultant',
      team: 'no team',
      link: '../assets/cvs/SR Consultant.pdf'
    },
    {
      jobTitle: 'Lead Expert',
      team: 'SAP, integration',
      link: '../assets/cvs/Lead Expert.pdf'
    },
    {
      jobTitle: 'Senior Manager',
      team: 'data & analytics',
      link: '../assets/cvs/SR Manager.pdf'

    }
  ];

  return (
    <div className='c-example'>
      <div className='c-example__title'>{t('personal-information__example-title')}</div>
      <p>{t('personal-information__example-description')}</p>
      <div className='o-flex'>
        <table>
          <tbody>
          <tr>
            <th></th>
            <th>{t('personal-information__lbl-jobTitle')}</th>
            <th>{t('personal-information__lbl-team')}</th>
            <th></th>
            
          </tr>
          {data.map((val, key) => {
            return (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{val.jobTitle}</td>
                <td>{val.team}</td>
                <td className='c-example__link'><a href={val.link} download>{t('general__download')}</a></td>
              </tr>
            );
          })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Example;
