import React from 'react';
import Box from 'cv-app/shared/ui/layout/box/box';
import CardActions from 'cv-app/shared/ui/components/card-actions/card-actions';
import { cleanHtml } from 'cv-app/shared/services/html-serialize/html-serialize';

import './cv-article-card.module.scss';

export interface ArticleEntity {
  id: number;
  title: string;
  subtitle: string;
  info: string;
  description: string;
}

/* eslint-disable-next-line */
export interface CvArticleCardProps {
  editLink: string;
  deleteCallback: (articleId: number) => void;
  article: ArticleEntity;
}

export function CvArticleCard(props: CvArticleCardProps) {
  let description = props.article.description.length > 249 ? `${props.article.description.slice(0, 249)}...` : props.article.description;
  if (description.includes('<![CDATA')) {
    description = description.replace('<![CDATA[', '').slice(0, -3);
  }
  return (
    <article className="c-cv-article">
      <Box isBordered={true} cssClass="c-cv-article__box">
        <div className="o-flex o-flex--justify-content-between">
          <div className="c-cv-article__hdr o-hidden">
            <div className="c-cv-article__title text-truncate" title={props.article.title}>{props.article.title}</div>
            <div className="c-cv-article__subtitle">{props.article.subtitle}</div>
            <div className="c-cv-article__info">{props.article.info}</div>
          </div>
          <CardActions
            editLink={props.editLink}
            deleteCallback={() => {props.deleteCallback(props.article.id)}}
            extraCssClass="c-cv-article__actions"
          ></CardActions>
        </div>
        <p className="u-margin-bottom-none text-truncate" dangerouslySetInnerHTML={{__html: cleanHtml(description)}}></p>
      </Box>
    </article>
  );
}

export default CvArticleCard;
