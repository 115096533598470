import { ConsultancyEntity } from 'cv-app/shared/redux/consultancy/consultancy.slice';
import AddLink from 'cv-app/shared/ui/components/add-link/add-link';
import CvArticleCard from 'cv-app/shared/ui/components/cv-article-card/cv-article-card';
import Timeline, { TimelineRow } from 'cv-app/shared/ui/components/timeline/timeline';
import { gethistoryYears } from 'cv-app/utils/base/history-helper';
import { mapConsultancyToArticle } from 'cv-app/utils/consultancy/consultancy-helper';
import { SectionType } from 'cv-app/utils/section-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './consultancy-lister.module.scss';

/* eslint-disable-next-line */
export interface ConsultancyListerProps {
  articleDeleteCallback: (articleId: number) => void;
  cvId: string;
  entities: ConsultancyEntity[];
}

export function ConsultancyLister(props: ConsultancyListerProps) {
  const { t } = useTranslation();

  function hasConsultancys() {
    return props.entities.length > 0
  }
  const renderAddLink = () => {
    return (
      <AddLink
        isFirstEntity={!hasConsultancys()}
        cvId={props.cvId}
        sectionKey={SectionType.Consultancy}
      ></AddLink>
    );
  }

  const rows: TimelineRow[] = [];
  props.entities?.forEach((x, i) => {
    const article = mapConsultancyToArticle(x);
    const row: TimelineRow = {
      aside: <div>{gethistoryYears(x, t('timeline_present'))}</div>,
      svg: x.isDelaware ? 'icon-delaware' : null,
      dotVariant: x.isDelaware ? 'accent-primary' : null,
      flag:
        <CvArticleCard
          article={article}
          editLink={`/cv/${props.cvId}/work-history/consultancy/${article.id}`}
          deleteCallback={props.articleDeleteCallback}
          key={`project-card-${i}`}
        ></CvArticleCard>

    }
    rows.push(row);
  });

  return (
    <React.Fragment>
      {props.entities.length > 2 &&
        <div className='u-margin-bottom-small'>
          {renderAddLink()}
        </div>
      }

      <Timeline timelineRows={rows} isRequiredMarksDisabled={true}></Timeline>

      {renderAddLink()}
    </React.Fragment>
  );
}

export default ConsultancyLister;
