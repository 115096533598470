import { CertificateEntity } from 'cv-app/shared/redux/certificate/certificate.slice';
import Box from 'cv-app/shared/ui/layout/box/box';
import Button from 'cv-app/shared/ui/molecules/button/button';
import SimpleSvg from 'cv-app/shared/ui/molecules/simple-svg/simple-svg';
import { gethistoryDates } from 'cv-app/utils/base/history-helper';
import { stringIsNullOrEmpty } from 'cv-app/utils/helpers/string-helper';
import React from 'react';

import './certificate-item.module.scss';

/* eslint-disable-next-line */
export interface CertificateItemProps {
  certificate: CertificateEntity;
  isInteractive: boolean;
  deleteCallback: (certId: number) => void;
  editCallback: (certId: number) => void;
  extraCss?: string
}

export function CertificateItem(props: CertificateItemProps) {
  const renderLabel = (lbl: string, key: string) => {
    if (!stringIsNullOrEmpty(lbl)) {
      return (
        <div key={key} className="c-certificate-card__tag">{lbl}</div>
      );
    }
  }
  const certificateLables = props.certificate.labels ? props.certificate.labels : [];
  return (
    <Box cssClass={`c-certificate-card ${props.extraCss} o-hidden`} isBordered={true}>
      <div className="c-certificate-card__top-wrapper">
        <SimpleSvg
          cssClass="c-certificate-card__icon"
          svgId="icon-star"
          svgHeight={40}
          svgWidth={40}
        ></SimpleSvg>
        <div className="c-certificate-card__text-wrapper o-hidden">
          <h3 className="c-certificate-card__title text-truncate" title={props.certificate.name}>{props.certificate.name}</h3>
          <div className="c-certificate-card__dates">
            {gethistoryDates(props.certificate)}
          </div>
        </div>
        {props.isInteractive &&
          <div>
            <Button
              buttonIcon="icon-edit"
              buttonCss="u-margin-none u-padding-none"
              buttonIconHeight={28}
              buttonIconWidth={28}
              callbackOnClick={() => { props.editCallback(props.certificate.id) }}
            ></Button>
            <Button
              buttonIcon="icon-trashcan"
              buttonCss="u-margin-none"
              buttonIconHeight={28}
              buttonIconWidth={28}
              callbackOnClick={() => { props.deleteCallback(props.certificate.id) }}
            ></Button>
          </div>}
      </div>
      <div className="c-certificate-card__tag-wrapper">
        {renderLabel(props.certificate.issuedBy, `cert-${props.certificate.id}-lbl-issuedBy`)}
        {certificateLables.map((label, i) => {
          return (
            renderLabel(label, `cert-${props.certificate.id}-lbl-${i}`)
          );
        })}
      </div>
    </Box>
  );
}

export default CertificateItem;
