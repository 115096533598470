import React from 'react';
import { AppInsightsErrorBoundary, useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useTranslation } from 'react-i18next';

/* eslint-disable-next-line */
export interface TrackedErrorBoundaryProps {
  message?: string;
}

export function TrackedErrorBoundary(props: React.PropsWithChildren<TrackedErrorBoundaryProps>) {
  const { t } = useTranslation();
  const appInsights = useAppInsightsContext();

  const errorBoxStyle = {
    backgroundColor: "#ffbaba",
    color: "#D8000C",
    margin: "20px",
    padding: "0 10px 20px",
    border: "1px solid #D8000C",
    borderRadius: "3px"
  };

  const onErrorRendering = () => {
    return (
      <div style={errorBoxStyle}>
          <h1>{props.message || t("general__error-boundary")}</h1>
      </div>
    )
  }
  return (
    <AppInsightsErrorBoundary onError={onErrorRendering} appInsights={appInsights}>
      {props.children}
    </AppInsightsErrorBoundary>
  );
}

export default TrackedErrorBoundary;
