import { fetchCvs, fetchCvSectionStates, fetchDownloadCV } from "cv-app/shared/redux/cvs/cvs.slice";
import { fetchTeams } from "cv-app/shared/redux/data/teams/teams.slice";
import { connect } from "react-redux";
import CvLister from "cv-app/pages/dashboard/components/cv-lister/cv-lister";
import { fetchPersonal } from 'cv-app/shared/redux/personal/personal.slice';

const mapStateToProps = (state: any) => ({
  loadingStatus: state.cvs.loadingStatus,
  loadingStatusCvStates: state.cvs.loadingSectionsStatus,
  loadingDownloadStatus: state.cvs.loadingDownloadStatus,
  errorMessage: state.cvs.error,
  cvs: state.cvs.variants,
  cvSectionsStates: state.cvs.sections,
  teamsLoadingStatus: state.teams.loadingStatus,
  teamsData: state.teams.teams,
  loadingStatusPersonal: state.personal.loadingStatus,
  legalEntityId: state.personal.sectionData.input.legalEntityId,
  personalSectionDataInput: state.personal.sectionData.input,
});

const mapDispatchToProps = {
  fetchCvs,
  fetchCvSectionStates,
  fetchTeams,
  fetchDownloadCV,
  fetchPersonal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CvLister);
