import { MeEntity } from "cv-app/shared/redux/me/me.slice";
import { PersonalInfoFormData, PersonalInformationInput } from "cv-app/shared/services/personal-information/personal-types";
import { SelectOption } from "cv-app/shared/ui/molecules/form-select/form-select";
import { getOptionName } from "../helpers/form-helper";
import { StatusEnums } from "../status-enums";

export function personalInputUnchanged(personalInfo: PersonalInfoFormData, otherPersonalInfo: PersonalInformationInput) {
    return (
        personalInfo.firstName === otherPersonalInfo.firstName &&
        personalInfo.lastName === otherPersonalInfo.lastName &&
        personalInfo.nationality === otherPersonalInfo.nationality &&
        personalInfo.jobTitle === otherPersonalInfo.jobTitle &&
        personalInfo.teamId === otherPersonalInfo.teamId &&
        personalInfo.description === otherPersonalInfo.description
    );
}

export function getUserNationality(userData: MeEntity, nationalities: SelectOption[]) {
    if (!userData || !userData.nationalityId) {
      return '';
    }
    return getOptionName(userData.nationalityId.toString(), nationalities);
}

export function getUserLegalEntity(userData: MeEntity, entities: SelectOption[]) {
    if (!userData || !userData.entityId) {
      return '';
    }
    return getOptionName(userData.entityId.toString(), entities);
}

export function getProfilePhoto(userData: MeEntity, imageLoadingStatus: StatusEnums) {
    if (userData.hasPhoto && imageLoadingStatus === StatusEnums.Loaded) {
      return `data:image/jpeg;base64,${userData.photoDataBase64}`
    }
    return '';
}
