import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';


let reactPlugin = new ReactPlugin();;
let appInsights = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
 const createTelemetryService = () => {

  /**
   * Initialize the Application Insights class
   * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
   * @return {void}
   */
  const initialize = (browserHistory) => {
      if (!browserHistory) {
          throw new Error('Could not initialize Telemetry Service');
      }
      const instrumentationKey = getAppInsightKey();
      if (!instrumentationKey) {
          return null;
      }

      appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: instrumentationKey,
          enableAutoRouteTracking: true,
          disableFetchTracking: false,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
          }
        }
      });
      appInsights.loadAppInsights();
      appInsights.trackPageView();
  };

  const getAppInsightKey = () => {
    const serverConfigLogging = window.serverConfig.Logging;
    if (!window.serverConfig.Logging) {
      console.log("No logging settings on config");
      return null;
    }
    if (!serverConfigLogging.ApplicationInsights || !serverConfigLogging.ApplicationInsights.InstrumentationKey) {
      console.log("Please set an instrumentation key");
      return null;
    }
    return serverConfigLogging.ApplicationInsights.InstrumentationKey;
  }

  return {reactPlugin, appInsights, initialize};
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;