import { connect } from "react-redux";
import {fetchEducation, postEducationInput} from 'cv-app/shared/redux/education/education.slice';
import {fetchDegree} from 'cv-app/shared/redux/data/degree/degree.slice';
import { fetchCvSectionStates } from "cv-app/shared/redux/cvs/cvs.slice";
import StepEducationEdit from "../step-education-edit";

const mapStateToProps = (state: any) => ({
    loadingStatus: state.education.loadingStatus,
    loadingStatusCvStates: state.cvs.loadingSectionsStatus,
    degreesLoadingStatus: state.degree.loadingStatus,
    errorMessage: state.education.error,
    educations: Object.values(state.education.sectionData.input.educations),
    sectionData: state.education.sectionData,
    cvSectionsStates: state.cvs.sections,
    degrees: state.degree.degrees,
    legalEntityId: state.personal.sectionData.input.legalEntityId,
});

const mapDispatchToProps = {
    fetchEducation,
    postEducationInput,
    fetchDegree,
    fetchCvSectionStates
};

export default connect(mapStateToProps, mapDispatchToProps)(StepEducationEdit)