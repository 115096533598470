import { formatDateForInput } from "./form-helper";
import { stringIsNullOrEmpty } from "./string-helper";

/**
 * 
 * @param a Date in string format
 * @param b Another date (to compare) in string format
 * @returns -1 if a < b (a before b chronologically), 1 if a > b, 0 if equal
 */
export function compareDates(a: string, b: string) {
    const aDate = new Date(a);
    const bDate = new Date(b);
    if (aDate < bDate)
      return -1;
    if (aDate > bDate)
      return 1;
    return 0;
  }
  
export function setMaxDateForStartDate(endDate: string){
  if(!stringIsNullOrEmpty(endDate)){
    return formatDateForInput(new Date(endDate).toISOString())
  }
  return formatDateForInput(new Date().toISOString())
}

export function maxDateToday(){
  return formatDateForInput(new Date().toISOString())
}
