import { JobEntity } from 'cv-app/shared/redux/jobs/jobs.slice';
import { htmlDecode } from 'cv-app/shared/services/html-serialize/html-serialize';
import { ArticleEntity } from 'cv-app/shared/ui/components/cv-article-card/cv-article-card';
import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import { compareHistoryDates, gethistoryDates, isValidHistoryItem } from 'cv-app/utils/base/history-helper';
import { getOptionName, isValidDateRange, isValidOption } from '../helpers/form-helper';
import { getNextListItemId, isValidListItem } from '../helpers/list-item-helper';
import { stringIsNullOrEmpty, removeSpace } from '../helpers/string-helper';

export function mapJobToArticle(c: JobEntity, industryOptions: SelectOption[]): ArticleEntity {
  const entity: ArticleEntity = {
    id: c.id,
    title: c.companyName,
    subtitle: `${c.role}, ${getOptionName(c.industryId, industryOptions)}`,
    info: gethistoryDates(c),
    description: c.description
  };
  return entity;
}

export function haveIdenticalData(j: JobEntity, otherJ: JobEntity) {
  return (
    j.companyName === otherJ.companyName &&
    j.industryId === otherJ.industryId &&
    j.role === otherJ.role &&
    j.startDate === otherJ.startDate &&
    j.endDate === otherJ.endDate &&
    j.description === otherJ.description
  );
}

export function validateJobEntity(w: JobEntity, isFinished: boolean, industryOptions: SelectOption[]): boolean {
  try {
    w.companyName = removeSpace(w.companyName);
    w.role = removeSpace(w.role);
    w.description = removeSpace(w.description);
  }
  catch (exception) {}

  if (!isValidListItem(w)) {
    return false;
  }
  if (!isValidHistoryItem(w, isFinished)) {
    return false;
  }
  if (!isValidOption(w.industryId, industryOptions))
    if (stringIsNullOrEmpty(w.companyName)
      || stringIsNullOrEmpty(w.role)
    ) {
      return false;
    }
  if (!isValidDateRange(w.startDate, w.endDate, false)) {
    return false;
  }
  if(w.companyName.length < 2 || w.companyName.length > 80){
    return false;
  }
  if(w.role.replace.length < 2 || w.role.length > 80){
    return false;
  }  
  if (htmlDecode(w.description).length < 200) {
    return false;
  }
  if (htmlDecode(w.description).length > 700) {
    return false;
  }
  return true;
}

export function compareJobEntities(a: JobEntity, b: JobEntity) {
  const dateCompare = compareHistoryDates(a, b);
  if (dateCompare !== 0)
    return dateCompare;
  // in the unlikly event that two jobs have the same start and end date, do it by companyName
  return a.companyName.localeCompare(b.companyName);
}

export function createEmptyJob(): JobEntity {
  const emptyEntity: JobEntity = {
    id: -1,
    companyName: '',
    description: '',
    industryId: '',
    role: '',
    startDate: '',
    endDate: ''
  };
  return emptyEntity;
}

export function createNewJob(entities: JobEntity[]): JobEntity {
  return {
    ...createEmptyJob(),
    id: getNextListItemId(entities)
  };
}
