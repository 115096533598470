import React from 'react';
import { Link } from 'react-router-dom';
import SimpleSvg from '../../molecules/simple-svg/simple-svg';

import './timeline.module.scss';

export interface TimelineRow {
  aside: React.ReactNode;
  svg?: string;
  rowUrl?: string;
  flag: React.ReactNode;
  dotVariant?: 'accent-primary' | 'accent-secondary' | 'none';
  isRequired?: boolean;
}
/* eslint-disable-next-line */
export interface TimelineProps {
  timelineRows: TimelineRow[];
  dotSmall?: boolean;
  isRequiredMarksDisabled?: boolean
}

export function Timeline(props: TimelineProps) {
  const renderAside = (x: TimelineRow) => {
    const asideClassName = 'c-timeline__aside';
    if (x.rowUrl) {
      return (
        <Link className={`${asideClassName} u-underline-none`} to={x.rowUrl}>
          {x.aside}
        </Link>
      );
    }
    return (
      <div className={asideClassName}>
        {x.aside}
      </div>
    );
  };

  const renderDot = (x: TimelineRow) => {
    const dotClassName = `c-timeline__dot ${x.dotVariant ? `c-timeline__dot--${x.dotVariant} ` : ''} `;
    if (x.rowUrl) {
      return (
        <Link className={dotClassName} to={x.rowUrl}>
          {x.svg && renderSvg(x.svg)}
        </Link>
      );
    }
    return (
      <div className={dotClassName}>
        {x.svg && renderSvg(x.svg)}
      </div>
    );
  };

  const renderSvg = (svg: string) => {
    return (
      <SimpleSvg
        svgId={svg}
        svgHeight={31}
        svgWidth={19}
      ></SimpleSvg>
    );
  };

  const renderFlag = (x: TimelineRow) => {
    const flagClassName = 'c-timeline__flag';
    if (x.rowUrl) {
      return (
        <Link className={`${flagClassName} u-underline-none`} to={x.rowUrl}>
          {x.flag}
        </Link>
      );
    }
    return (
      <div className={flagClassName}>
        {x.flag}
      </div>
    );
  };

  const renderRequired = (x: TimelineRow) => {
    if (props.isRequiredMarksDisabled) {
      return;
    } else if (!x.isRequired) {
      return <div></div>;
    }
    return x.dotVariant === 'none' || !x.rowUrl ? <div className='required-mark'>*</div> : <div></div>
  };

  return (
    <div className={` c-timeline ${props.dotSmall ? '' : 'c-timeline--lg'}`}>
      <div className='c-timeline__grid'>
        {props.timelineRows.map((x, i) => {
          const isLastRow = props.timelineRows.length - 1 === i;
          return (
            <React.Fragment key={`timeline-${i}`}>
              {renderAside(x)}
              <div className={`c-timeline__dot-area ${isLastRow ? 'c-timeline__dot-last' : ''}`}>
                {renderDot(x)}
              </div>
              {renderFlag(x)}
              {renderRequired(x)}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default Timeline;
