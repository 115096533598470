import { connect } from "react-redux";
import { fetchCvSectionStates } from "cv-app/shared/redux/cvs/cvs.slice";
import { fetchJobs, postJobsInput} from 'cv-app/shared/redux/jobs/jobs.slice';
import StepOtherWorkEdit from "../step-other-work-edit";
import { fetchPersonal } from 'cv-app/shared/redux/personal/personal.slice';

const mapStateToProps = (state: any) => ({
    loadingStatus: state.jobs.loadingStatus,
    loadingStatusCvStates: state.cvs.loadingSectionsStatus,
    cvSectionsStates: state.cvs.sections,
    errorMessage: state.jobs.error,
    entities: state.jobs.sectionData.input.otherWorkHistoryItems,
    loadingStatusPersonal: state.personal.loadingStatus,
    legalEntityId: state.personal.sectionData.input.legalEntityId,
})

const mapDispatchToProps = {
    fetchPersonal,
    fetchJobs,
    postJobsInput,
    fetchCvSectionStates,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepOtherWorkEdit);
