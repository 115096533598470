import FormInput from 'cv-app/shared/ui/molecules/form-input/form-input';
import React, { useState } from 'react';

import './education-form.module.scss';
import CheckBox from 'cv-app/shared/ui/molecules/check-box/check-box';
import FormSelect, {
  SelectOption,
} from 'cv-app/shared/ui/molecules/form-select/form-select';
import { EducationEntity } from 'cv-app/shared/redux/education/education.slice';
import DateInterval from 'cv-app/shared/ui/components/date-interval/date-interval';
import { SectionType } from 'cv-app/utils/section-types';
import { useTranslation } from 'react-i18next';
import { maxDateToday } from 'cv-app/utils/helpers/date-helper';
import { htmlDecode } from 'cv-app/shared/services/html-serialize/html-serialize';

/* eslint-disable-next-line */
export interface EducationFormProps {
  doValidate: boolean;
  educationData: EducationEntity;
  isStudying: boolean;
  degreeDataSource: SelectOption[];
  dataChangeCallback: (data: EducationEntity) => void;
  checkboxChangeCallback: (checked: boolean) => void;
}

const MIN_DATE = '1950-01-01';

export function EducationForm(props: EducationFormProps) {
  const [isMaxLengthError, setIsMaxLengthError] = useState(false);
  const [isMaxLengthSchool, setIsMaxLengthSchool] = useState(false);
  const [isMaxLengthField, setIsMaxLengthField] = useState(false);
  const { t } = useTranslation();

  /**CALLBACKS */
  //#region
  const handleSchoolChange = (newValue: string) => {
    if (newValue.length > 80) {
      setIsMaxLengthSchool(true);
    } else {
      setIsMaxLengthSchool(false);
    }    
    const data : EducationEntity = {
      ...props.educationData,
      schoolName: newValue
    };
    props.dataChangeCallback(data);
  };
  const handleDegreeChange = (newValue: string) => {
    const data : EducationEntity = {
      ...props.educationData,
      educationDegreeId: newValue
    };
    props.dataChangeCallback(data);
  };
  const handleFieldChange = (newValue: string) => {
    if (newValue.length > 80) {
      setIsMaxLengthField(true);
    } else {
      setIsMaxLengthField(false);
    }
    const data : EducationEntity = {
      ...props.educationData,
      fieldOfStudy: newValue
    };
    props.dataChangeCallback(data);
  };
  const handleStartDateChange = (newValue: string) => {
    const data : EducationEntity = {
      ...props.educationData,
      startDate: newValue,
    };
    props.dataChangeCallback(data);
  };
  const handleEndDateChange = (newValue: string) => {
    const data : EducationEntity = {
      ...props.educationData,
      endDate: newValue
    };
    props.dataChangeCallback(data);
  };
  const handleDescriptionChange = (newValue: string) => {
    if (newValue.length > 700) {
      setIsMaxLengthError(true);
    } else {
      setIsMaxLengthError(false);
    }
    const data : EducationEntity = {
      ...props.educationData,
      description: newValue
    };
    props.dataChangeCallback(data);
  };
  const handleCheckboxChange = e => {
    props.checkboxChangeCallback(!props.isStudying);
    const checked = e.target.checked;
    if (checked) {
      handleEndDateChange(null);
    }
  };
  //#endregion

  return (
    <React.Fragment>
      <div className="c-form__input-grid">
        <FormInput
          isRequired={true}
          type="input"
          doValidate={props.doValidate}
          onChangeCallback={handleSchoolChange}
          inputValue={props.educationData.schoolName}
          inputId="edu_school"
          labelText={t("education__lbl-schoolName")}
          errorText={t("education__lbl-schoolNameError")}
          minLength={2}
          maxLenght={80}   
          maxLengthErrorText={t('limit_reached') + " (" + props.educationData.schoolName.length + t("characters") + ")"}
          isMaxLengthError={isMaxLengthSchool}             
        ></FormInput>
        <FormSelect
          isRequired={true}
          doValidate={props.doValidate}
          inputValue={props.educationData.educationDegreeId}
          inputId="edu_degree"
          inputName="edu_degree"
          labelText={t("education__lbl-degree")}
          errorText={t("education__lbl-degreeError")}
          onChangeCallback={handleDegreeChange}
          selectDataSource={props.degreeDataSource}
          defaultValue={`${t('general__select-choose')} ${t("education__lbl-degree")}`}
        ></FormSelect>
      </div>
      <div className="c-form__input-grid c-form__input-grid--double">
        <FormInput
          isRequired={true}
          type="input"
          doValidate={props.doValidate}
          onChangeCallback={handleFieldChange}
          inputValue={props.educationData.fieldOfStudy}
          inputId="edu_field"
          labelText={t("education__lbl-fieldOfStudy")}
          errorText={t("education__lbl-fieldOfStudyError")}
          minLength={2}
          maxLenght={80}
          maxLengthErrorText={t('limit_reached') + " (" + props.educationData.fieldOfStudy.length + t("characters") + ")"}
          isMaxLengthError={isMaxLengthField}
        ></FormInput>
        <div>
          <div className="c-form__input-grid">
            <DateInterval
              doValidate={props.doValidate}
              componentKey={SectionType.Education}
              startDate={props.educationData.startDate}
              endDate={props.educationData.endDate}
              // maxEndDate={maxDateToday()}
              requireEndDate={!props.isStudying}
              showEndDate={!props.isStudying}
              onStartDateChanged={handleStartDateChange}
              onEndDateChanged={handleEndDateChange}
              minDate={MIN_DATE}
            ></DateInterval>
          </div>
          <CheckBox
            inputId="edu_studying"
            isChecked={props.isStudying}
            labelText={t("education__lbl-checkbox")}
            changeCallback={handleCheckboxChange}
            extraCss="c-education-form__checkbox"
          ></CheckBox>
        </div>
      </div>
      <FormInput
        isRequired={false}
        type="textarea"
        doValidate={props.doValidate}
        onChangeCallback={handleDescriptionChange}
        inputValue={props.educationData.description}
        inputId="edu_description"
        labelText={t("education__lbl-description")}
        maxLenght={700}
        maxLengthErrorText={t('limit_reached') + " (" + htmlDecode(props.educationData.description).length + t("characters") + ")"}
        isMaxLengthError={isMaxLengthError}
      ></FormInput>
    </React.Fragment>
  );
}

export default EducationForm;
