// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".step-personal_examples-button__GmwAx {\n  text-transform: unset;\n}", ""]);
// Exports
exports.locals = {
	"examples-button": "step-personal_examples-button__GmwAx"
};
module.exports = exports;
