// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".passport_c-passport__2V9QO {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n}\n.passport_c-passport__col__2uH1A {\n  display: flex;\n  align-items: center;\n}\n.passport_c-passport__col--img-wrapper__2VTgK {\n  width: 200px;\n  height: 200px;\n  margin-right: 40px;\n}\n.passport_c-passport__title__1IesS {\n  font-size: 40px;\n  line-height: 1.2;\n}\n.passport_c-passport__info__1P9Pq {\n  font-size: 18px;\n  line-height: 1.5;\n}\n.passport_c-passport__subtitle__Ndg2O {\n  font-size: 24px;\n  line-height: 1.2;\n  color: brandColor(\"dot-red\");\n}", ""]);
// Exports
exports.locals = {
	"c-passport": "passport_c-passport__2V9QO",
	"c-passport__col": "passport_c-passport__col__2uH1A",
	"c-passport__col--img-wrapper": "passport_c-passport__col--img-wrapper__2VTgK",
	"c-passport__title": "passport_c-passport__title__1IesS",
	"c-passport__info": "passport_c-passport__info__1P9Pq",
	"c-passport__subtitle": "passport_c-passport__subtitle__Ndg2O"
};
module.exports = exports;
