import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { msalConfig } from 'auth/authConfig';
import App from 'app/app';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { reducers } from 'cv-app/shared/redux/reducers';
import CustomAiProvider from 'cv-app/utils/application-insights/custom-ai-provider';
import { getAppInsights } from 'cv-app/utils/application-insights/AppInsights';
import './i18n';


/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 */
const msalInstance = new PublicClientApplication(msalConfig);

const store = configureStore({
  reducer: reducers,
  // Additional middleware can be passed to this array
  middleware: [...getDefaultMiddleware()],
  devTools: process.env.NODE_ENV !== 'production',
  // Optional Redux store enhancers
  enhancers: [],
});

const history = createBrowserHistory({ basename: '' });
let appInsight = getAppInsights();
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Router history={history}>
          <CustomAiProvider history={history} onInitializedCallback={() => { appInsight = getAppInsights() }}>
            <App />
          </CustomAiProvider>
        </Router>
      </MsalProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
