import { connect } from "react-redux";
import {fetchEducation, postEducationInput} from 'cv-app/shared/redux/education/education.slice';
import {fetchCvSectionStates } from "cv-app/shared/redux/cvs/cvs.slice";
import StepEducationView from "../step-education-view";
import { fetchPersonal } from "cv-app/shared/redux/personal/personal.slice";

const mapStateToProps = (state: any) => ({
    loadingStatus: state.education.loadingStatus,
    loadingStatusCvStates: state.cvs.loadingSectionsStatus,
    cvSectionsStates: state.cvs.sections,
    errorMessage: state.education.error,
    educations: state?.education?.sectionData?.input?.educations && Object.values(state.education.sectionData.input.educations),
    loadingStatusPersonal: state.personal.loadingStatus,
    legalEntityId: state.personal.sectionData.input.legalEntityId,
});

const mapDispatchToProps = {
    fetchPersonal,
    fetchEducation,
    postEducationInput,
    fetchCvSectionStates
};

export default connect(mapStateToProps, mapDispatchToProps)(StepEducationView)