import { fetchCertificate, postCertificateInput } from "cv-app/shared/redux/certificate/certificate.slice";
import {fetchCvSectionStates } from "cv-app/shared/redux/cvs/cvs.slice";
import { connect } from "react-redux";
import StepCertificates from "../step-certificates";

const mapStateToProps = (state: any) => ({
    loadingStatus: state.certificate.loadingStatus,
    loadingStatusCvStates: state.cvs.loadingSectionsStatus,
    cvSectionsStates: state.cvs.sections,
    errorMessage: state.certificate.error,
    sectionData: state.certificate.sectionData,
    certificates: state.certificate.sectionData.input.certificates
});

const mapDispatchToProps = {
    fetchCertificate,
    postCertificateInput,
    fetchCvSectionStates
}

export default connect(mapStateToProps, mapDispatchToProps)(StepCertificates);