import escapeHtml from 'escape-html'
import { Descendant, Text } from 'slate'
import { jsx } from 'slate-hyperscript';
import DOMPurify from "dompurify";

export const serialize = node => {
  if (Text.isText(node)) {
    return escapeHtml(node.text);
  }

  let children;

  if (node instanceof Array) {
    children = node.map(n => {
      return n.children.map(c => serialize(c));
    }).join('');
  } else {
    children = node.children.map(n => serialize(n)).join('');
  }

  switch (node.type) {
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'bulleted-list':
      return `<ul>${children}</ul>`;
    case 'list-item':
      return `<li>${children}</li>`;
    default:
      return children;
  }
}

const deserialize = el => {
  if (el.nodeType === 3) {
    return el.textContent;
  }
  if (el.nodeType !== 1) {
    return null;
  }

  const children: Descendant[] = Array.from(el.childNodes).map(deserialize);

  if (children.length === 0) {
    children.push(jsx('text', {}, ''))
  }

  switch (el.nodeName) {
    // Elements:
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'P':
      return jsx('element', { type: 'paragraph' }, children);
    case 'H1':
      return jsx('element', { type: 'heading-one' }, children);
    case 'H2':
      return jsx('element', { type: 'heading-two' }, children);
    case 'UL':
      return jsx('element', { type: 'bulleted-list' }, children);
    case 'LI':
      return jsx('element', { type: 'list-item' }, children);

    // Leafs:
    case 'STRONG':
      return { text: el.textContent, bold: true };
    case 'EM':
      return { text: el.textContent, italic: true };
    case 'U':
      return { text: el.textContent, underline: true };

    default:
      return children;
  }
};

export const deserializeFromHtml = html => {
  const document = new DOMParser().parseFromString(html, 'text/html');
  return deserialize(document.body);
};

export const htmlDecode = (input: string) => {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

export const unescapeHtml = (unsafe: string) => {
  return unsafe
      .replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, "\"")
      .replace(/&#039;/g, "'")
      .replace(/&#39;/g, "'");
}

export const cleanHtml = (dirty: string) => {
  return DOMPurify.sanitize(dirty, {ALLOWED_TAGS: ['p', 'ul', 'li'], FORBID_CONTENTS: ['label'], FORBID_TAGS: ['form']});
}
