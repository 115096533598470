import { CertificateEntity, certificateSlice } from 'cv-app/shared/redux/certificate/certificate.slice';
import { isValidHistoryItem } from '../base/history-helper';
import { getNextListItemId } from '../helpers/list-item-helper';
import { stringIsNullOrEmpty, removeSpace } from '../helpers/string-helper';
import { isValidDateRange } from 'cv-app/utils/helpers/form-helper';

export function createEmptyCertification() {
  const emptyCert: CertificateEntity = {
    id: -1,
    name: '',
    issuedBy: '',
    startDate: '',
    endDate: null,
    labels: []
  };
  return emptyCert;
}

export function createNewCertification(certifications: CertificateEntity[]) {
  return {
    ...createEmptyCertification(),
    id: getNextListItemId(certifications)
  };
}

export function validateData(cert: CertificateEntity) {
  if (!isValidHistoryItem(cert, !stringIsNullOrEmpty(cert.endDate))) {
    return false;
  }
  if (cert.name === '') {
    return false;
  }
  if (removeSpace(cert.name).length < 2 || cert.name.length > 80) {
    return false;
  }
  if (cert.issuedBy.length > 80) {
    return false;
  }
  if (!isValidDateRange(cert.startDate, cert.endDate, false)) {
    return false;
  }
  return true;
}

export function addAlignSelfStartClass(certs: CertificateEntity[], editId: number, currentId: number): string {
  let css = '';
  const className = 'c-certificate-card--start';

  // If there are only 2 items, add css class if editId is not a new one.
  if (certs.length === 2 && certs.some(x => x.id == editId)) {
    css = className;
  } else {
    // Create array based on number of groups (2 items per group)
    const result: CertificateEntity[][] = [];
    for (let i = 0; i < certs.length; i += 2) {
      result.push(certs.slice(i, i + 2));
    }

    result.forEach(row => {
      // Add class to the item with editId if found in the same row as currentId
      if (row.some(x => x.id == editId) && row.some(x => x.id == currentId)) {
        css = className;
      }
    });
  }

  return css;
}
