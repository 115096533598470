import { AuthInfo, fetchJson, fetchJsonPost } from "cv-app/utils/api-auth-helper";
import { SectionData } from "cv-app/shared/services/sections";
import { ConsultancyInput } from "./consultancy-types";
import { unescapeHtml } from "../html-serialize/html-serialize";

//GET and POST for consultancy section
//#region
export function getConsultancySection(authInfo: AuthInfo): Promise<SectionData<ConsultancyInput>> {
  return fetchJson<SectionData<ConsultancyInput>>('/api/cvs/sections/consultancy-work-reference?draft=true', authInfo);
}
export function postConsultancies(input: ConsultancyInput, authInfo: AuthInfo) : Promise<SectionData<ConsultancyInput>> {
  const projects = input.projects.map(project => {
    return Object.assign({}, project, { description: unescapeHtml(project.description) });
  });
  return fetchJsonPost<SectionData<ConsultancyInput>>('/api/cvs/sections', { projects, type: input.type }, authInfo)
}
//#endregion
