import Box from 'cv-app/shared/ui/layout/box/box';
import Button from 'cv-app/shared/ui/molecules/button/button';
import Checkbox from 'cv-app/shared/ui/molecules/check-box/check-box';
import FormSelect, { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import FormInput from 'cv-app/shared/ui/molecules/form-input/form-input';
import React, { useState } from 'react';

import './language-card.module.scss';
import {
  LanguageProficiencyEntity,
  LanguageProficiency
} from 'cv-app/shared/redux/language-proficiency/language-proficiency.slice';
import { useTranslation } from 'react-i18next';

/* eslint-disable-next-line */
export interface LanguageCardProps {
  languageName: string;
  deleteCallback?: (id: number) => void;
  languageProficiency?: LanguageProficiencyEntity;
  editingMode?: boolean;
  languageOptions?: SelectOption[];
  languageProficiencyInput?: LanguageProficiencyEntity;
  inputChangeCallback?: (data: LanguageProficiencyEntity) => void;
  doValidate?: boolean;
  editLanguageCallback?: (language: LanguageProficiencyEntity) => void;
  addLanguageCallback?: (language: LanguageProficiencyEntity) => void;
  cancelAddLanguage?: () => void;
  languageProficiencyLevels: LanguageProficiency[];
}

export function LanguageCard(props: LanguageCardProps) {

  const [isEditMode, setEditMode] = useState(false);

  const [languageProficiency, setLanguageProficiency] = useState(props.languageProficiency);

  const { t } = useTranslation();

  const tryDelete = () => {
    props.deleteCallback(props.languageProficiency.id);
  };
  const tryAddLanguage = () => {
    props.addLanguageCallback(props.languageProficiencyInput);
  };

  const editLanguage = () => {
    props.editLanguageCallback(languageProficiency);
    setEditMode(false);
  };

  const cancelAddLanguage = () => {
    props.cancelAddLanguage();
  };

  const cancelEditLanguage = () => {
    setLanguageProficiency(props.languageProficiency)
    setEditMode(false);
  };

  const handleLanguageChange = (newValue: string) => {
    const data = { ...props.languageProficiencyInput };
    data.languageId = newValue;
    props.inputChangeCallback(data);
  };

  const handleEditLanguageChange = (newValue: string) => {
    const data = { ...languageProficiency };
    data.languageId = newValue;
    setLanguageProficiency(data);
  };

  const handleNativeChange = () => {
    const data = { ...props.languageProficiencyInput };
    data.isNative = !data.isNative;
    props.inputChangeCallback(data);
  };

  const handleEditNativeChange = () => {
    const data = { ...languageProficiency };
    data.isNative = !data.isNative;
    setLanguageProficiency(data);
  };

  const handleProficiencyChange = (newValue: number) => {
    const data = { ...props.languageProficiencyInput };
    data.level = newValue;
    props.inputChangeCallback(data);
  }

  const handleEditProficiencyChange = (newValue: number) => {
    const data = { ...languageProficiency };
    data.level = newValue;
    setLanguageProficiency(data);
  }

  function enableEditMode() {
    setEditMode(true);
  };

  if (props.editingMode) {
    const languageProficiencyLevelInfo = props.languageProficiencyLevels.find(x => x.id == props.languageProficiencyInput.level);
    const tooltip =
      <React.Fragment>
        <div className='c-language-card__slider-text'>
          {t(`language-proficiency__lbl-tooltip-${languageProficiencyLevelInfo?.code}`)}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: t(`language-proficiency__lbl-info-${languageProficiencyLevelInfo?.code}`) }}
          className={`c-language-card__slider-tooltip c-language-card__slider-tooltip--${languageProficiencyLevelInfo.id}`}></div>
      </React.Fragment>;
    return (
      <Box cssClass='c-language-card' isBordered={true}>
        <div className='c-language-card__input-wrapper'>
          <form className='c-form'>
            <FormSelect
              inputId='language_select'
              inputName='languageSelect'
              isRequired={true}
              labelText={t('language-proficiency__lbl-languageSelect')}
              doValidate={props.doValidate}
              inputValue={props.languageProficiencyInput.languageId}
              errorText={t('language-proficiency__lbl-languageSelectError')}
              onChangeCallback={handleLanguageChange}
              selectDataSource={props.languageOptions}
              defaultValue={t('language-proficiency__lbl-languageSelect')}
            ></FormSelect>
            <div className='c-checkbox'>
              <Checkbox
                inputId='language_native'
                labelText={t('language-proficiency__lbl-nativeCheckbox')}
                isChecked={props.languageProficiencyInput.isNative}
                changeCallback={handleNativeChange}
              ></Checkbox>
            </div>
            {!props.languageProficiencyInput.isNative &&
              <div className='c-language-card__bottom-wrapper'>
                <FormInput
                  isRequired={true}
                  type='range'
                  doValidate={props.doValidate}
                  onChangeCallback={handleProficiencyChange}
                  inputValue={props.languageProficiencyInput.level.toString()}
                  inputId='language_proficiency'
                  labelText={t('language-proficiency__lbl-range')}
                  min={1}
                  max={6}
                  errorText={t('language-proficiency__lbl-rangeError')}
                ></FormInput>
                {tooltip}
              </div>}

          </form>
        </div>
        <div className='c-language-card__button-wrapper'>
          <Button
            callbackOnClick={cancelAddLanguage}
            buttonText={t('general__btn-cancel')}
          ></Button>
          <div className='u-padding-left'></div>
          <Button
            buttonText={t('general__btn-add')}
            callbackOnClick={tryAddLanguage}
            btnType='primary'
          ></Button>
        </div>
      </Box>
    );
  } else if (isEditMode && languageProficiency) {
    const languageProficiencyLevelInfo = props.languageProficiencyLevels.find(x => x.id == languageProficiency?.level);
    const tooltip =
      <React.Fragment>
        <div className='c-language-card__slider-text'>
          {t(`language-proficiency__lbl-tooltip-${languageProficiencyLevelInfo?.code}`)}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: t(`language-proficiency__lbl-info-${languageProficiencyLevelInfo?.code}`) }}
          className={`c-language-card__slider-tooltip c-language-card__slider-tooltip--${languageProficiencyLevelInfo.id}`}></div>
      </React.Fragment>;
    return (
      <Box cssClass='c-language-card' isBordered={true}>
        <div className='c-language-card__input-wrapper'>
          <form className='c-form'>
            <FormSelect
              inputId='language_select'
              inputName='languageSelect'
              isRequired={true}
              labelText={t('language-proficiency__lbl-languageSelect')}
              doValidate={props.doValidate}
              inputValue={languageProficiency.languageId}
              errorText={t('language-proficiency__lbl-languageSelectError')}
              onChangeCallback={handleEditLanguageChange}
              selectDataSource={props.languageOptions}
              defaultValue={t('language-proficiency__lbl-languageSelect')}
            ></FormSelect>
            <div className='c-checkbox'>
              <Checkbox
                inputId='language_native'
                labelText={t('language-proficiency__lbl-nativeCheckbox')}
                isChecked={languageProficiency.isNative}
                changeCallback={handleEditNativeChange}
              ></Checkbox>
            </div>
            {!languageProficiency.isNative &&
              <div className='c-language-card__bottom-wrapper'>
                <FormInput
                  isRequired={true}
                  type='range'
                  doValidate={props.doValidate}
                  onChangeCallback={handleEditProficiencyChange}
                  inputValue={languageProficiency.level.toString()}
                  inputId='language_proficiency'
                  labelText={t('language-proficiency__lbl-range')}
                  min={1}
                  max={6}
                  errorText={t('language-proficiency__lbl-rangeError')}
                ></FormInput>
                {tooltip}
              </div>}
          </form>
        </div>
        <div className='c-language-card__button-wrapper'>
          <Button
            callbackOnClick={cancelEditLanguage}
            buttonText={t('general__btn-cancel')}
          ></Button>
          <div className='u-padding-left'></div>
          <Button
            buttonText={t('general__btn-update')}
            callbackOnClick={editLanguage}
            btnType='primary'
          ></Button>
        </div>
      </Box>
    );
  }

  return (
    <Box cssClass='c-language-card' isBordered={true}>
      <div className='c-language-card__top-wrapper'>
        <div className='c-language-card__text-wrapper'>
          <h3
            className={`c-language-card__title ${props.languageProficiency.isNative ? 'c-language-card__title--native' : ''
            }`}
          >
            {props.languageName} {props.languageProficiency.isNative &&
            <span>{t('language-proficiency__lbl-native')}</span>}
          </h3>
        </div>
        <div className='d-flex'>
          <Button
            callbackOnClick={enableEditMode}
            buttonIcon='icon-edit'
            buttonIconHeight={28}
            buttonIconWidth={28}
          ></Button>
          <Button
            callbackOnClick={tryDelete}
            buttonIcon='icon-trashcan'
            buttonIconHeight={28}
            buttonIconWidth={28}
          ></Button>
        </div>
      </div>
      <div className='c-language-card__bottom-wrapper'>
        <div className='c-language-card__slider-text'>{t(`language-proficiency__lbl-value-${props.languageProficiency.level.toString()}`)}</div>
        <div className='c-language-card__progress-wrapper'>
          <div className={`c-language-card__progress c-language-card__progress--${props.languageProficiency.level.toString().toUpperCase()}`}></div>
        </div>
      </div>
    </Box>
  );
}

export default LanguageCard;
