// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cv-placeholder_c-cv-placeholder__1sogW {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.cv-placeholder_c-cv-placeholder__wrapper__1uOuM {\n  width: 75%;\n  margin: 0 auto;\n  border: 1px dashed brandColor(\"mid-grey\");\n}\n.cv-placeholder_c-cv-placeholder__title__3_BRG {\n  font-size: 24px;\n  margin-bottom: 24px;\n}\n.cv-placeholder_c-cv-placeholder__info__1427r {\n  font-size: 12px;\n  text-align: center;\n  margin-top: 24px;\n  color: brandColor(\"mid-grey\");\n}", ""]);
// Exports
exports.locals = {
	"c-cv-placeholder": "cv-placeholder_c-cv-placeholder__1sogW",
	"c-cv-placeholder__wrapper": "cv-placeholder_c-cv-placeholder__wrapper__1uOuM",
	"c-cv-placeholder__title": "cv-placeholder_c-cv-placeholder__title__3_BRG",
	"c-cv-placeholder__info": "cv-placeholder_c-cv-placeholder__info__1427r"
};
module.exports = exports;
