import React from 'react';
import { SelectOption } from '../form-select/form-select';

import './radio-buttons.module.scss';

/* eslint-disable-next-line */
export interface RadioButtonsProps {
  isRequired: boolean;
  doValidate: boolean;
  groupName: string;
  selectedValue: string;
  radioButtons: SelectOption[]; //Hm, maybe the name is not reusable enough
  isInline: boolean;
  onChangeCallback: (newValue: string) => void;
}

export function RadioButtons(props: RadioButtonsProps) {
  const onRadioButtonChange = (event) => {
    props.onChangeCallback(event.target.value)
  }

  return (
    <div className={`c-field ${props.isInline ? "c-field--flex" : ""}`}>
      {props.radioButtons.map((r: SelectOption) => {
        let key = `${props.groupName}-${r.optionValue}`
        return (
          <label htmlFor={key} key={key} className="c-form-element">
            {r.optionName}
            <input 
              id={key} 
              type="radio" 
              value={r.optionValue} 
              name={props.groupName} 
              checked={props.selectedValue === r.optionValue}
              onChange={onRadioButtonChange}
            />
            <span className="c-radio"></span>
          </label>
        )
      })}
    </div>
  );
}

export default RadioButtons;
