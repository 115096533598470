import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getTeamsData } from 'cv-app/shared/services/personal-information/personal-information-service';
import { SelectOption } from 'cv-app/shared/ui/molecules/form-select/form-select';
import { AuthInfo } from 'cv-app/utils/api-auth-helper';
import { StatusEnums } from 'cv-app/utils/status-enums';

export const TEAMS_FEATURE_KEY = 'teams';

/*
 * Update these interfaces according to your requirements.
 */

export interface TeamsState extends EntityState<SelectOption> {
  loadingStatus: StatusEnums;
  error: string;
  teams: SelectOption[];
}

export const teamsAdapter = createEntityAdapter<SelectOption>();

export const fetchTeams = createAsyncThunk(
  'teams/fetchStatus',
  async (authInfo: AuthInfo, thunkAPI) => {
    const response = getTeamsData(authInfo);
    return Promise.resolve(response);
  }
);

export const initialTeamsState: TeamsState = teamsAdapter.getInitialState({
  loadingStatus: StatusEnums.Initial,
  error: null,
  teams: []
});

export const teamsSlice = createSlice({
  name: TEAMS_FEATURE_KEY,
  initialState: initialTeamsState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeams.pending, (state: TeamsState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(
        fetchTeams.fulfilled,
        (state: TeamsState, action: PayloadAction<SelectOption[]>) => {
         // teamsAdapter.setAll(state, action.payload); - doesn't work, don't know why :(
          state.teams = action.payload;
          state.loadingStatus = StatusEnums.Loaded;
        }
      )
      .addCase(fetchTeams.rejected, (state: TeamsState, action) => {
        state.loadingStatus = StatusEnums.Error;
        state.error = action.error.message;
      });
  },
});

/*
 * Export reducer for store configuration.
 */
export const teamsReducer = teamsSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(teamsActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const teamsActions = teamsSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllTeams);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = teamsAdapter.getSelectors();

export const getTeamsState = (rootState: unknown): TeamsState =>
  rootState[TEAMS_FEATURE_KEY];

export const selectAllTeams = createSelector(getTeamsState, selectAll);

export const selectTeamsEntities = createSelector(
  getTeamsState,
  selectEntities
);
