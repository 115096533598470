import { AuthInfo, fetchJson, fetchJsonPost } from 'cv-app/utils/api-auth-helper';
import { EducationDegree, EducationInput } from "cv-app/shared/services/educations/education-types";
import { SectionData } from 'cv-app/shared/services/sections';
import { SelectOption } from "cv-app/shared/ui/molecules/form-select/form-select";

// GET and POST for education section
//#region 
export function getEducationSection(authInfo: AuthInfo): Promise<SectionData<EducationInput>> {
    return fetchJson<SectionData<EducationInput>>('/api/cvs/sections/education?draft=true', authInfo);
}
export function postEducations(data: EducationInput, authInfo: AuthInfo) {
    return fetchJsonPost<SectionData<EducationInput>>('/api/cvs/sections', data, authInfo)
}

export function getLegalEntitiesEducationDegrees<T> (authInfo: AuthInfo, legalEntityId: number | string): Promise<SelectOption[]> {
    return new Promise((resolve, reject) => {
      fetchJson<EducationDegree[]>(`/api/educationdegrees/${legalEntityId}`, authInfo)
      .then(teams => {
        const selectOption: SelectOption[] = teams.map(d => {
          return {
              optionName: d.name,
              optionValue: d.id.toString()
          }
        });
        selectOption.sortObjects(x => x.optionName.toUpperCase());
        resolve(selectOption);
      })
      .catch(err => {
        reject(err)
      })
    })
}
//#endregion