import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { downloadCV, getCvSectionStates, getCvSummaryData } from 'cv-app/shared/services/cvs/cv-service';
import { CvSummaryData } from 'cv-app/shared/services/cvs/cv-types';
import { PersonalInformationInput } from 'cv-app/shared/services/personal-information/personal-types';
import { SectionData } from 'cv-app/shared/services/sections';
import { AuthInfo } from 'cv-app/utils/api-auth-helper';
import { SectionType } from 'cv-app/utils/section-types';
import { StatusEnums } from 'cv-app/utils/status-enums';

export const CVS_FEATURE_KEY = 'cvs';

/*
 * Update these interfaces according to your requirements.
 */
export interface FetchParameters {
  id: number;
  authInfo: AuthInfo,
  personalInfo: PersonalInformationInput,
}
export interface CvEntity {
  id: number;
  cvId: number;
  owner: string;
  hasDraft: boolean;
  hasPublished: boolean;
  canPublish: boolean;
  content: SectionData<PersonalInformationInput>;
}

export interface CvSectionStateEntity {
  id: string;
  cvId: string;
  locale: string;
  type: SectionType;
  canPublish: boolean;
  changedBy: string;
  changedOn: string;
}

export interface CvsState extends EntityState<CvEntity> {
  loadingStatus: StatusEnums;
  loadingSectionsStatus: StatusEnums;
  loadingDownloadStatus: StatusEnums;
  error: string;
  variants: CvEntity[];
  sections: CvSectionStateEntity[];
}

export const cvsAdapter = createEntityAdapter<CvEntity>();


export const fetchCvs = createAsyncThunk(
  'cvs/fetchStatus',
  async (authInfo: AuthInfo, thunkAPI) => {
    const response = await getCvSummaryData(authInfo);
    return Promise.resolve(response);
  }
);

export const fetchDownloadCV = createAsyncThunk('cvs/fetchDownloadStatus',
  async (params: FetchParameters) => {
    const response = await downloadCV(params.id, params.authInfo, params.personalInfo);
    return Promise.resolve(response);
  }
);

export const fetchCvSectionStates = createAsyncThunk(
  'cvs/fetchSectionsStatus',
  async (authInfo: AuthInfo, thunkAPI) => {
    const response = await getCvSectionStates(authInfo);
    return Promise.resolve(response);
  }
);

export const initialCvsState: CvsState = cvsAdapter.getInitialState({
  loadingStatus: StatusEnums.Initial,
  loadingSectionsStatus: StatusEnums.Initial,
  loadingDownloadStatus: StatusEnums.Initial,
  error: null,
  variants: [],
  sections: []
});

export const cvsSlice = createSlice({
  name: CVS_FEATURE_KEY,
  initialState: initialCvsState,
  reducers: {
    add: cvsAdapter.addOne,
    remove: cvsAdapter.removeOne,
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDownloadCV.pending, (state: CvsState) => {
        state.loadingDownloadStatus = StatusEnums.Loading
      })
      .addCase(fetchDownloadCV.fulfilled, (state: CvsState) => {
        state.loadingDownloadStatus = StatusEnums.Loaded
      })
      .addCase(fetchDownloadCV.rejected, (state: CvsState, action) => {
        state.loadingDownloadStatus = StatusEnums.Error;
        state.error = action.error.message;
      })
      .addCase(fetchCvs.pending, (state: CvsState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(fetchCvSectionStates.pending, (state: CvsState) => {
        state.loadingSectionsStatus = StatusEnums.Loading;
      })
      .addCase(
        fetchCvs.fulfilled,
        (state: CvsState, action: PayloadAction<CvSummaryData>) => {
          state.variants = action.payload.variants;
          state.loadingStatus = StatusEnums.Loaded;
        }
      )
      .addCase(
        fetchCvSectionStates.fulfilled,
        (state: CvsState, action: PayloadAction<CvSectionStateEntity[]>) => {
          state.sections = action.payload;
          state.loadingSectionsStatus = StatusEnums.Loaded;
        }
      )
      .addCase(fetchCvs.rejected, (state: CvsState, action) => {
        state.loadingStatus = StatusEnums.Error;
        state.error = action.error.message;
      })
      .addCase(fetchCvSectionStates.rejected, (state: CvsState, action) => {
        if (action.error.message === '404') {
          state.loadingSectionsStatus = StatusEnums.NotFound;
        }
        else {
          state.loadingSectionsStatus = StatusEnums.Error;
        }
        state.error = action.error.message;
      });
  },
});

/*
 * Export reducer for store configuration.
 */
export const cvsReducer = cvsSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(cvsActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const cvsActions = cvsSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllCvs);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = cvsAdapter.getSelectors();

export const getCvsState = (rootState: unknown): CvsState =>
  rootState[CVS_FEATURE_KEY];

export const selectAllCvs = createSelector(getCvsState, selectAll);

export const selectCvsEntities = createSelector(getCvsState, selectEntities);
