import React from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { TrackedErrorBoundary } from 'cv-app/shared/ui/tracked-error-boundary';
import Header from 'cv-app/shared/ui/layout/header/header';
import MainContent from 'cv-app/shared/ui/layout/main-content/main-content';
import InitialScreen from 'cv-app/pages/initial-screen/initial-screen';
const { getAuthScope } = require("cv-app/utils/getAuthScope");


const App = () => {
  const { login, result, error } = useMsalAuthentication(InteractionType.Redirect, { scopes: getAuthScope() });

  return (
    
  // Adding an error boundary on the highest level,
  // to catch unexpected JS errors.
      <TrackedErrorBoundary>
        <Header />

        <main role="main" className="u-bg-color-accent1">
          <AuthenticatedTemplate>
            <MainContent></MainContent>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <InitialScreen></InitialScreen>
          </UnauthenticatedTemplate>
        </main>

      </TrackedErrorBoundary>

  );
};

export default App;
