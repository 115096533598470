// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".section_c-section__3ktwM {\n  overflow: auto;\n  padding-bottom: 40px;\n}\n.section_c-section__3ktwM:first-of-type {\n  padding-top: 40px;\n}", ""]);
// Exports
exports.locals = {
	"c-section": "section_c-section__3ktwM"
};
module.exports = exports;
