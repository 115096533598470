import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  EntityState,
  PayloadAction,
} from '@reduxjs/toolkit';
import { getEducationSection, postEducations } from 'cv-app/shared/services/educations/education-service';
import { EducationInput } from 'cv-app/shared/services/educations/education-types';
import { PostListData, SectionData } from 'cv-app/shared/services/sections';
import { AuthInfo } from 'cv-app/utils/api-auth-helper';
import { HistoryItem } from 'cv-app/utils/base/history-helper';
import { SectionType } from 'cv-app/utils/section-types';
import { StatusEnums } from 'cv-app/utils/status-enums';

export const EDUCATION_FEATURE_KEY = 'education';

export interface EducationEntity extends HistoryItem {
  id: number;
  schoolName: string;
  educationDegreeId: string;
  fieldOfStudy: string;
  description: string;
}

export interface EducationState extends EntityState<EducationEntity> {
  loadingStatus: StatusEnums;
  error: string;
  sectionData: SectionData<EducationInput>;
}

export const educationAdapter = createEntityAdapter<EducationEntity>();


export const fetchEducation = createAsyncThunk(
  'education/fetchStatus',
  async (authInfo: AuthInfo) => {
    const response = await getEducationSection(authInfo);
    return Promise.resolve(response);
  }
);
export const postEducationInput = createAsyncThunk(
  'education/postStatus',
  async (postData: PostListData<EducationEntity>) => {
    const response = await postEducations({educations: postData.entities, type: SectionType.Education}, postData.authInfo);
    return Promise.resolve(response);
  }
)

export const initialEducationState: EducationState = educationAdapter.getInitialState(
  {
    loadingStatus: StatusEnums.Initial,
    error: null,
    sectionData: {
      id: '',
      cvId: '',
      locale: '',
      canPublish: false,
      changedBy: '',
      changedOn: '',
      type: SectionType.Education,
      input: {
        type: SectionType.Education,
        educations: []
      }
    }
  }
);

export const educationSlice = createSlice({
  name: EDUCATION_FEATURE_KEY,
  initialState: initialEducationState,
  reducers: {
    // ...
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEducation.pending, (state: EducationState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(postEducationInput.pending, (state: EducationState) => {
        state.loadingStatus = StatusEnums.Loading;
      })
      .addCase(
        fetchEducation.fulfilled,
        (state: EducationState, action: PayloadAction<SectionData<EducationInput>>) => {
          state.loadingStatus = StatusEnums.Loaded;
          state.sectionData = action.payload;
        }
      )
      .addCase(
        postEducationInput.fulfilled,
        (state: EducationState, action: PayloadAction<SectionData<EducationInput>>) => {
          state.loadingStatus = StatusEnums.Loaded;
          state.sectionData = action.payload;
        }
      )
      .addCase(fetchEducation.rejected, (state: EducationState, action) => {
        if (action.error.message === '404') {
          state.loadingStatus = StatusEnums.NotFound;
          state.sectionData = initialEducationState.sectionData;
        } else {
          state.loadingStatus = StatusEnums.Error;
        }
        state.error = action.error.message;
      })
      .addCase(postEducationInput.rejected, (state: EducationState, action) => {
        state.loadingStatus = StatusEnums.SubmitError;
        state.error = action.error.message;
      });
  },
});

/*
 * Export reducer for store configuration.
 */
export const educationReducer = educationSlice.reducer;

/*
 * Export action creators to be dispatched. For use with the `useDispatch` hook.
 *
 * e.g.
 * ```
 * import React, { useEffect } from 'react';
 * import { useDispatch } from 'react-redux';
 *
 * // ...
 *
 * const dispatch = useDispatch();
 * useEffect(() => {
 *   dispatch(educationActions.add({ id: 1 }))
 * }, [dispatch]);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#usedispatch
 */
export const educationActions = educationSlice.actions;

/*
 * Export selectors to query state. For use with the `useSelector` hook.
 *
 * e.g.
 * ```
 * import { useSelector } from 'react-redux';
 *
 * // ...
 *
 * const entities = useSelector(selectAllEducation);
 * ```
 *
 * See: https://react-redux.js.org/next/api/hooks#useselector
 */
const { selectAll, selectEntities } = educationAdapter.getSelectors();

export const getEducationState = (rootState: unknown): EducationState =>
  rootState[EDUCATION_FEATURE_KEY];

export const selectAllEducation = createSelector(getEducationState, selectAll);

export const selectEducationEntities = createSelector(
  getEducationState,
  selectEntities
);
