import { AuthInfo, DownloadCVPayload, fetchDownloadCVFile, fetchDownloadFile, fetchJson } from "cv-app/utils/api-auth-helper";
import { CvSummaryData } from "cv-app/shared/services/cvs/cv-types";
import { CvSectionStateEntity } from "cv-app/shared/redux/cvs/cvs.slice";
import { PersonalInfoFormData } from "../personal-information/personal-types";

export function getCvSummaryData(authInfo: AuthInfo): Promise<CvSummaryData> {
    return fetchJson<CvSummaryData>('/api/cvs/summary', authInfo);
}

export function getCvSectionStates(authInfo: AuthInfo): Promise<CvSectionStateEntity[]> {
    return fetchJson<CvSectionStateEntity[]>('/api/cvs', authInfo);
}

export function downloadCV(id: number, authInfo: AuthInfo, personalInfo: PersonalInfoFormData): Promise<void> {
    return fetchDownloadFile(`/api/cvs/${id}/download`, authInfo, personalInfo);
}

export function downloadCVPost(authInfo: AuthInfo, personalInfo: PersonalInfoFormData, body: DownloadCVPayload): Promise<void> {
    return fetchDownloadCVFile(`/api/cvs/download-cv`, authInfo, personalInfo, body);
}
